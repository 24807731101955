import { createSelector } from "reselect";

const dayStatusSelector = state => state.dayStatus.days;
//const selectedDayStatus = state => state.dayStatus.selectedDayStatusID;

const getSelectedStatus = state => {
    let currentDay = new Date().setHours(0, 0, 0, 0);
    let status = state.filter(c => c.Day === currentDay);
    if (status.length > 0) return status[0];
    return state;
};

const SelectedDayStatusSelector = createSelector(
    dayStatusSelector,
    getSelectedStatus
);

// const SelectedDayStatusSelectorStructured = createSelector(
//     state => {
//         return state.dayStatus.days;
//     },
//     stat => {
//         let currentDay = new Date().setHours(0, 0, 0, 0);
//         let status = stat.filter(c => c.Day === currentDay);
//         if (status.length > 0) return status[0];
//         return stat;
//     }
// );

// export default {
//     SelectedDayStatusSelector,
//     SelectedDayStatusSelectorStructured
// };

export default SelectedDayStatusSelector;
