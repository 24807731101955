import React, { Component } from "react";
import { connect } from "react-redux";
import { setTitle } from "../../actions/AppActions";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import moment from "moment";
import "moment/locale/de";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { createStructuredSelector } from "reselect";
import {
  WatchIcon,
  PhoneIcon,
  PhonelinkRing,
  AddAlarmIcon
} from "../../bundles/IconsBundle";
import { SavePropertyValue, SetPropertyValue, SaveData, SaveUnassignedDates } from "../../actions/TaskActions";
import DateHelper from "../../helpers/date-helper";
import makeGetDayAppointmentsSelectorMenck from "../../selectors/TasksDailySelectorMenck";
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Media from 'react-media';

import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  DateTimePicker
} from "@material-ui/pickers";
import SyncButton from './SyncButton';
import { ToastContainer } from "react-toastify";
import { checkIfAppointmentDurationIsInOneDay } from '../../helpers/utils';
import notification from "../../helpers/toast_notification";

const styles = {
  div: {
    display: "block",
    float: "left",
    textAlign: "left",
    paddingLeft: "10px",
    zIndex: 999
  },
  par: {
    height: 40,
    marginTop: 0
  },
  appbar: {
    top: 0,
    height: 43,
    zIndex: 1000
  },
  card: {
    margin: "10px",
    boxShadow: "1px 1px 1px 1px #AAA",
    borderRadius: "0px",
    textAlign: "left",
    color: "#444"
  },
  cont: {
    overflowY: "scroll",
    height: "calc(100vh - 100px)",
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0
  },
  grayBackground: {
    background: 'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3MCIgaGVpZ2h0PSI3MCI+CjxyZWN0IHdpZHRoPSI3MCIgaGVpZ2h0PSI3MCIgZmlsbD0iI2EyZDhjZiI+PC9yZWN0Pgo8ZyB0cmFuc2Zvcm09InJvdGF0ZSg0NSkiPgo8cmVjdCB3aWR0aD0iOTkiIGhlaWdodD0iMjUiIGZpbGw9IiNhNWQzY2IiPjwvcmVjdD4KPHJlY3QgeT0iLTUwIiB3aWR0aD0iOTkiIGhlaWdodD0iMjUiIGZpbGw9IiNhNWQzY2IiPjwvcmVjdD4KPC9nPgo8L3N2Zz4=")'
  },
};

export class Unassigned extends Component {
  constructor(props) {
    super(props);
    this.calendarRef = React.createRef();
  }

  state = {
    appointments: [],
    date: new Date(),
    questionDialogOpen: false,
    questionFinishDialogOpen: false,
    dialogApp: {},
    dialogImages: [],
    selectDateTimeDialogOpen: false,
    type: 1,
    dateStart: null,
    dateEnd: null,
    selectedDate: new Date(),
    duration: null
  };

  componentDidMount() {
    this.props.setTitle("Unverteilte Vorgänge");
  }

  getDateString = () => {
    moment.locale("de");
    var d = Date.now();
    return moment(d).format("MMMM DD, YYYY");
  };

  getDateTimeString = date => {
    moment.locale("de");
    return moment(date).format("dddd, DD.MM.YYYY HH:mm");
  };

  getServiceTitle = app => {
    return `${app.ServiceNr || ""} - ${app.ekName || ""}`;
  };

  getBackgroundColor = app => {
    if (app.Finished) return { background: "#81c784" };
    if (app.Started) return { background: "#ffee58" };
    return { background: "white" };
  };

  getDayName = () => {
    var d = Date.now();
    moment.locale("de");
    return moment(d).format("dddd");
  };

  ShowAppointment = val => {
    // this.props.history.push("/taskunassigned/" + val.id);
    this.props.history.push("/taskunassigned/" + (val.UniqueID == null ? -1 : val.UniqueID) + "/" + val.id);
    // + (val.UniqueID==null?-1:val.UniqueID) + "/" + val.IDService );
  };


  zeroTasksCheck = () => {
    if (this.props.appointments.length === 0) return <div>0 Service</div>;
    return null;
  };

  ToggleWorkAsk = (app, name) => {
    this.setState({
      ...this.state,
      questionDialogOpen: true,
      dialogApp: app,
      type: name === "Start" ? 1 : 0
    });
  };

  handleCloseDialogs = () => {
    this.setState({
      ...this.state,
      questionDialogOpen: false,
      questionFinishDialogOpen: false,
      selectDateTimeDialogOpen: false,
      type: 1
    });
  };

  // saveData = async () => {
  //   const data = {
  //     Finished: this.props.app.Finished,
  //     StartTime: this.props.app.StartTime,
  //     Duration: this.props.app.Duration,
  //     ErledigteArbeiten: this.props.app.ErledigteArbeiten
  //   };
  //   await this.props.SaveData(this.props.app.IDService, data);
  // };

  toggleWorkAccepted = async (e) => {
    let name = e.currentTarget.name;
    const dt = new Date();
    dt.setSeconds(0, 0);
    const app = this.state.dialogApp;
    if (name === "Start") {
      await this.props.SavePropertyValue(app, "Started", 1);
      this.props.SavePropertyValue(app, "StartTime", dt);

      await this.setState({
        ...this.state,
        dialogApp: null,
        questionDialogOpen: false
      });
    } else {
      await this.props.SavePropertyValue(app, "Finished", 1);

      const duration = moment.duration(
        moment(Date.now()).diff(moment(app.StartTime))
      );
      await this.props.SavePropertyValue(app, "Duration", Math.round(duration.asMinutes()));

      this.setState({
        ...this.state,
        dialogApp: null,
        questionDialogOpen: false
      });
    }
  };

  selectDateTimeDialog = app => event => {
    this.setState({ ...this.state, selectDateTimeDialogOpen: true, dialogApp: app });
  };

  takeThisService = app => event => {
    // console.log("Delta," ,app)
    let data = {
      StartTime: this.state.dateStart,
      StartDate: this.state.dateStart,
      Duration: this.state.duration
    }
    if (checkIfAppointmentDurationIsInOneDay(data.StartDate, data.Duration)) {

      //snimanje u state
      this.props.SaveUnassignedDates(app.IDService, "StartTime", this.state.dateStart);
      this.props.SaveUnassignedDates(app.IDService, "StartDate", this.state.dateStart);
      this.props.SaveUnassignedDates(app.IDService, "Duration", app.GeplanteServicezeit);
      //snimanje u enteredData
      this.props.SaveData(app, data);
    } else {
      notification(
        "nonEditable",
        "Die Dauer des Termins beträgt mehr als einen Tag. Bitte wählen Sie eine andere Startzeit für den Termin."
      );
    }

  };

  saveDateTime = (duration, app) => e => {
    let newDateToSet = null;
    if (e !== null) newDateToSet = e._d;
    //snimi datum u state, potrebno za prikaz
    this.props.SaveUnassignedDates(app.IDService, "StartDate", newDateToSet);
    this.setState({ dateStart: newDateToSet });
    this.setState({ duration: duration });
  }

  handleChipClick = (event) => {
    return window.location = "tel:" + event;
  }

  renderDateButton = (props) => (
    <Button
      variant="contained"
      size="small"
      color="secondary"
      onClick={props.onClick}
      id={props.id}
      value={props.value}
      disabled={props.disabled}
      {...props.inputProps}
      style={{ padding: "5px", paddingLeft: "0px", paddingRight: "0px", minWidth: "40px" }}
    ><AddAlarmIcon size="small" /></Button>
  );

  header = () => {
    return (
      <AppBar position="static" style={styles.appbar}>
        <Grid container spacing={0} direction="row" justify="space-evenly" alignItems="flex-start">
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <div style={styles.div}>{this.getDayName()}</div>
            <br />
            <div style={styles.div}>{this.getDateString()}</div>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <div style={{ textAlign: "right" }}>
              <SyncButton />
            </div>
          </Grid>
        </Grid>
        <div>

        </div>
      </AppBar>
    );
  };



  /*FinishWorkAsk = async app => {
    this.props.history.push("/task/" + app.id);
  };*/

  render() {
    return <div style={styles.back}>
      <div style={styles.par}>{this.header()}</div>
      {/* <div style={styles.par}>{this.header()}</div>
      <div style={styles.cont}> */}
      {this.zeroTasksCheck()}
      {this.props.appointments.map(val => (
        <Card
          key={val.id}
          style={styles.card}
          raised
        >
          <Grid container spacing={0} direction="row" justify="space-evenly" alignItems="flex-start">
            <Grid container style={styles.grayBackground}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div>
                  <CardContent
                    onClick={() => { this.ShowAppointment(val); }}
                    style={{ paddingBottom: "5px", paddingTop: "10px" }}
                  >
                    <div
                      style={{ color: val.Tourart !== 1 ? "red" : "" }}
                    >
                      <b>{this.getServiceTitle(val)}</b>
                    </div>
                    <div>
                      <Typography component="p">
                        {val.ekPLZ} - {val.ekOrt}
                      </Typography>
                      <div>
                        <Typography component="p">
                          {val.Strasse}
                        </Typography>
                      </div>
                    </div>


                  </CardContent>
                </div>
              </Grid>
              {((val.ekTel1 !== null && val.ekTel1 !== "") || (val.ekTel2 !== null && val.ekTel2 !== "")) && (
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Media query={{ maxWidth: 599 }}>
                    {matches => matches ? (
                      <div style={{ margin: "5px", marginLeft: "10px", textAlign: "left" }}>
                        {(val.ekTel1 !== null && val.ekTel1 !== "") && (
                          <Chip
                            style={{ marginTop: "0px", padding: "0 5px" }}
                            clickable
                            icon={<PhoneIcon />}
                            label={val.ekTel1}
                            onClick={() => { this.handleChipClick(val.ekTel1) }}
                          />
                        )}
                        <br />
                        {(val.ekTel2 !== null && val.ekTel2 !== "") && (
                          <Chip
                            style={{ marginTop: "5px", padding: "0 5px" }}
                            clickable
                            icon={<PhoneIcon />}
                            label={val.ekTel2}
                            onClick={() => { this.handleChipClick(val.ekTel2) }}
                          />
                        )}
                         <br />
                        {(val.ekHandy !== null && val.ekHandy !== "") && (
                          <Chip
                            style={{ marginTop: "5px", padding: "0 5px" }}
                            clickable
                            icon={<PhonelinkRing />}
                            label={val.ekHandy}
                            onClick={() => { this.handleChipClick(val.ekHandy) }}
                          />
                        )}
                        <br />
                        {(val.ekHandy2 !== null && val.ekHandy2 !== "") && (
                          <Chip
                            style={{ marginTop: "5px", padding: "0 5px" }}
                            clickable
                            icon={<PhonelinkRing />}
                            label={val.ekHandy2}
                            onClick={() => { this.handleChipClick(val.ekHandy2) }}
                          />
                        )}
                      </div>
                    ) : (
                        <div style={{ marginRight: "10px", marginLeft: "20px", marginBottom:"5px", textAlign: "right" }}>
                          {(val.ekTel1 !== null && val.ekTel1 !== "") && (
                            <Chip
                              style={{ marginTop: "5px", padding: "0 5px" }}
                              clickable
                              icon={<PhoneIcon />}
                              label={val.ekTel1}
                              onClick={() => { this.handleChipClick(val.ekTel1) }}
                            />
                          )}
                          <br />
                          {(val.ekTel2 !== null && val.ekTel2 !== "") && (
                            <Chip
                              style={{ marginTop: "5px", padding: "0 5px" }}
                              icon={<PhoneIcon />}
                              clickable
                              label={val.ekTel2}
                              onClick={() => { this.handleChipClick(val.ekTel2) }}
                            />
                          )}
                            <br />
                          {(val.ekHandy !== null && val.ekHandy !== "") && (
                            <Chip
                              style={{ marginTop: "5px", padding: "0 5px" }}
                              icon={<PhonelinkRing />}
                              clickable
                              label={val.ekHandy}
                              onClick={() => { this.handleChipClick(val.ekHandy) }}
                            />
                          )}
                           <br />
                          {(val.ekHandy2 !== null && val.ekHandy2 !== "") && (
                            <Chip
                              style={{ marginTop: "5px", padding: "0 5px" }}
                              icon={<PhonelinkRing />}
                              clickable
                              label={val.ekHandy2}
                              onClick={() => { this.handleChipClick(val.ekHandy2) }}
                            />
                          )}
                        </div>
                      )}
                  </Media>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {val.ServiceInfo !== null && (
                <div>
                  <CardContent
                    onClick={() => { this.ShowAppointment(val); }}
                    style={{ paddingBottom: "10px", paddingTop: "10px" }}
                  >
                    <div>
                      <Typography component="p">
                        {val.ServiceInfo}
                      </Typography>
                    </div>
                  </CardContent>
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              {val.StartDate !== null ? (
                <div style={{ lineHeight: "24px", marginTop: "5px" }}>
                  <span
                    style={{
                      verticalAlign: "middle",
                      display: "inline-block",
                      height: "24px",
                      marginLeft: "10px"
                    }}
                  >
                    <Typography component="p">
                      {" "}
                      <WatchIcon />
                    </Typography>
                  </span>
                  <span
                    style={{
                      verticalAlign: "middle",
                      display: "inline-block",
                      height: "24px",
                      fontSize: "100%",
                      marginLeft: "10px"
                    }}
                  >
                    <Typography component="p">
                      {" "}
                      {this.getDateTimeString(val.StartDate)}
                    </Typography>
                  </span>
                </div>
              ) : (
                  <div style={{ marginLeft: "15px", marginTop: "5px" }}>
                    <span
                      style={{
                        verticalAlign: "middle",
                        display: "inline-block",
                        height: "24px",
                        fontSize: "100%",
                      }}
                    >
                      <Typography component="p">
                        {"Zuerst Datum und Zeit eintragen"}
                      </Typography>
                    </span>
                  </div>
                )}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Media query={{ maxWidth: 599 }}>
                {matches => matches ? (
                  <div style={{ marginRight: "10px", marginBottom: "5px", marginLeft: "10px", marginTop: "5px", textAlign: "left" }}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DateTimePicker
                        clearable
                        TextFieldComponent={this.renderDateButton}
                        shouldDisableDate={date => new DateHelper().disableWeekends(date)}
                        disablePast={true}
                        value={val.StartDate}
                        ampm={false}
                        onChange={this.saveDateTime(val.GeplanteServicezeit, val)} />
                    </MuiPickersUtilsProvider>
                    <Button
                      variant="contained"
                      size="small"
                      aria-label="Verschieben"
                      id="ubernehmen"
                      color="secondary"
                      onClick={this.takeThisService(val)}
                      disabled={val.StartDate === null}
                      style={{
                        marginLeft: "5px",
                        color: "white",
                        backgroundColor: val.StartDate === null ? "#bdbdbd" : "#3962BA",
                      }}
                    >Übernehmen
                      </Button>
                  </div>
                ) : (
                    <div style={{ marginRight: "10px", marginBottom: "5px", marginLeft: "10px", marginTop: "5px", textAlign: "right" }}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DateTimePicker
                          clearable
                          TextFieldComponent={this.renderDateButton}
                          shouldDisableDate={date => new DateHelper().disableWeekends(date)}
                          disablePast={true}
                          value={val.StartDate}
                          ampm={false}
                          onChange={this.saveDateTime(val.GeplanteServicezeit, val)} />
                      </MuiPickersUtilsProvider>
                      <Button
                        variant="contained"
                        size="small"
                        aria-label="Verschieben"
                        id="ubernehmen"
                        color="secondary"
                        onClick={this.takeThisService(val)}
                        disabled={val.StartDate === null}
                        style={{
                          marginLeft: "5px",
                          color: "white",
                          backgroundColor: val.StartDate === null ? "#bdbdbd" : "#3962BA",
                        }}
                      >Übernehmen
                      </Button>
                    </div>
                  )}
              </Media>
            </Grid>
          </Grid>
        </Card>
      ))}
      <ToastContainer autoClose={8000} />
    </div>
  }
}

const mapStateToProps = createStructuredSelector({
  appointments: makeGetDayAppointmentsSelectorMenck()
});

export default withRouter(
  connect(
    mapStateToProps,
    { setTitle, SavePropertyValue, SetPropertyValue, SaveData, SaveUnassignedDates }
  )(Unassigned)
);
