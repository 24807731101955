import { createSelector } from 'reselect';
import mergeChangesWithAppointment from '../helpers/appointment-changes-merge';

let tdsm = () => createSelector(
    (state) => { return state.tasks.appointments },
    (state) => { return state.tasks.enteredData },
    (state) => { return state.tasks.shownType },
    (state, props) => { return props.day },
    (apps, enteredData, shownType, day) => {

        let mergedUnverteilte = apps.map(app => {
            const modifiedData = enteredData.find(c => c.IDService === app.IDService && c.UniqueID===app.UniqueID);
            if (!modifiedData) return app;
            return mergeChangesWithAppointment(app, modifiedData);
        });

        const unverteilteService = mergedUnverteilte.filter(app => {
            return app.UniqueID === null && app.StartTime === undefined;
        });

        return unverteilteService;
    }
);

export default tdsm;