import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Button } from "@material-ui/core";

import { DoBackup, ReportBackupProgress } from "../actions/BackupActions";
import { setTitle } from "../actions/AppActions";

export class DbExport extends Component {
  componentDidMount() {
    this.props.setTitle("Sicherungskopie");
  }

  DoBackup = async () => {
    this.props.DoBackup(this.props.settings, this.props.ReportBackupProgress);
  };

  render() {
    const style = {
      container: {
        height: "100vh",
        margin: "30px"
      },

      control: {
        position: "relative",
        top: "calc(40%)"
      }
    };

    return (
      <div style={style.container}>
        <div style={style.control}>
          {!this.props.backup.backupInProgress && (
            <div>
              <Button
                id="btnExport"
                color="primary"
                variant="contained"
                onClick={this.DoBackup}
              >
                Export
              </Button>
            </div>
          )}

          {this.props.backup.backupInProgress && (
            <div>
              <LinearProgress
                variant="determinate"
                value={this.props.backup.backupProgressValue}
              />
              <div>
                Backup in progress <span id="spanProgressValue">{this.props.backup.backupProgressValue}%</span>
              </div>
              <br />
              <Button color="primary" variant="contained" disabled={true}>
                Export
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

DbExport.propTypes = {
  DoBackup: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  backup: state.backup,
  settings: state.settings
});

export default connect(
  mapStateToProps,
  { DoBackup, ReportBackupProgress, setTitle }
)(DbExport);
