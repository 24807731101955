import { CHANGE_FILTER, FOTO_ADD, FOTO_REMOVE, INITIALISE_STATE, SAVE_SIGNATURES, SAVE_TV_DATA, SAVE_TV_PROPERTY_VALUE, SELECT_APPOINTMENT_BY_ID, SET_SHOWN_DAY, SET_TV_PROPERTY_VALUE,
     SAVE_PROPERTY_VALUE_MENCK, SAVE_TV_DATA_MENCK, SAVE_UNASSIGNED_DATES, SET_TV_PROPERTY_VALUE_FOR_ROW, SAVE_TV_DATA_FOLGETERMIN, DOWNLOAD_FILE_MANUALLY, SET_DOWNLOAD_IN_PROGRESS } from '../actions/types';
import { SHOWN_TYPE_OFFENE } from '../helpers/constants';
import DateHelper from '../helpers/date-helper';

const initialState = {
    days: [],
    appointments: [],
    attachments: [],
    notes: [],
    enteredData: [],
    fotos:[],

    shownDay:(new DateHelper()).getTodayIndex(),
    shownType: SHOWN_TYPE_OFFENE,
    selectedAppointmentId: -1,
    selectedAppointmentServiceID:-1,
    downloadInProgress: false

}

export default function task(state = initialState, action) {
    
    switch (action.type) {
        case INITIALISE_STATE:
            if (!action.allDbData) return state;                      
            return Object.assign({}, state, action.allDbData);
        case CHANGE_FILTER:
            return {...state,shownType:action.payload };
        case SET_TV_PROPERTY_VALUE:
        {
            let edIndex = state.enteredData.findIndex(c=>c.IDService===action.app.IDService && c.UniqueID===action.app.UniqueID);            
            if (edIndex===-1) {
                let newED = { IDService: action.app.IDService, UniqueID: action.app.UniqueID };
                newED[action.prop] = action.value;
                let newS = {...state, enteredData: [...state.enteredData, newED]}
                return newS;
            } else {
                let enteredData = Object.assign([],state.enteredData);
                let ed = Object.assign({}, enteredData[edIndex]);
                ed[action.prop]=action.value;
                enteredData.splice(edIndex,1,ed);
                let newS2={...state, enteredData: enteredData};
                return newS2;
            }             
        }
        case SAVE_UNASSIGNED_DATES:{
            // let index = state.appointments.findIndex(c => c.IDService === action.idService);
            let newProp = action.prop;
            let newValue = action.value;            
            //let ed = Object.assign({}, obj);

            return {...state,
                appointments: state.appointments.map(content => (content.IDService === action.idService && content.UniqueID == null) ? {...content, [newProp]: newValue} : content)
            }
            
                // return state.appointments.map(app => {
                //     if(app.IDService === action.idService){
                //         return {...app, tasks: [ed]}
                //     }
                //     return app;
                // });
            
            
        }                
        case SAVE_TV_DATA_MENCK:
        case SAVE_PROPERTY_VALUE_MENCK:
        case SET_TV_PROPERTY_VALUE_FOR_ROW:
            let edIndex3 = state.enteredData.findIndex(c=>c.IDService===action.app.IDService);  
           
            if (edIndex3===-1) {
                let newED = { ServiceID: action.app.IDService };
                if (typeof (action.value) == "object")
                {
                    newED[action.tableName] = [];
                    newED[action.tableName].push(action.value);
                } 
                let newS = {...state, enteredData: [...state.enteredData, newED]}
                return newS;
            } else {
                let enteredData = Object.assign([],state.enteredData);
                let ed = Object.assign({}, enteredData[edIndex3]);

                if (typeof (action.value) == "object")
                {
                    let arr=ed[action.tableName];
                    if (arr===undefined) arr=[];
                    arr.push(action.value);
                    ed[action.tableName]=arr;                    
                } else {
                    var idx = ed[action.tableName].findIndex(c=>c.id===action.rowId);                    
                    ed[action.tableName][idx][action.prop]=action.value;
                }
               
                enteredData.splice(edIndex3,1,ed);
                let newS2={...state, enteredData: enteredData};
                return newS2;
            }   

          


        case SAVE_SIGNATURES:
        case SAVE_TV_DATA:
        case SAVE_TV_PROPERTY_VALUE:


            let edIndex = state.enteredData.findIndex(c=> c.UniqueID===action.app.UniqueID && c.IDService===action.app.IDService);
            
            if (edIndex===-1) {
                let newS = {...state, enteredData: [...state.enteredData, action.enteredData]}
                return newS;
            } else {
                let enteredData = Object.assign([],state.enteredData);
                enteredData.splice(edIndex,1,action.enteredData);
                let newS2={...state, enteredData: enteredData};
                return newS2;
            } 
           
        case SAVE_TV_DATA_FOLGETERMIN:
            let newS = {...state, enteredData: [...state.enteredData, action.enteredData]}
            return newS;
        case SELECT_APPOINTMENT_BY_ID:
            return {...state, selectedAppointmentId: action.UniqueID, selectedAppointmentServiceID: parseInt(action.IDService) }
        case FOTO_ADD:      
            let fotos = state.fotos.slice();
            fotos.push(action.payload);            
            return {...state, fotos: fotos}   
        case FOTO_REMOVE:        
            return {...state, fotos: state.fotos.filter(f=> f.id!==action.payload)}                
        case SET_SHOWN_DAY:
            state.shownDay=action.payload; //state is modified directly on purpose. We do not need redraw, shownDay is needed only on switching to TaskList to set initialState
            return state;
        case DOWNLOAD_FILE_MANUALLY:
           // return {...state, attachments: [...state.attachments, action.payload]}
           state.downloadInProgress = false; //Zatvori popup za skidanje fajlova
           let fileIndex = state.attachments.findIndex(c=> c.id === action.FileID);
           let attachmentsData = Object.assign([],state.attachments);
                attachmentsData.splice(fileIndex,1,action.attachmentUpdate);
                let newS2={...state, attachments: attachmentsData};
                return newS2;
        case SET_DOWNLOAD_IN_PROGRESS:
            // state.downloadInProgress = action.payload;
            return {...state, downloadInProgress: action.payload}
        default:
            return state;
    }

}