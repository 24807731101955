import { INITIALISE_STATE, TOGGLE_DRAWER, SET_TITLE, SW_INIT, SW_UPDATE } from "../actions/types";
import { COMPANY } from "../settings";

const initialState = {
    drawerOpen: false,
    pageTitle: "ServiceApp",
    company: COMPANY,
    updateAvailable: false,
    serviceWorkerInitialized: false,
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null,
};

export default function app (state = initialState, action) {
    switch (action.type) {
        case INITIALISE_STATE:
            return state;
        case TOGGLE_DRAWER:
            return { ...state, drawerOpen: action.payload };
        case SET_TITLE:
            return { ...state, pageTitle: action.payload };
        case SW_INIT:
                return {
                  ...state,
                  serviceWorkerInitialized: !state.serviceWorkerInitialized,
                };
        case SW_UPDATE:
                return {
                  ...state,
                  serviceWorkerUpdated: !state.serviceWorkerUpdated,
                  serviceWorkerRegistration: action.payload,
                };
        default:
            return state;
    }
}
