import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
//import { SetPropertyValue, SavePropertyValue } from '../actions/taskviewActions';
import { setTitle } from "../../actions/AppActions";
import { withRouter } from "react-router-dom";
import Fab from "@material-ui/core/Fab";
import AppBar from "@material-ui/core/AppBar";
import moment from "moment";
import "moment/locale/de";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { createStructuredSelector } from "reselect";
import {
    WatchIcon,
    FlagIcon,
    FinishIcon,
    PinDropIcon
} from "../../bundles/IconsBundle";
import { SavePropertyValue } from "../../actions/TaskActions";
import StartWorkQuestionDialog from "../dialogs/StartWorkQuestionDialog";

import DateHelper from "../../helpers/date-helper";
import makeGetDayAppointmentsSelector from "../../selectors/TasksDailySelector";

// import SelectedDayStatusSelectorStructured from "../../selectors/DayStatusSelector";

const styles = {
    div: {
        display: "block",
        float: "left",
        textAlign: "left",
        paddingLeft: "10px",
        zIndex: 999
    },
    par: {
        height: 43,
        marginTop: 0
    },
    appbar: {
        top: 0,
        zIndex: 1000
    },
    fab: {
        // marginTop: '-30px',
        // marginBottom: '0px',
        // marginLeft: '-30px',
        backgroundColor: "#5c97c1",
        float: "right"
        // display: 'block',
    },
    card: {
        margin: "10px",
        borderRadius: "0px",
        border: "3px solid #5C97C1",
        textAlign: "left",
        color: "#444",
        whiteSpace: "nowrap"
    },
    cardcontent: {
        width: "190px",
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingLeft: "5px"
    },
    finishedtask: {
        color: "#FFFFFF"
    },
    cont: {
        overflowY: "scroll",
        height: "calc(100vh - 100px)",
        paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0
    },
    iconContainer: {
        position: "relative"
    },
    pinIcon: {
        position: "absolute",
        top: 43,
        right: 10
    }
};

export class DayTasks extends Component {
    state = {
        appointments: [],
        date: new Date(),
        questionDialogOpen: false,
        questionFinishDialogOpen: false,
        dialogApp: {},
        dialogImages: [],
        finished: 0
    };

    getDateString = () => {
        moment.locale("de");
        let day = this.props.day;
        var d = new DateHelper().getDateByIndex(day);
        return d.format("MMMM DD, YYYY");
    };

    getDateTimeString = date => {
        moment.locale("de");
        return moment(date).format("dddd, DD.MM.YYYY HH:mm");
    };

    getServiceTitle = app => {
        return `${app.ServiceNr || ""} (${app.AbNr || ""}) - ${app.ekName ||
            ""}`;
    };

    getBackgroundColor = app => {
        let style = {};
        if (app.Started === 1 && app.Finished === 1)
            style = { background: "#5C97C1" };
        else if (app.Started === 1 && app.Finished === 0)
            style = { background: "#FFE082" };
        else if (
            (app.Started === 0 || app.Started === undefined) &&
            app.Finished === 1
        )
            style = { background: "#5C97C1" };
        else style = { background: "white" };

        let mitarbeiter =
            app.AnzahlMitarbeiter === 0 ? 1 : app.AnzahlMitarbeiter;

        if (app.ServiceArt === 0 && app.Kategorie !== 6 && mitarbeiter === 1)
            style.boxShadow = "0px 0px 0px 3px #5C97C1 inset";

        if (app.ServiceArt === 0 && app.Kategorie !== 6 && mitarbeiter > 1)
            style.boxShadow = "0px 0px 0px 3px #99D9EA inset";

        if (app.ServiceArt === 0 && app.Kategorie === 6)
            style.boxShadow = "0px 0px 0px 3px #ED1C24 inset";

        if (app.ServiceArt === 1)
            style.boxShadow = "0px 0px 0px 3px #FFE082 inset";

        if (app.ServiceArt === 2)
            style.boxShadow = "0px 0px 0px 3px #FFB062 inset";

        return style;
    };

    computeFontStyle = app => {
        let style = {};
        if (app.Started === 1 && app.Finished === 1)
            style = { color: "#FFFFFF" };
        else if (
            (app.Started === 0 || app.Started === undefined) &&
            app.Finished === 1
        )
            style = { color: "#FFFFFF" };
        else if (app.Started === 1 && app.Finished === 0)
            style = { color: "#7f7f7f" };
        else style = { color: "#7f7f7f" };
        return style;
    };

    getDayName = () => {
        let day = this.props.day;
        var d = new DateHelper().getDateByIndex(day);
        moment.locale("de");
        return d.format("dddd");
    };

    ShowAppointment = val => {
        this.props.history.push("/task/" + (val.UniqueID==null?-1:val.UniqueID) + "/" + val.IDService );
    };

    header = () => {
        return (
            <AppBar position="static" style={styles.appbar}>
                <div style={styles.div}>{this.getDayName()}</div>
                <div style={styles.div}>{this.getDateString()}</div>
            </AppBar>
        );
    };

    zeroTasksCheck = () => {
        if (this.props.appointments.length === 0) return <div>0 Auftrage</div>;
        return null;
    };

    StartWorkAsk = app => {
        this.setState({
            ...this.state,
            questionDialogOpen: true,
            dialogApp: app
        });
    };

    handleCloseDialogs = () => {
        this.setState({
            ...this.state,
            questionDialogOpen: false,
            questionFinishDialogOpen: false
        });
    };

    startWorkAccepted = async () => {
        const dt = new Date();
        dt.setSeconds(0, 0);
        const app = this.state.dialogApp;
        await this.props.SavePropertyValue(app, "Started", 1);
        await this.props.SavePropertyValue(app, "StartTime", dt);

        this.setState({
            ...this.state,
            dialogApp: null,
            questionDialogOpen: false
        });
    };

    showKategorie = (kategorie, serviceart) => {
        if (serviceart === 0 && Number(kategorie) === 0) return "Glastausch";
        else if (serviceart === 0 && Number(kategorie) === 1)
            return "Einbruchschaden";
        else if (serviceart === 0 && Number(kategorie) === 2)
            return "sonstige Reparaturen";
        else if (serviceart === 0 && Number(kategorie) === 3)
            return "Umrüstung";
        else if (serviceart === 0 && Number(kategorie) === 4)
            return "Insektenschutz";
        else if (serviceart === 0 && Number(kategorie) === 5)
            return "Beschattung";
        else if (serviceart === 0 && Number(kategorie) === 6)
            return "Reklamation aus Montage";
        else if (serviceart === 1 && Number(kategorie) === 0) return "vor Ort";
        else if (serviceart === 1 && Number(kategorie) === 1)
            return "Werkstatt";
        else if (serviceart === 2 && Number(kategorie) === 0) return "Wartung";
        else if (serviceart === 2 && Number(kategorie) === 1)
            return "freie Wartung";
        else if (serviceart === 0 && Number(kategorie) === 0) return "Beratung";
        else if (serviceart === 0 && Number(kategorie) === 1) return "Aufmaß";
        else if (serviceart === 0 && Number(kategorie) === 2)
            return "lt. Auftrag";
        else if (serviceart === 0 && Number(kategorie) === 3)
            return "ohne Auftrag";
        else return "";
    };

    showTerminArt = (serviceart, terminart) => {
        if (serviceart === 0 && Number(terminart) === 0) return "Reklamation";
        else if (serviceart === 0 && Number(terminart) === 1) return "Aufmaß";
        else if (serviceart === 0 && Number(terminart) === 2)
            return "lt. Auftrag";
        else if (serviceart === 0 && Number(terminart) === 3)
            return "ohne Auftrag";
        else return "";
    };

    // hiddenIfStarted = app => {
    //     if (app.Started === 1) return false;
    //     if (app.Started !== 1 && app.Finished !== 1) return true;
    //     if (app.Finished === 1) return false;
    // };

    // hiddenIfNotStarted = app => {
    //     if (app.Started === 1) return true;
    //     if (app.Started !== 1 && app.Finished !== 1) return false;
    //     if (app.Finished === 1) return false;
    // };

    hasNotiz = app => {
        if (
            app.Bemerkung !== "" &&
            app.Bemerkung !== null &&
            app.Bemerkung !== undefined
        )
            return true;
        else return false;
    };

    /*FinishWorkAsk = async app => {
    this.props.history.push("/task/" + app.id);
  };*/

    render() {
        return (
            <div style={styles.back}>
                <div style={styles.par}>{this.header()}</div>
                <div style={styles.cont}>
                    {this.zeroTasksCheck()}

                    {this.props.appointments.map(val => (
                        <Card
                            key={val.id}
                            style={Object.assign(
                                this.getBackgroundColor(val),
                                styles.card
                            )}
                            raised
                        >
                            <table style={{ width: "100%" }}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <CardContent
                                                style={styles.cardcontent}
                                                onClick={() => {
                                                    this.ShowAppointment(val);
                                                }}
                                            >
                                                <div>
                                                    <b
                                                        style={this.computeFontStyle(
                                                            val
                                                        )}
                                                    >
                                                        {val.KName}/ {val.BName}
                                                    </b>
                                                </div>
                                                <Typography
                                                    style={this.computeFontStyle(
                                                        val
                                                    )}
                                                    component="p"
                                                >
                                                    {val.BLand}-{val.BPLZ}{" "}
                                                    {val.BOrt} {val.BStrasse}
                                                </Typography>

                                                <div
                                                    style={{
                                                        verticalAlign: "middle",
                                                        display: "inline-block",
                                                        height: "24px",
                                                        fontSize: "90%",
                                                        marginLeft: "0px",
                                                        marginTop: "0px"
                                                        // float: 'right'
                                                    }}
                                                >
                                                    <Typography
                                                        style={this.computeFontStyle(
                                                            val
                                                        )}
                                                        component="p"
                                                    >
                                                        {" "}
                                                        Kategorie:{" "}
                                                        {this.showKategorie(
                                                            val.Kategorie,
                                                            val.ServiceArt
                                                        )}
                                                    </Typography>

                                                    <Typography
                                                        style={this.computeFontStyle(
                                                            val
                                                        )}
                                                        component="p"
                                                    >
                                                        {" "}
                                                        Terminart:{" "}
                                                        {this.showTerminArt(
                                                            val.ServiceArt,
                                                            val.TerminArt
                                                        )}
                                                    </Typography>
                                                </div>

                                                <div
                                                    style={{
                                                        lineHeight: "25px",
                                                        marginTop: "15px"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            verticalAlign:
                                                                "middle",
                                                            display:
                                                                "inline-block",
                                                            height: "24px"
                                                        }}
                                                    >
                                                        <Typography
                                                            style={this.computeFontStyle(
                                                                val
                                                            )}
                                                            component="p"
                                                        >
                                                            {" "}
                                                            <WatchIcon />
                                                        </Typography>
                                                    </span>
                                                    <span
                                                        style={{
                                                            verticalAlign:
                                                                "middle",
                                                            display:
                                                                "inline-block",
                                                            height: "24px",
                                                            fontSize: "90%",
                                                            marginLeft: "10px"
                                                        }}
                                                    >
                                                        <Typography
                                                            style={this.computeFontStyle(
                                                                val
                                                            )}
                                                            component="p"
                                                        >
                                                            {" "}
                                                            {this.getDateTimeString(
                                                                val.StartDate
                                                            )}
                                                        </Typography>
                                                    </span>
                                                </div>
                                            </CardContent>
                                        </td>
                                        <td>
                                            <CardContent>
                                                <div
                                                    style={styles.iconContainer}
                                                >
                                                    {val.Started !== 1 &&
                                                        val.Finished !== 1 && (
                                                            <Fab
                                                                style={
                                                                    styles.fab
                                                                }
                                                                size="small"
                                                                aria-label="Dest"
                                                                id="fabStart"
                                                                color="secondary"
                                                                // disable={
                                                                //     this.props
                                                                //         .daystatus
                                                                //         .StartPause !==
                                                                //         "" &&
                                                                //     this.props
                                                                //         .daystatus
                                                                //         .EndPause ===
                                                                //         ""
                                                                //         ? true
                                                                //         : false
                                                                // }
                                                                onClick={() => {
                                                                    this.StartWorkAsk(
                                                                        val
                                                                    );
                                                                }}
                                                            >
                                                                <FlagIcon fontSize="small" />
                                                            </Fab>
                                                        )}
                                                    {val.Started === 1 &&
                                                        val.Finished !== 1 && (
                                                            <Fab
                                                                style={
                                                                    styles.fab
                                                                }
                                                                size="small"
                                                                aria-label="Dest"
                                                                id="fabFinish"
                                                                color="secondary"
                                                                onClick={() => {
                                                                    this.ShowAppointment(
                                                                        val
                                                                    );
                                                                }}
                                                            >
                                                                <FinishIcon fontSize="small" />
                                                            </Fab>
                                                        )}
                                                    {this.hasNotiz(val) && (
                                                        <PinDropIcon
                                                            style={
                                                                styles.pinIcon
                                                            }
                                                            color="error"
                                                            fontSize="small"
                                                        />
                                                    )}
                                                </div>
                                                {/* <span
                          style={{
                            verticalAlign: "middle",
                            display: "inline-block",
                            height: "24px",
                            fontSize: "90%",
                            marginLeft: "0px",
                            marginTop: '0px',
                            float: 'left'
                          }}
                        >


                          {val.Finished === 1 ? <Typography style={styles.finishedtask} component="p"> Kategorie: {this.showKategorie(val.Kategorie, val.ServiceArt)}</Typography> :
                            <Typography component="p"> Kategorie: {this.showKategorie(val.Kategorie, val.ServiceArt)}</Typography>}

                          {val.Finished === 1 ? <Typography style={styles.finishedtask} component="p"> Terminart: {this.showTerminArt(val.ServiceArt, val.TerminArt)}</Typography> :
                            <Typography component="p"> Terminart: {this.showTerminArt(val.ServiceArt, val.TerminArt)}</Typography>}
                        </span> */}
                                            </CardContent>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Card>
                    ))}
                </div>

                <StartWorkQuestionDialog
                    open={this.state.questionDialogOpen}
                    handleClose={this.handleCloseDialogs}
                    startWorkAccepted={this.startWorkAccepted}
                />
            </div>
        );
    }
}

DayTasks.propTypes = {
    appointments: PropTypes.array.isRequired
};

const mapStateToProps = createStructuredSelector({
    appointments: makeGetDayAppointmentsSelector()
    // daystatus: SelectedDayStatusSelectorStructured()
});

export default withRouter(
    connect(
        mapStateToProps,
        { setTitle, SavePropertyValue }
    )(DayTasks)
);
