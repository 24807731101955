import React, { Component } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import check from '../extensions/check';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { EnteredDataForServicePDF } from '../../selectors/SelectedAppointmentSelector';
import { SavePropertyValue} from '../../actions/TaskActions';
import moment from 'moment';

import { GsServiceProtokolLogo } from '../../assets/images/Base64logo';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
//pdfMake.vfs = pdfMakeUnicode.pdfMake.vfs;
//pdfMake.vfs = t.pdfMake.vfs;
let i = check.pdfMake.vfs;
let j = pdfFonts.pdfMake.vfs;
pdfMake.vfs = { ...i, ...j };

pdfMake.fonts = {
	Roboto: {
		normal: 'Roboto-Regular.ttf',
		bold: 'Roboto-Medium.ttf',
		italics: 'Roboto-Italic.ttf',
		bolditalics: 'Roboto-Italic.ttf'
	},
	// FontAwesome: {
	//     normal: 'fontawesome-webfont.ttf',
	//     bold: 'fontawesome-webfont.ttf',
	//     italics: 'fontawesome-webfont.ttf',
	//     bolditalics: 'fontawesome-webfont.ttf'
	// }
	Fontello: {
		normal: 'fontello.ttf',
		bold: 'fontello.ttf',
		italics: 'fontello.ttf',
		bolditalics: 'fontello.ttf'
	}
}

class GeneratePdf extends Component {
	savePdfToEnteredData = pdfBase64 => event => {
		this.props.SavePropertyValue(this.props.app, "PdfService", pdfBase64);
	};
	downloadPDF = () => {
		const { Festgestellte, Ausgefuhrte, Masshaltigkeit, Benotigtes, Stabzahl, SollMass, IstMass, SollGelocht, SollUngelocht,
			IstGelocht, IstUngelocht, LangeFuhrungsscienen, IstLange, SollLange, FuhrungsschienenMontiert, FunktionstestAnlage,
			FotosGemacht, FehlerhafteMontage, Axialspiel, FehlerhafteProgramierung, Bedienfehler, Panzer, SignatureKunde,
			MotorSteuerung, Bedienungs, Endstab, UnzulassigeInstallation, ISRollo, ZusatzlicheAngaben,
			Aufhanger, Einlauftrichter, Fliegengitter, Wickeldurchmesser, Gurtwickler, ServiceTyp,StartDatum,
			TerminNotwendig, ProfilstabeVerbaut, Einbaurichtung, Sonstiges, ServicemonteurList, ServiceNr, Komision, Name, ekOrt, ekStrasse, ekPlz, ekTelefon1,
			Plz, Ort, Strasse
		} = this.props.app;

		const today = new Date();

		const bodyDataServicemonteur = [[{ text: 'Datum', style: 'tableHeader', alignment: 'center', rowSpan: 2 }, { text: 'Name Servicemonteur(e)', rowSpan: 2, style: 'tableHeader', alignment: 'center' }, { text: 'Uhrzeit', style: 'tableHeader', colSpan: 2, alignment: 'center' }, {}],
		[{ text: 'Header 1', style: 'tableHeader', alignment: 'center' }, { text: 'Header 1', style: 'tableHeader', alignment: 'center' }, { text: 'von', alignment: 'center' }, { text: 'bis', alignment: 'center' }]];
		ServicemonteurList.forEach(function (e) {
			let arr = [];
			let item = { text: `${e.Datum}`, alignment: 'center' };
			arr.push(item);
			let i = { text: `${e.Name}`, alignment: 'left' };
			arr.push(i);
			let j = { text: `${e.Von}`, alignment: 'left' };
			arr.push(j);
			let k = { text: `${e.Bis}`, alignment: 'left' };
			arr.push(k);
			bodyDataServicemonteur.push(arr);

		});
		// PDF document definition
		let docDefinition = {
			content: [
				{ text: 'Serviceauftrag', style: 'header' },
				{
					image: GsServiceProtokolLogo,
					width: 165,
					margin: [300, 0, 0, 0],
				},
				{
					style: 'tableExample',
					table: {
						widths: ['*', '*'],
						body: [
							[`Auftraggeber: ${Komision || ''}`, `Kunde / Bauvorhaben: ${Name || ''}`],
							[`${Strasse}`, `${ekStrasse}`],
							[`${Plz} ${Ort}`, `${ekPlz} ${ekOrt}`],
							[`Ansprechpartner - Telefon/Fax: Frau Weisskopf Susanne +49 3904667128`, `Ansprechpartner - Telefon/Fax: ${ekTelefon1}`],
						]
					}
				},
				{
					style: 'tableAuftragsnummer',
					table: {
						widths: ['*', '*', '*'],
						body: [
							[`Auftragsnummer: 
					${ServiceNr} `,
								`Auftragsdatumm: 
					${moment(StartDatum).format('DD.MM.YYYY')}`,
								`Termin: ca. Uhr: ${moment(StartDatum).format('HH:MM')}`],

						]
					}
				},
				{
					columns: [
						{
							text: [{ text: `${ServiceTyp === 'kundendienst' ? '' : ''} `, style: 'icon' }, ` Kundendienst`]
						},
						{
							text: [{ text: `${ServiceTyp === 'beratung' ? '' : ''} `, style: 'icon' }, ` Beratung / Begutachtung`]
						},
						{
							text: [{ text: `${ServiceTyp === 'serviceleistung' ? '' : ''} `, style: 'icon' }, ` Serviceleistung / Reparatur`]
						}
					],
					style: 'checkFirst'
				},
				{
					style: 'tableExample',
					color: '#000',
					table: {
						widths: ['*', '*'],
						//headerRows: 2,
						// keepWithHeaderRows: 1,
						body: [

							//[{text: 'festgestellte Mängel:', style: 'tableHeader', alignment: 'center'}, {text: 'Header 2', style: 'tableHeader', alignment: 'center'}],
							[{
								rowSpan: 4,
								text: [{ text: 'festgestellte Mängel: ', style: 'headerInsideTable' }, Festgestellte]
							},
							{ text: 'Prüfmaßnahmen vor Ort', style: 'headerInsideTable', border: [true, true, true, false] }],
							['',
								{
									border: [true, false, true, false],
									style: 'tableMargin',
									table: {
										widths: ['*', 'auto'],
										body: [
											[{ text: 'Maßhaltigkeit Element geprüft?', style: 'smallHeaderInsideTable', border: [false, false, false, false] },
											{ text: [{ text: `${Masshaltigkeit === true ? '' : ''} `, style: 'icon' }, ` ja`], border: [false, false, false, false] }]
										]
									}
								}],
							['', {
								border: [true, false, true, false],
								style: 'tableMargin',
								table: {
									widths: ['*', '*'],
									body: [
										[{ text: 'SOLL Maß lt. ZA / Auftrag:', border: [false, false, false, false] },
										{ text: `${SollMass}`, border: [false, false, false, true] }]
									]
								}
							}
							],
							['', {
								border: [true, false, true, false],
								style: 'tableMargin',
								table: {
									widths: ['*', '*'],
									body: [
										[{ text: 'IST Maß lt. ZA / Auftrag:', border: [false, false, false, false] },
										{ text: `${IstMass}`, border: [false, false, false, true] }]
									]
								}
							}],
							[
								{
									rowSpan: 4,
									text: [{ text: 'ausgeführte Arbeiten: ', style: 'headerInsideTable' }, Ausgefuhrte]
								},
								{
									border: [true, false, true, false],
									style: 'tableMargin',
									table: {
										widths: ['*', 'auto'],
										body: [
											[{ text: 'Stabzahl Panzer überprüft?', style: 'smallHeaderInsideTable', border: [false, false, false, false] },
											{ text: [{ text: `${Stabzahl === true ? '' : ''} `, style: 'icon' }, ` ja`], border: [false, false, false, false] }]
										]
									}
								}],
							['', {
								border: [true, false, true, false],
								style: 'tableMargin',
								table: {
									widths: ['*', '*', '*', '*', '*'],
									body: [
										[
											{ text: 'SOLL Zahl:', border: [false, false, false, false] },
											{ text: 'gelocht', border: [false, false, false, false] },
											{ text: `${SollGelocht}`, border: [false, false, false, true] },
											{ text: 'ungelcoht', border: [false, false, false, false] },
											{ text: `${SollUngelocht}`, border: [false, false, false, true] }
										]
									]
								}
							}],
							['', {
								border: [true, false, true, false],
								style: 'tableMargin',
								table: {
									widths: ['*', '*', '*', '*', '*'],
									body: [
										[
											{ text: 'IST Zahl:', border: [false, false, false, false] },
											{ text: 'gelocht', border: [false, false, false, false] },
											{ text: `${IstGelocht}`, border: [false, false, false, true] },
											{ text: 'ungelcoht', border: [false, false, false, false] },
											{ text: `${IstUngelocht}`, border: [false, false, false, true] }
										]
									]
								}
							}],
							['', {
								border: [true, false, true, false],
								style: 'tableMargin',
								table: {
									widths: ['*', 'auto'],
									body: [
										[{ text: 'Länge Führungsschienen geprüft?', style: 'smallHeaderInsideTable', border: [false, false, false, false] },
										{ text: [{ text: `${LangeFuhrungsscienen === true ? '' : ''} `, style: 'icon' }, ` ja`], border: [false, false, false, false] }]
									]
								}
							}],
							[
								{
									rowSpan: 5,
									text: [{ text: 'benötigtes Material: ', style: 'headerInsideTable' }, Benotigtes],
									border: [true, true, true, true],
								}, {
									border: [true, false, true, false],
									style: 'tableMargin',
									table: {
										widths: ['*', '*'],
										body: [
											[{ text: 'SOLL Länge lt. ZA / Auftrag:', border: [false, false, false, false] },
											{ text: `${SollLange}`, border: [false, false, false, true] }]
										]
									}
								}],
							['', {
								border: [true, false, true, false],
								style: 'tableMargin',
								table: {
									widths: ['*', '*'],
									body: [
										[{ text: 'IST Länge lt. ZA / Auftrag:', border: [false, false, false, false] },
										{ text: `${IstLange}`, border: [false, false, false, true] }]
									]
								}
							}],
							['', {
								border: [true, false, true, false],
								style: 'tableMargin',
								table: {
									widths: ['*', 'auto'],
									body: [
										[{ text: 'Führungsschienen richtig montiert?', border: [false, false, false, false] },
										{ text: [{ text: `${FuhrungsschienenMontiert === true ? '' : ''} `, style: 'icon' }, ` ja`], border: [false, false, false, false] }]
									]
								}
							}],
							['', {
								border: [true, false, true, false],
								style: 'tableMargin',
								table: {
									widths: ['*', 'auto'],
									body: [
										[{ text: 'Funktionstest Anlage durchgeführt?', border: [false, false, false, false] },
										{ text: [{ text: `${FunktionstestAnlage === true ? '' : ''} `, style: 'icon' }, ` ja`], border: [false, false, false, false] }]
									]
								}
							}],
							['', {
								border: [true, false, true, true],
								style: 'tableMargin',
								table: {
									widths: ['*', 'auto'],
									body: [
										[
											{ text: 'Fotos gemacht?', border: [false, false, false, false] },
											{ text: [{ text: `${FotosGemacht === true ? '' : ''} `, style: 'icon' }, ` ja`], border: [false, false, false, false] }]
									]
								}
							}],
						]
					}
				},
				{ text: 'Fehlerursache:', style: 'subheaderFehler' },
				{
					style: 'noBorderTable',
					table: {
						widths: ['*', '*', '*'],
						body: [
							[
								{ text: [{ text: `${FehlerhafteMontage === true ? '' : ''} `, style: 'icon' }, ` fehlerhafte Montage`], style: "iconAlign" },
								{ text: [{ text: `${Axialspiel === true ? '' : ''} `, style: 'icon' }, ` Axialspiel (zu groß / zu klein)`], style: "iconAlign" },
								{ text: [{ text: `${FehlerhafteProgramierung === true ? '' : ''} `, style: 'icon' }, ` Programmierung/-einstellung`], style: "iconAlign" }
							],
							[
								{ text: [{ text: `${Bedienfehler === true ? '' : ''} `, style: 'icon' }, ` Bedienfehler`], style: "iconAlign" },
								{ text: [{ text: `${Panzer === true ? '' : ''} `, style: 'icon' }, ` Panzer Arretierung (defekt / klemmt)`], style: "iconAlign" },
								{ text: [{ text: `${MotorSteuerung === true ? '' : ''} `, style: 'icon' }, ` Motor / Uhr / Steuerung defekt`], style: "iconAlign" }
							],
							[
								{ text: [{ text: `${Bedienungs === true ? '' : ''} `, style: 'icon' }, ` Bedienungs-u. Wartungsanleitung vorhanden`], style: "iconAlign" },
								{ text: [{ text: `${Endstab === true ? '' : ''} `, style: 'icon' }, ` Endstab verkantet in der Führungsschiene`], style: "iconAlign" },
								{ text: [{ text: `${UnzulassigeInstallation === true ? '' : ''} `, style: 'icon' }, ` unzulässige elektrische Installation`], style: "iconAlign" }
							],
							[
								{ text: [{ text: `${ISRollo === true ? '' : ''} `, style: 'icon' }, ` IS-Rollo (Spannung / Gaze / Endleiste)`], style: "iconAlign" },
								{ text: [{ text: `${Aufhanger === true ? '' : ''} `, style: 'icon' }, ` Aufhänger abgebrochen (Federn / Starre)`], style: "iconAlign" },
								{ text: [{ text: `${Einlauftrichter === true ? '' : ''} `, style: 'icon' }, ` Einlauftrichter (zu klein / lose)`], style: "iconAlign" }
							],
							[
								{ text: [{ text: `${Fliegengitter === true ? '' : ''} `, style: 'icon' }, ` Fliegengitter (Gaze defekt / Profil)`], style: "iconAlign" },
								{ text: [{ text: `${Wickeldurchmesser === true ? '' : ''} `, style: 'icon' }, ` Wickeldurchmesser zu groß`], style: "iconAlign" },
								{ text: [{ text: `${Gurtwickler === true ? '' : ''} `, style: 'icon' }, ` Gurtwickler / Kurbelgetriebe defekt`], style: "iconAlign" }
							], [
								{ text: [{ text: `${TerminNotwendig === true ? '' : ''} `, style: 'icon' }, ` weiterer Termin notwendig`], style: "iconAlign" },
								{ text: [{ text: `${ProfilstabeVerbaut === true ? '' : ''} `, style: 'icon' }, ` zu viele / zu wenige Profilstäbe verbaut`], style: "iconAlign" },
								{ text: [{ text: `${Einbaurichtung === true ? '' : ''} `, style: 'icon' }, ` Einbaurichtung (Nord / Ost / Süd / West)`], style: "iconAlign" }
							], [
								{ text: [{ text: `${Sonstiges === true ? '' : ''} `, style: 'icon' }, ` Sonstiges`] }, '', ''
							]
						]
					},
					layout: 'noBorders'
				},
				{
					style: 'tableExample',
					table: {
						widths: ['*'],
						body: [
							[{ text: `Zusätzliche Angaben: ${ZusatzlicheAngaben}`, border: [false, false, false, true] }],

						]
					}
				},
				{
					style: 'tableServicemonteur',
					table: {
						widths: [80, '*', 70, 70],
						headerRows: 2,
						body: bodyDataServicemonteur
					}
				},
				{ text: 'Abnahmebestätigung:', style: 'subheader1' },
				{
					columns: [

						{
							text: `Hiermit wird die Arbeitszeit, die einwandfreie Ausführung der Arbeiten sowie eine ordnungsgemäße
Funktion nach der Abnahme bestätigt. Diesem Auftrag liegen unsere allgemeinen Geschäftsbedingungen
zugrunde.`
						}
					],
					style: 'check'
				},
				{
					style: 'tableUnterschrift',
					table: {
						widths: ['*', '*', '*'],
						body: [
							[
								{ text: `${ekOrt}, ${moment(today).format('DD.MM.YYYY')}`, border: [false, false, false, true], margin: [0, 20, 0, 0],fontSize: 10, bold:true  },
								{ text: '', border: [false, false, false, false] },
								SignatureKunde == null ? { text: '', width: 100, border: [false, false, false, true] } : { image: `${SignatureKunde}`, width: 100, border: [false, false, false, true] }
							],
							[
								{ text: 'Ort, Datum:', border: [false, false, false, false] },
								{ text: '', border: [false, false, false, false] },
								{ text: 'Unterschrift Bauherr / Eigentümer:', border: [false, false, false, false] }]

						]
					}
				},
				{
					text: `ROLTEX Rolladenfabrikation GmbH • Zum Gewerbegebiet 4 • 04769 Mügeln • Tel.: 03 43 62 / 4 22 - 0 • Fax: 03 43 62 / 4 22 - 11 • e-Mail: info@roltex.de • Internet:
www.roltex.de`, style: 'footer'
				}
			],
			styles: {
				header: {
					fontSize: 22,
					bold: false,
					margin: [0, 30, 0, -55]
				},
				headerInsideTable: {
					fontSize: 12,
					bold: true
				},
				smallHeaderInsideTable: {
					fontSize: 9,
					bold: true
				},
				subheader: {
					fontSize: 12,
					bold: true,
					margin: [0, 10, 0, 5]
				},
				subheaderFehler: {
					fontSize: 12,
					bold: true,
					margin: [0, 0, 0, 5]
				},
				subheader1: {
					fontSize: 10,
					bold: true
				},
				tableExample: {
					margin: [0, 5, 0, 10],
					fontSize: 8,
				},
				tableServicemonteur: {
					margin: [0, 5, 0, 5],
					fontSize: 8,
				},
				tableAuftragsnummer: {
					margin: [0, -5, 0, 15],
					fontSize: 8,
				},
				check: {
					fontSize: 10
				},
				checkFirst: {
					fontSize: 8,
					margin: [0, -10, 0, 0]
				},
				tableHeader: {
					bold: false,
					fontSize: 10,
					color: 'black'
				},
				tableMargin: {
					margin: [0, -5, 0, -3]
				},
				tableUnterschrift: {
					fontSize: 8,
					margin: [0, 0, 0, 15],
				},
				footer: {
					fontSize: 6
				},
				icon: {
					font: 'Fontello',
					fontSize: 12
				},
				iconAlign: {
					margin: [0, 0, 0, -5]
				},
				noBorderTable: {
					margin: [0, 0, 0, -5],
					fontSize: 8
				}
			},
			defaultStyle: {
				// alignment: 'justify'
			},


		}
		//pdfMake.createPdf(docDefinition).open();
		const pdfDocGenerator = pdfMake.createPdf(docDefinition);
		pdfDocGenerator.getBase64((data) => {
			this.props.SavePropertyValue(this.props.app, "pdf", data);
			this.props.history.goBack();
		});

	};

	render() {
		console.log("GeneratePDF props: ", this.props);
		return (
			<div>
				<Button color="primary" variant="contained" onClick={this.downloadPDF}>Save</Button>
			</div>
		)
	}
}

const mapStateToProps = (state) => (
	{
		app: EnteredDataForServicePDF(state)
	}
)

export default withRouter(connect(mapStateToProps, { SavePropertyValue })(GeneratePdf));
