import { SET_URL, SET_BERATER, SET_CHATURL, SAVE_SETTINGS, SET_FILE_SIZE } from './types';
import dbSettings from '../db/DbSettings';

export const SetURL = (new_url) => {        
    return {
        type: SET_URL,
        payload: new_url
    };    
}

export const SetBerater = (new_berater) => {
    return {
        type: SET_BERATER,
        payload: new_berater
    };
}

export const SetChatURL = (new_url) => {        
    return {
        type: SET_CHATURL,
        payload: new_url
    };    
}

export const SetFileSize = (new_file_size) => {        
    return {
        type: SET_FILE_SIZE,
        payload: new_file_size
    };    
}

export const SaveSettings = (settings) => async(dispatch) => { 
  
    let savedSettings = await(new dbSettings()).SaveSettings(settings);       
    dispatch({
        type: SAVE_SETTINGS,
        settings: savedSettings
    });  
}

