import React from 'react'
import { Button } from '@material-ui/core';
import { Update } from '../bundles/IconsBundle';
import { useSelector } from 'react-redux';

export default function UpdateComponent() {
    const sw = useSelector(state => state.app.serviceWorkerRegistration);
    const updateServiceWorker = (e, app) => {
        
        //const sw = app.serviceWorkerRegistration;
        console.log("Sw 1: ", sw);
        if (sw) {
            console.log("Sw 2: ", sw);
            const registrationWaiting = sw.waiting;
            if (registrationWaiting) {
                console.log("registration waiting skip waiting:", registrationWaiting);
                registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

                // window.ServiceWorker.addEventListener("install", event => {
                //     console.log("Install event :", event);
                //     window.ServiceWorker.skipWaiting()
                //   })
                // registrationWaiting.skipWaiting();



                registrationWaiting.addEventListener('statechange', e => {
                    console.log("Statechange", e);
                    if (e.target.state === 'activated') {
                        window.location.reload();
                    }
                });
            }
        }
    };

    return (
        <Button
            style={{ backgroundColor: "#ffffff", color: '#818284', fontWeight: '700' }}
            onClick={updateServiceWorker}
            variant="contained">
            <Update />Update
        </Button>
    )
}