import {
    COMPANY_BROEMSE,
    COMPANY_EVERS,
    COMPANY_GROWE,
    COMPANY_OST
} from "../helpers/constants";

const pdfBsSettings = () => {
    return {
        Berater: {
            x: 180,
            y: 187
        },
        Kunde: {
            x: 412,
            y: 187
        },
        ekOrt: {
            x: 90,
            y: 210,
            size: 10,
            colorRgb: [0, 0, 1]
        },
        CurrentDate: {
            x: 335,
            y: 210,
            size: 10,
            colorRgb: [0, 0, 1]
        },
        erledigteArbeit: {
            x: 70,
            y: 400,
            size: 12,
            colorRgb: [0, 0, 1]
        },
        AdditionalServiceNeeded: {
            x: 72,
            y: 137,
            size: 14,
            colorRgb: [0, 0, 1]
        },
        hasBild: {
            x: 72,
            y: 123,
            size: 14,
            colorRgb: [0, 0, 1]
        },
        isErledigt: {
            x: 72,
            y: 151,
            size: 14,
            colorRgb: [0, 0, 1]
        }
    };
};

const pdfOstSettings = () => {
    return {
        Berater: {
            x: 35,
            y: 215
        },
        Kunde: {
            x: 280,
            y: 215
        },
        ekOrt: {
            x: 90,
            y: 211,
            size: 10,
            colorRgb: [0, 0, 1]
        },
        CurrentDate: {
            x: 90,
            y: 510,
            size: 10,
            colorRgb: [0, 0, 0]
        },
        erledigteArbeit: {
            x: 55,
            y: 473,
            size: 10,
            colorRgb: [0, 0, 0]
        },
        AdditionalServiceNeeded: {
            x: 0,
            y: 0,
            size: 14,
            colorRgb: [1, 1, 1]
        },
        hasBild: {
            x: 72,
            y: 123,
            size: 14,
            colorRgb: [0, 0, 1]
        },
        isErledigt: {
            x: 380,
            y: 245,
            size: 14,
            colorRgb: [0, 0, 1]
        }
    };
};

const pdfSettingsData = company => {
    switch (company) {
        case COMPANY_BROEMSE:
        case COMPANY_EVERS:
            return pdfBsSettings();
        case COMPANY_GROWE:
            return pdfBsSettings();
        case COMPANY_OST:
            return pdfOstSettings();
        default:
            return pdfBsSettings();
    }
};

export default pdfSettingsData;
