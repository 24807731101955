import { DO_SYNC, STOP_SYNC, REPORT_PROGRESS, INITIALISE_STATE } from './types';
import dbTasks from '../db/DbTasks';


export const DoSync = (settings, ReportProgressHandler) => dispatch => {
            let db = new dbTasks();
             
            db.doSync(settings, ReportProgressHandler)
                .then(async ()=> { return db.getAllData(); }) 
                .then(res=> { 
                    dispatch({
                    type: INITIALISE_STATE,
                    allDbData: res                    
                })
            })                  
                .then(tasks=> dispatch({
                    type: DO_SYNC
                }))                       
                .catch(err=> {
                   alert(err);
                    dispatch({
                        type: STOP_SYNC                  
                    })
                });
            
    }

    export const ReportProgress = (val) => dispatch => {   
        dispatch({
            type: REPORT_PROGRESS,
            payload: val
        });
    }

    export const StopSync = () => dispatch => {   
        dispatch({
            type: STOP_SYNC
        });
    }

        


