import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/de';
import convertFile from './extensions/FileConverter';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { TimePicker } from '@material-ui/pickers';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { CloseIcon, DeleteIcon, LocationIcon, CalendarIcon, TimeIcon, KommissionIcon, PositionIcon, PhoneIcon, AttachmentIcon, RefreshIcon, SaveIcon, EditIcon } from '../bundles/IconsBundle';
import TakePhoto from './extensions/TakePhoto';
import MessageBox from './dialogs/MessageBox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Loader from './extensions/Loader';

import { setTitle } from '../actions/AppActions';
import {
    SelectAppointmentByID,
    SetPropertyValue,
    SavePropertyValue,
    SaveData, FotoAdd,
    FotoRemove,
    DownloadFileManually,
    SetDownloadInProgress
} from '../actions/TaskActions';
import { SelectedAppSelector, SelectedAppAttachmentsSelector, SelectedAppNotesSelector, SelectedAppPhotosSelector } from '../selectors/SelectedAppointmentSelector'
import DownloadFileDialog from "./dialogs/menck/DownloadFileDialog";
import { COMPANY_EVERS } from '../helpers/constants';
import { DialogActions, DialogContent } from '@material-ui/core';
const styles = {
    container: {
        textAlign: 'left',
        marginTop: '75px',
        marginLeft: '20px',
        marginRight: '20px'
    },
};


class TaskView extends Component {
    state = {
        dialog: false,
        TakePhotoOpen: false,
        images: this.props.images || [],
        _deleteImageId: -1,
        _deleteImageMessageOpen: false,
        downloadFileDialogOpen: false,
        fileNameForDownload: "",
        fileExtForDownload: "",
        fileIdForDownload: -1,
        downloadInProgress: false,
        editMode: false,
    }

    file_input = [];

    editModeEnabled = (isEnabled) => {
        this.setState({ editMode: isEnabled });
    }

    componentDidMount() {
        moment.locale("de");
        this.props.SelectAppointmentByID(this.props.match.params.id, this.props.match.params.service);
    }

    componentDidUpdate() {
        //console.log("Završeno skidanje fajla");

        if (this.state.downloadFileDialogOpen !== this.props.downloadInProgress) {
            this.setState({ downloadFileDialogOpen: this.props.downloadInProgress });
        }

    }

    _returnDescriptionData = (data) => {
        return JSON.parse(data !== undefined ? data.toString() : "[]");
    }

    materialTypeToName = (item) => {
        var material = "";
        if (item.MaterialType === 0) material = "PVC_Fenster";
        if (item.MaterialType === 1) material = "PVC_Hausturen";
        if (item.MaterialType === 2) material = "Rolladen";
        if (item.MaterialType === 3) material = "ALU_Hausturen";
        if (item.MaterialType === 4) material = "ALU_Fenster";
        if (item.MaterialType === 5) material = "HST";
        if (item.MaterialType === 6) material = "Glas";
        if (item.MaterialType === 7) material = "Fullungen";
        if (item.MaterialType === 8) material = "Zubehor";
        if (item.MaterialType === 9) material = "Sonstiges ";
        return material;
    }


    handleClose = () => {
        this.props.history.goBack();
    }

    fotoMachen = () => {
        this.setState({ TakePhotoOpen: true, images: this.state.images });
    }

    closeTakePhoto = () => {
        this.setState({ TakePhotoOpen: false });
    }

    handleTakeScreenshot = (src) => {
        this.props.FotoAdd(this.props.app, src);
        var st = this.state;
        //st.images.push(src);
        st.TakePhotoOpen = false;
        this.setState(st);
    }

    removeImage = (idToRemove) => {
        var st = this.state;
        st._deleteImageId = idToRemove;
        st._deleteImageMessageOpen = true;
        this.setState(st);
    }

    onRemoveImage = () => {
        var st = this.state;
        //st.images = this.state.images.filter((item,index) => index !== this.state._deleteImageId)
        this.props.FotoRemove(st._deleteImageId);
        st._deleteImageId = -1;
        st._deleteImageMessageOpen = false;
        this.setState(st);
    }

    handleCloseMessage = () => {
        var st = this.state;
        st._deleteImageMessageOpen = false;
        this.setState(st);
    }

    openDocument = att => {
        if (att.Attachment != null) {
            convertFile(att.FileName, att.Extension, att.Attachment);
        } else {
            //Dodati akciju za setovanje otvorenog dijaloga.
            this.props.SetDownloadInProgress(true); //akcija za setovanje u state da je dialog za download otvoren
            this.setState({
                ...this.state,
                downloadFileDialogOpen: true,
                fileNameForDownload: att.FileName,
                fileExtForDownload: att.Extension,
                fileIdForDownload: att.id
            });
        }
    };

    ShowPDF = (att) => {
        convertFile("Bericht", ".pdf", att, this.props.appInfo.company);
    }

    signDialog = (files) => {
        //files[0] je posljednji dodani dokumentk koji treba potpisati.
        let company = this.props.settings.Company || "";
        if (company === "BROEMSE" || files[0].Attachment != null) {
            this.props.history.push("/tasksign/" + this.props.match.params.id + "/" + this.props.match.params.service + "/edit");
            this.setState({ ...this.state, dialog: true });
        } else {
            //Fajl postoji ali njegov attachment nije preuzet zbog ograničenja za download / slučaj kada je maxFileSize = 0 ili manji od veličine dokumenta.
            //Potrebno pokrenuti postupak za download fajla
            this.openDocument(files[0]);
        }

    }

    saveData = async () => {
        const data = {
            Finished: this.props.app.Finished,
            StartTime: this.props.app.StartTime,
            Duration: this.props.app.Duration,
            ErledigteArbeiten: this.props.app.ErledigteArbeiten,
            ekStrasse: this.props.app.ekStrasse,
            ekOrt: this.props.app.ekOrt,
            ekPlz: this.props.app.ekPlz,
            ekTelefon1: this.props.app.ekTelefon1,
            ekTelefon2: this.props.app.ekTelefon2,
        };
        await this.props.SaveData(this.props.app, data);
    }

    handleChange = async (e, checked) => {
        console.log(this.props.app.IDService);
        if (e._d) {
            await this.props.SavePropertyValue(this.props.app, "StartTime", e._d);
        } else {
            if (e.target.name === "Finished") {
                // if(!this.props.app.Started){
                //     alert("Appointment not started !");
                //     return;
                // }
                await this.props.SetPropertyValue(this.props.app, e.target.name, checked ? 1 : 0);
                this.saveData();
            }
            else
                await this.props.SetPropertyValue(this.props.app, e.target.name, e.target.value);
        }

    }

    handleBlur = async (e) => {
        this.saveData();

    }

    selectFile = (event) => {
        event.preventDefault();
        console.log("FI", this.file_input);
        this.file_input = document.createElement('input');
        this.file_input.addEventListener("change", this.uploadFile.bind(this), false);
        this.file_input.type = 'file';
        this.file_input.accept = ".png, .jpg, .jpeg"
        this.file_input.click();
    }


    isDocumentUpdateNeeded = () => {
        const pdfData = this.props.app.pdfFieldValues;
        const appData = this.props.app;
        if (!pdfData) return false;
        if (pdfData.AdditionalServiceNeeded !== appData.AdditionalServiceNeeded) return true;
        if (pdfData.Finished !== appData.Finished) return true;
        if (pdfData.Duration !== appData.Duration) return true;
        if (pdfData.ErledigteArbeiten !== appData.ErledigteArbeiten) return true;
        if (pdfData.StartTime !== appData.StartTime) return true;
        if (pdfData.hasBild !== (this.props.fotos.length > 0)) return true;

        return false;
    }

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    uploadFile = () => {

        this.getBase64(this.file_input.files[0])
            .then(src => this.props.FotoAdd(this.props.app, src));

    }

    //Download fajlova koji nisu inicijalno povučeni zbog ograničenja veličine fajlova.
    downloadFile = async () => {
        // console.log("File ID:", this.state.fileForDownloadID);
        this.setState({ ...this.state, downloadInProgress: true });
        let fileName = this.state.fileNameForDownload + this.state.fileExtForDownload;
        let res = await this.props.DownloadFileManually(this.props.settings, this.props.app.IDService, fileName, this.state.fileIdForDownload);
        if (!res) {
            this.handleCloseDialogs();
            alert("Datei nicht gefunden !");
        } else {
            this.handleCloseDialogs();
        }
    }

    handleCloseDialogs = () => {
        this.setState({
            ...this.state,
            dialog: false,
            TakePhotoOpen: false,
            _deleteImageMessageOpen: false,
            downloadFileDialogOpen: false,
            downloadInProgress: false
        });
        this.props.SetDownloadInProgress(false);
    };

    handleEditedChange = (e) => {
        this.props.SetPropertyValue(this.props.app, e.target.name, e.target.value)
    }

    saveEdited = () => {
        this.saveData();
        this.editModeEnabled(false);
    }

    render() {

        const { app, notes, attachments, fotos, settings } = this.props;
        const pattern = new RegExp("(Bericht)*(Bericht\\d*$)");
        const berichtDocumentsFiltered = attachments.filter(c => { return pattern.exec(c.FileName) !== null ? true : false });

        let berichtDocs = berichtDocumentsFiltered.sort((a, b) => a.id < b.id ? 1 : -1)

        console.log("Settings company :", settings.Company);
        settings.Company = settings.Company || "";
        return (

            <Dialog fullScreen open={true}>
                <AppBar >
                    <Toolbar>
                        <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                            <CloseIcon />
                        </IconButton>

                        <div>
                            <div>{app.ServiceNr} ({app.AbNr}) - {app.ekName}</div>
                            {settings.Company === COMPANY_EVERS ? (
                                <div>({app.ekPlz} - {app.ekOrt}) - {app.StartDate !== null ? moment(app.StartDate).format("DD.MM.YYYY - HH:mm") : ""}</div>
                            ) : (
                                    <div>({app.ekPlz} - {app.ekOrt})</div>
                                )}
                        </div>


                    </Toolbar>
                </AppBar>

                <div style={styles.container} >
                    {/* Service info */}
                    <Table>
                        <TableBody>
                            <TableRow style={{ height: 10 }}>
                                <TableCell><LocationIcon fontSize="small" /></TableCell>
                                <TableCell>Endkunde</TableCell>
                                <TableCell>{app.ekStrasse}, {app.ekOrt} {app.ekPLZ}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><LocationIcon fontSize="small" /></TableCell>
                                <TableCell>Firma</TableCell>
                                <TableCell>{app.Strasse}, {app.Ort} {app.PLZ}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><CalendarIcon fontSize="small" /></TableCell>
                                <TableCell>Start</TableCell>
                                <TableCell>{moment(app.StartDate).format("DD.MM YYYY HH:mm")}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><CalendarIcon fontSize="small" /></TableCell>
                                <TableCell>Ende</TableCell>
                                <TableCell>{moment(app.EndDate).format("DD.MM YYYY HH:mm")}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><TimeIcon fontSize="small" /></TableCell>
                                <TableCell>Dauer</TableCell>
                                <TableCell>{app.Duration}min</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><KommissionIcon fontSize="small" /></TableCell>
                                <TableCell>Kommission</TableCell>
                                <TableCell>{app.Komision}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><PositionIcon fontSize="small" /></TableCell>
                                <TableCell>Position</TableCell>
                                <TableCell>{app.ServicePosition}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><PhoneIcon fontSize="small" /></TableCell>
                                <TableCell>Ek. Tel 1:</TableCell>
                                <TableCell><a href={'tel:' + app.ekTelefon1} >{app.ekTelefon1}</a></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><PhoneIcon fontSize="small" /></TableCell>
                                <TableCell>Ek. Tel 2:</TableCell>
                                <TableCell><a href={'tel:' + app.ekTelefon2}>{app.ekTelefon2}</a></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<EditIcon />}
                                        onClick={() => this.editModeEnabled(true)}
                                    >
                                        Endkunden daten bearbeiten
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <br />
                    {/* Mangelbeschreibung */}
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ fontSize: 14 }} colSpan={4}>
                                    Mangelbeschreibung
                            </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Pos</TableCell>
                                <TableCell>Material</TableCell>
                                <TableCell>Element</TableCell>
                                <TableCell>Notiz</TableCell>
                            </TableRow>
                            {
                                this._returnDescriptionData(app.ServiceMangelbeschreibung).map(item => {
                                    return (
                                        <React.Fragment key={item.ID}>
                                            <TableRow style={{ backgroundColor: 'lightgray' }}>
                                                <TableCell style={{ border: 0 }}>{item.Position}</TableCell>
                                                <TableCell style={{ border: 0 }}>{this.materialTypeToName(item)}</TableCell>
                                                <TableCell style={{ border: 0 }}>{item.Description}</TableCell>
                                                <TableCell style={{ border: 0 }}>{item.Notiz}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell colSpan={3}>{item.Mangelbechreibung}</TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>

                    <br />
                    {/* Notiz */}
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ fontSize: 14 }} colSpan={4}>
                                    Notiz
                                </TableCell>
                            </TableRow>
                            {
                                notes.map(item => {
                                    const attachments = this.props.attachments.filter(c => c.NoteID === item.id);
                                    const attDom = attachments.map(item => {
                                        return (<span key={item.id}><AttachmentIcon fontSize="small" onClick={() => { this.openDocument(item) }} />{item.FileName}{item.Extension}</span>);
                                    });
                                    return (
                                        <React.Fragment key={item.id}>
                                            <TableRow>
                                                <TableCell style={{ border: 0 }} >{item.Note ? item.Note : "-"}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell><b><span style={{ fontSize: '.8em' }}>{item.MitarbeiterName} {moment(item.NoteDate).format("DD.MM YYYY HH:mm")} {attDom}</span></b></TableCell>
                                            </TableRow>
                                        </React.Fragment>

                                    );
                                })
                            }
                        </TableBody>
                    </Table>

                    <br />
                    {/* Dokument unterzeichnung */}
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ fontSize: 14, border: 0 }} colSpan={2}>
                                    Dokument unterzeichnung:
                            </TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell style={{ border: 0 }}>
                                    <Button variant="outlined" color="primary" onClick={() => this.signDialog(berichtDocs)} disabled={settings.Company !== "BROEMSE" ? berichtDocs.length > 0 ? false : true : false}>Unterzeichnen</Button><RefreshIcon font="small" visibility={this.isDocumentUpdateNeeded() ? 'visible' : 'hidden'} />
                                </TableCell>
                                <TableCell style={{ border: 0 }}>
                                    <Button variant="outlined" color="primary" disabled={!(this.props.app.pdf)} onClick={() => { this.ShowPDF(this.props.app.pdf) }}>Dokument anzeigen</Button>
                                </TableCell>
                            </TableRow>
                            { settings.Company !== "BROEMSE" &&
                                berichtDocs.length === 0 &&
                                <TableRow>
                                    <TableCell style={{ color: 'red' }} colSpan={2}><span style={{ color: 'red' }}><br /><span>Bitte Dokument zuerst erstellen lassen von dem Service Planer!</span></span></TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>

                    <br />
                    {/* Form fields */}
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ border: 0 }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.props.app.Finished === 1}
                                                onChange={this.handleChange}
                                                name="Finished"
                                                value="checkedB"
                                                color="primary"
                                            //disabled={true}
                                            />
                                        }
                                        label="Fertig"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell style={{ border: 0 }}>
                                        <TimePicker 
                                        variant="filled"
                                        ampm={false}                                        
                                        name="StartTime"
                                        label="Start zeit"
                                        format="HH:mm"
                                        value={this.props.app.StartTime ? this.props.app.StartTime : this.props.app.StartDate}
                                        onChange={this.handleChange}
                                        onBlur={this.handleBlur}
                                        />
                                </TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell style={{ border: 0 }}>
                                    <TextField multiline style={{ width: '100%' }}
                                        name="Duration"
                                        label="Benoetigte zeit (min)"
                                        margin="dense"
                                        onChange={this.handleChange}
                                        onBlur={this.handleBlur}
                                        value={this.props.app.Duration}
                                        type="number"
                                        variant="filled" />
                                </TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell style={{ border: 0 }}>
                                    <TextField multiline style={{ width: '100%' }}
                                        name="ErledigteArbeiten"
                                        label="Erledigte Arbeiten"
                                        onChange={this.handleChange}
                                        onBlur={this.handleBlur}
                                        value={this.props.app.ErledigteArbeiten}
                                        margin="dense"
                                        variant="filled" />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <br />
                    {/* Fotos */}
                    <Table>
                        <TableBody>
                            <TableRow >
                                <TableCell style={{ border: 0 }}>
                                    <GridList cellHeight={160} cols={3}>
                                        {fotos.map((tile, index) => (
                                            <GridListTile cols={1} key={index} style={{ border: '1px solid #DDD' }}>
                                                <img src={tile.data} alt="" />
                                                <GridListTileBar
                                                    actionIcon={
                                                        <IconButton onClick={() => { this.removeImage(tile.id) }}>
                                                            <DeleteIcon color="secondary"  />
                                                        </IconButton>
                                                    }
                                                />
                                            </GridListTile>
                                        ))}
                                    </GridList>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ border: 0 }}><Button variant="contained" color="primary" onClick={this.fotoMachen} >Foto machen</Button> &nbsp;<Button onClick={this.selectFile} variant="contained" color="primary">Foto hinzufuegen</Button></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                </div>
                <TakePhoto open={this.state.TakePhotoOpen} handleClose={this.closeTakePhoto} handleTakeScreenshot={this.handleTakeScreenshot} />
                <MessageBox open={this.state._deleteImageMessageOpen}
                    Title="Foto Loeschen?" MessageBox="Sind Sie Sicher?" AcceptText="Loeschen" CancelText="Abbrechen"
                    handleClose={this.handleCloseMessage} handleAccept={this.onRemoveImage} />
                <Dialog fullScreen open={this.state.dialog} >
                    <AppBar >
                        <Toolbar>
                            <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                                <CloseIcon />
                            </IconButton>
                            <div>
                                <div>Serviceauftrag Daten einfügen</div>
                            </div>
                        </Toolbar>
                    </AppBar>
                </Dialog>

                <Dialog fullScreen open={this.state.editMode} >
                    <AppBar >
                        <Toolbar>
                            <IconButton color="inherit" onClick={() => this.editModeEnabled(false)} aria-label="Close">
                                <CloseIcon />
                            </IconButton>
                            <div>
                                <div>Servicedaten bearbeiten</div>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <div style={styles.container}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell><LocationIcon fontSize="small" /></TableCell>
                                        <TableCell>Straße</TableCell>
                                        <TableCell>
                                            <TextField
                                                name="ekStrasse"
                                                value={app.ekStrasse}
                                                onChange={e => this.handleEditedChange(e)}
                                                margin="normal"
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><LocationIcon fontSize="small" /></TableCell>
                                        <TableCell>Ort</TableCell>
                                        <TableCell>
                                            <TextField
                                                name="ekOrt"
                                                value={app.ekOrt}
                                                onChange={e => this.handleEditedChange(e)}
                                                margin="normal"
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><LocationIcon fontSize="small" /></TableCell>
                                        <TableCell>PLZ</TableCell>
                                        <TableCell>
                                            <TextField
                                                name="ekPlz"
                                                value={app.ekPlz}
                                                onChange={e => this.handleEditedChange(e)}
                                                margin="normal"
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><PhoneIcon fontSize="small" /></TableCell>
                                        <TableCell>Telefon 1</TableCell>
                                        <TableCell>
                                            <TextField
                                                name="ekTelefon1"
                                                value={app.ekTelefon1}
                                                onChange={e => this.handleEditedChange(e)}
                                                margin="normal"
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><PhoneIcon fontSize="small" /></TableCell>
                                        <TableCell>Telefon 2</TableCell>
                                        <TableCell>
                                            <TextField
                                                name="ekTelefon2"
                                                value={app.ekTelefon2}
                                                id="ekTelefon2"
                                                onChange={e => this.handleEditedChange(e)}
                                                margin="normal"
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => this.saveEdited()}
                            startIcon={<SaveIcon />}>Speichern</Button>
                    </DialogActions>
                </Dialog>

                <DownloadFileDialog
                    open={this.state.downloadFileDialogOpen}
                    handleClose={this.handleCloseDialogs}
                    downloadFile={this.downloadFile}
                    downloadInProgress={this.state.downloadInProgress}
                />
            </Dialog>

        )
    }
}


TaskView.propTypes = {
    app: PropTypes.object.isRequired,
    notes: PropTypes.array.isRequired,
    attachments: PropTypes.array.isRequired,
    fotos: PropTypes.array.isRequired,
    setTitle: PropTypes.func.isRequired
}

const mapStateToProps = state => (
    {
        app: SelectedAppSelector(state),
        notes: SelectedAppNotesSelector(state),
        attachments: SelectedAppAttachmentsSelector(state),
        fotos: SelectedAppPhotosSelector(state),
        settings: state.settings,
        downloadInProgress: state.tasks.downloadInProgress,
        appInfo: state.app,
    }
);

//export default connect(mapStateToProps, { DoSync, ReportProgress })(TaskView);
const withLoader = Loader(TaskView);

const connected = connect(mapStateToProps, {
    setTitle,
    SelectAppointmentByID,
    SetPropertyValue,
    SavePropertyValue,
    SaveData,
    FotoAdd,
    FotoRemove,
    DownloadFileManually,
    SetDownloadInProgress
})(withLoader);

export default withRouter(connected);