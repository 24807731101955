import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import SigPadTransparent from "../extensions/SigPadTransparent";
import Button from "@material-ui/core/Button";
import {
    SetPropertyValue,
    SavePropertyValue,
    SelectAppointmentByID
} from "../../actions/TaskActions";
import { SelectedAppSelector } from "../../selectors/SelectedAppointmentSelector";
import GeneratePdf from './GeneratePdf';
import { setTitle } from "../../actions/AppActions";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
const styles2 = {
    card: {
        margin: "20px",
        border: "1px solid #999"
    }
};

class TaskSignMenck extends Component {
    sigPadKunde = {};

    componentDidMount() {
        this.props.setTitle("unterzeichnen");
        this.props.SelectAppointmentByID(this.props.match.params.id,this.props.match.params.service);
        console.log("mounted");
    }

    handleClearKunde = () => {
        this.sigPadKunde.clear();
    };

    handleClearBerater = () => {
        this.sigPadBerater.clear();
    };

    UpdateKundeSignature = () => {
        if (this.sigPadKunde)
            this.sigPadKunde.fromDataURLTransparent(
                this.props.app.SignatureKunde,
                { width: 500, height: 200 }
            );
    };

    saveSignature = event => {

        const kundeSignature = this.sigPadKunde.toDataURLTransparent('image/png');

        this.props.SetPropertyValue(this.props.app, "SignatureKunde", kundeSignature);
    }

    handleChange = (e, checked) => {
        this.props.SetPropertyValue(
            this.props.app,
            e.target.name,
            checked
        );
    };

    render() {
        let MaterialList = this.props.app.MaterialList || [], ZeitList = this.props.app.ZeitList || [], Besorgungsfahrt = this.props.app.Besorgungsfahrt || [];
        if (!this.props.app.StartDate) return <div />;
        return (
            <div style={{ marginTop: "70px" }}>
                <Paper elevation={1} style={styles2.card}>
                    <Typography variant="h5" component="h3">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        this.props.app.PauschalKleinMaterial || false
                                    }
                                    disabled={true}
                                    name="PauschalKleinMaterial"
                                    value="checkedB"
                                    color="primary"
                                />
                            }
                            label="pauschal Kleinmaterial"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        this.props.app.PauschalMaterial || false
                                    }
                                    disabled={true}
                                    name="PauschalMaterial"
                                    value="checkedB"
                                    color="primary"
                                />
                            }
                            label="pauschal Material"
                        />
                    </Typography>
                </Paper>
                <Paper elevation={1} style={styles2.card}>
                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <td rowSpan="2">Material</td>
                                {/* <td></td> */}
                                <td>Menge</td>
                            </tr>
                        </thead>
                        <tbody>
                            {MaterialList.map((val, idx) => {
                                return (
                                    <React.Fragment key={val.id}>
                                    <TableRow style={{backgroundColor: "lightgray"}}>
                                    <TableCell style={{border: 0}}>
                                    {val.Material}
                                    </TableCell>
                                    {/* <TableCell></TableCell>                                   */}
                                    <TableCell style={{border: 0, textAlign:'right'}}>
                                    {val.Stuck}
                                    </TableCell>
                                    </TableRow>
                                    </React.Fragment>
                                )
                            })}
                        </tbody>
                    </table>
                </Paper>
                <Paper elevation={1} style={styles2.card}>
                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <td>Fahrt zu</td>
                                <td>Service Mitarbeiter</td>
                                <td>Datum</td>
                                <td>Kilometer</td>
                                <td>Dauer</td>
                            </tr>
                        </thead>
                        <tbody>
                            {Besorgungsfahrt.map((val, idx) => {
                                return (
                                    <React.Fragment key={val.id}>
                                    <TableRow
                                        style={{
                                            backgroundColor: "lightgray"
                                        }}
                                    >
                                    <TableCell style={{ border: 0 }}>
                                            {val.FahrtZu}
                                        </TableCell>
                                        <TableCell style={{ border: 0 }}>
                                            {val.ServiceMitarbeiter}
                                        </TableCell>
                                        <TableCell style={{ border: 0, textAlign:'right' }}>
                                        {val.Datum}
                                        </TableCell>
                                        <TableCell style={{ border: 0, textAlign:'right' }}>
                                        {val.Kilometer}
                                        </TableCell>
                                        <TableCell style={{ border: 0, textAlign:'right' }}>
                                        {val.Dauer}
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                                )
                            })}
                        </tbody>
                    </table>
                </Paper>
                <Paper elevation={1} style={styles2.card}>
                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <td>Service Mitarbeiter</td>
                                <td>Datum</td>
                                <td>Arbeitszeit (Stunden)</td>
                            </tr>
                        </thead>
                        <tbody>
                            {ZeitList.map((val, idx) => {
                                return (
                                    <React.Fragment key={val.id}>
                                    <TableRow
                                        style={{
                                            backgroundColor: "lightgray"
                                        }}
                                    >
                                        <TableCell style={{ border: 0 }}>
                                            {val.ServiceMitarbeiter}
                                        </TableCell>
                                        <TableCell style={{ border: 0, textAlign:'right' }}>
                                        {val.Datum}
                                        </TableCell>
                                        <TableCell style={{ border: 0, textAlign:'right' }}>
                                        {val.Arbeitszeit}
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                                )
                            })}
                        </tbody>
                    </table>
                </Paper>
                <Paper elevation={1} style={styles2.card}>
                    <Typography variant="h5" component="h3">
                        <FormControlLabel
                            labelPlacement="bottom"
                            control={
                                <SigPadTransparent
                                    ref={ref => {
                                        this.sigPadKunde = ref;
                                        this.UpdateKundeSignature();
                                    }}
                                    onEnd={this.saveSignature}
                                    backgroundColor="rgba(200,200,200,1)"
                                    penColor="blue"
                                    canvasProps={{
                                        width: 500,
                                        height: 200,
                                        className: "sigCanvas"
                                    }}
                                />
                            }
                            label="Unterschrift Endkunde"
                        />
                    </Typography>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={this.handleClearKunde}
                    >
                        Löschen
                    </Button>
                </Paper>
                <GeneratePdf />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    app: SelectedAppSelector(state),
    company: state.app.company
});

export default withRouter(
    connect(
        mapStateToProps,
        {
            setTitle,
            SetPropertyValue,
            SavePropertyValue,
            SelectAppointmentByID
        }
    )(TaskSignMenck)
);
