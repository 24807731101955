import Dexie from "dexie";
import moment from "moment";

export default class DbDayStatus {
    constructor() {
        this.db = new Dexie("daystatus");

        this.db.version(1).stores({ daystatus: "++id, Day" });
    }

    async SaveData(ID, prop, value) {
        let row = await this.GetEnteredDataRow(ID);
        row = Object.assign(row, {
            [prop]: value,
            Day: new Date().setHours(0, 0, 0, 0)
        });
        let id = await this.db.table("daystatus").put(row);
        row.id = id;
        return row;
    }

    async UpdateRows(ids) {
        await ids.forEach(id =>
            this.db.table("daystatus").update(parseInt(id), { Synced: 1 })
        );
    }

    async GetEnteredDataRow(ID) {
        let dayStatus = await this.db.daystatus.where({ id: ID }).toArray();
        let row = {
            Day: new Date().setHours(0, 0, 0, 0),
            StartDay: "",
            StartPause: "",
            EndPause: "",
            ReturnedToCompany: "",
            FinishDay: "",
            Synced: 0
        };
        if (dayStatus.length > 0) {
            row = dayStatus[0];
        }
        return row;
    }

    async addEmptyRow(day) {
        let dayStatus = await this.db.daystatus.where({ Day: day }).toArray();
        let row = {
            Day: new Date().setHours(0, 0, 0, 0),
            StartDay: "",
            StartPause: "",
            EndPause: "",
            ReturnedToCompany: "",
            FinishDay: "",
            Synced: 0
        };
        if (dayStatus.length === 0) {
            await this.db.table("daystatus").put(row);
        }
    }

    async getAllData(initial) {
        await this.addEmptyRow(new Date().setHours(0, 0, 0, 0));
        let dayStatusData = await this.db.table("daystatus").toArray();

        if (dayStatusData.length > 0) return dayStatusData;
        return initial;
    }

    async getAllDaysData() {
        let data = await this.db.table("daystatus").toArray();

        let readyData = data.filter(c => c.FinishDay !== "" && c.Synced === 0);

        readyData.forEach(row => this.db.daystatus.put(row));

        return readyData;
    }

    uploadAllDataToService = async settings => {
        let ed = await this.getAllDaysData();

        let data = ed.map(row => {
            return {
                ID: row.id,
                UserName: settings.Berater,
                Day: moment(row.Day).format(),
                StartDay: row.StartDay,
                StartPause: row.StartPause,
                EndPause: row.EndPause,
                ReturnedToCompany: row.ReturnedToCompany,
                FinishDay: row.FinishDay
            };
        });

        let url = settings.URL;
        if (!url.endsWith("/")) url = url + "/";

        return fetch(url + "RestServiceImpl.svc/updateDayStatusData", {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json; charset=utf-8"
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
            .then(response => {
                return response.json();
            })
            .then(res => res)
            .catch(function(err) {
                return err;
            });
    };
}
