import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Webcam from "react-webcam";

export default class TakePhoto extends Component {

   
    setRef = webcam => {
        this.webcam = webcam;
      }

    takeScreenshootHandle=()=> {
        const imageSrc = this.webcam.getScreenshot();
        this.props.handleTakeScreenshot(imageSrc);
    }

    render() {
        const videoConstraints = {           
            //facingMode: { exact: "environment" }
            facingMode: "environment"
          };

        return (
        <Dialog
        fullScreen
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        
        <DialogTitle id="alert-dialog-title">Take Photo</DialogTitle>
        <DialogContent>
        <Webcam style={{width:'100%', height:'100%'}} ref={this.setRef} videoConstraints={videoConstraints} />    

          
        </DialogContent>
        <DialogActions>
            <Button onClick={this.takeScreenshootHandle} color="primary">
                Foto machen
            </Button>
            <Button onClick={this.props.handleClose} color="primary">
                Abbrechen
            </Button>           
        </DialogActions>
    </Dialog>)
      }
}
