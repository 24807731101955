import { DO_BACKUP, DO_BACKUP_IMPORT, REPORT_BACKUP_PROGRESS } from "./types";
import dbTasks from "../db/DbTasks";

export const DoBackup = (settings, ReportProgressHandler) => async dispatch => {
  const db = new dbTasks();
  await db.doBackup(settings, ReportProgressHandler);
  dispatch({
    type: DO_BACKUP
  });
};

export const DoBackupImport = ( settings, ReportProgressHandler) => async dispatch => {
  const db = new dbTasks();
  await db.doBackupImport(settings, ReportProgressHandler);

  dispatch({
    type: DO_BACKUP_IMPORT
  });
};

export const ReportBackupProgress = val => dispatch => {
  dispatch({
    type: REPORT_BACKUP_PROGRESS,
    payload: val
  });
};
