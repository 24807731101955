/**
 * Summary. (Merge changes from enteredValues to original appointment)
 *
 * @access     public
 *
 * @param {Appointment}   app
 *      Appointment from state.
 * @param {Object|Array} enteredData
 *      Single row of enteredData or Array of all enteredData rows. If Array is provided, the correct row will be found over IDService
 *
 * @return {Appointment}
 *      Original appointment modified with data from enteredData object.
 */

const mergeChangesWithAppointment = (app, enteredData) => {
    if (!app) return {}; //No appointment provided, return empty object
    if (!enteredData) return app; //No modifications provided, return original appointment

    let ed = enteredData;

    if (Array.isArray(ed))
        ed = enteredData.find(c => (app.UniqueID==null && c.UniqueID==null && c.IDService === app.IDService) || (app.UniqueID!=null && app.UniqueID===c.UniqueID)); //If provided enteredData is array, we look for needed enteredData for the sam Service
    if (!ed) return app; //No modification found in array for this IDService, return original appointment.

    let appRet = Object.assign({}, app);

    let duration = ed.Duration ? ed.Duration : app.Duration;
    //Modify appointment with data from enteredData
    appRet.StartTime = ed.StartTime ? ed.StartTime : app.StartDate;
    appRet.Started = ed.Started ? 1 : 0;
    appRet.Finished = ed.Finished ? 1 : 0;
    appRet.ErledigteArbeiten = ed.ErledigteArbeiten || "";
    appRet.SignatureBerater = ed.SignatureBerater;
    appRet.SignatureKunde = ed.SignatureKunde;
    appRet.Duration = duration;
    appRet.AdditionalServiceNeeded = ed.AdditionalServiceNeeded ? true : false;
    appRet.pdfFieldValues = ed.pdfFieldValues;
    appRet.pdf = ed.pdf;
    //appRet.id = appRet.UniqueID;
    //Menck
    appRet.PauschalMaterial = ed.PauschalMaterial;
    appRet.PauschalKleinMaterial = ed.PauschalKleinMaterial;
    appRet.MaterialList = ed.MaterialList;
    appRet.ZeitList = ed.ZeitList;
    appRet.Besorgungsfahrt = ed.Besorgungsfahrt;

    //#8178 task
    appRet.ekPlz = ed.ekPlz || app.ekPlz;
    appRet.ekStrasse = ed.ekStrasse || app.ekStrasse;
    appRet.ekOrt = ed.ekOrt || app.ekOrt;
    appRet.ekTelefon1 = ed.ekTelefon1 || app.ekTelefon1;
    appRet.ekTelefon2 = ed.ekTelefon2 || app.ekTelefon2;

    if(ed.ServiceMangelbeschreibung) appRet.ServiceMangelbeschreibung = ed.ServiceMangelbeschreibung;
    if(ed.StartDate !== null && ed.StartDate !== undefined){
        appRet.StartDate = ed.StartDate;
        appRet.EndDate = ed.EndDate;
    }
    //Menck
    //Loefel
    appRet.PrintNotiz = ed.PrintNotiz?1:0;
    appRet.Eingang = app.Eingang;

    return appRet;
};

export default mergeChangesWithAppointment;
