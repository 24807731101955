import Dexie from "dexie";
import moment from "moment";
import firebase from "firebase/app";
import notification from "../helpers/toast_notification";

import {
    PDFDocumentFactory,
    PDFDocumentWriter,
    drawLinesOfText,
    drawImage,
} from "pdf-lib";

import DateHelper from "../helpers/date-helper";
import { PAST_DAYS, FUTURE_DAYS } from "../settings";
import {
    COMPANY_BROEMSE,
    COMPANY_EVERS,
    COMPANY_GROWE,
    COMPANY_OST,
    COMPANY_MENCK,
    COMPANY_LOEFFEL
} from "../helpers/constants";
import pdfSettingsData from "../helpers/pdf-settings";
import DbDayStatus from "../db/DbDayStatus";
import { COMPANY } from "../settings";

export default class DBTasks {
    constructor() {
        this.db = new Dexie("appointments"); // new PouchDB(name,{revs_limit: 1, auto_compaction: true});



        this.db.version(10).stores({ appointments: 'id', notes: 'id, ServiceID', attachments: 'id, ServiceID', fotos: '++id, ServiceID', entereddata: '++id, ServiceID, Fertig, FileName, Started' });


        this.db.version(11).stores({ appointments: 'id, IDService', notes: 'id, ServiceID', attachments: 'id, ServiceID', fotos: '++id, ServiceID', entereddata: '++id, ServiceID, Fertig, FileName, Started' })
            .upgrade(tx => {
                return tx.entereddata.toCollection().modify(row => {
                    row.IDService = parseInt(row.IDService);
                });
            });

        this.db.version(12).stores({ appointments: 'id, IDService, StartDate', notes: 'id, ServiceID', attachments: 'id, ServiceID', fotos: '++id, ServiceID', entereddata: '++id, ServiceID, Fertig, FileName, Started' });

        this.db.version(13).stores({ appointments: 'id, IDService, StartDate', notes: 'id, ServiceID', attachments: 'id, ServiceID', fotos: '++id, ServiceID', entereddata: '++id, ServiceID, Finished, FileName, Started' })
            .upgrade(tx => {
                return tx.entereddata.toCollection().modify(row => {
                    row.Finished = row.Fertig;
                });
            });

        this.db.version(14).stores({ appointments: 'id, IDService, StartDate', notes: 'id, ServiceID', attachments: 'id, ServiceID', fotos: '++id, ServiceID', entereddata: '++id, ServiceID, Finished, FileName, Started, UniqueID' })
            .upgrade(tx => {
                return tx.entereddata.toCollection().modify(row => {
                    row.UniqueID = 0;
                });
            });
        this.db.version(15).stores({ appointments: 'id, IDService, StartDate', notes: 'id, ServiceID', attachments: 'id, ServiceID', fotos: '++id, ServiceID', entereddata: '++id, ServiceID, Finished, FileName, Started, UniqueID' })
            .upgrade(tx => {
                tx.appointments.clear();
                tx.notes.clear();
                tx.attachments.clear();
                tx.fotos.clear();
                return tx.entereddata.clear();
            });
        this.db.version(16).stores({ appointments: 'id, IDService, StartDate', notes: 'id, ServiceID', attachments: 'id, ServiceID, [FileName+ServiceID]', fotos: '++id, ServiceID', entereddata: '++id, ServiceID, Finished, FileName, Started, UniqueID' });
        this.db.version(17).stores({ appointments: 'id, IDService, StartDate', notes: 'id, IDService', attachments: 'id, IDService, [FileName+IDService]', fotos: '++id, IDService', entereddata: '++id, IDService, Finished, FileName, Started, UniqueID' });
        this.db.version(18).stores({ appointments: 'id, IDService, StartDate', notes: 'id, IDService', attachments: 'id, IDService, [FileName+IDService]', fotos: '++id, IDService', entereddata: '++id, IDService, Finished, FileName, Started, UniqueID' })
        this.db.version(19).stores({ appointments: 'id, [UniqueID+IDService], IDService, StartDate', notes: 'id, IDService', attachments: 'id, IDService, [FileName+IDService]', fotos: '++id, IDService', entereddata: '++id, IDService, Finished, FileName, Started, UniqueID,[UniqueID+IDService]' })
            .upgrade(tx => {
                tx.appointments.clear();
                tx.notes.clear();
                tx.attachments.clear();
                tx.fotos.clear();
                return tx.entereddata.clear();
            });





    }

    ne = (val) => {
        if (!val) return '';
        return val;
    };

    //NOT NEEDED ANYMORE. If we do not get multiple rows in enteredData for single Service, we can remove this
    /*fixMultipleEnteredData = async () => {
        let allEd = await this.getAllEnteredData();

        let toRemove=[];

        for (let i=0;i<allEd.length;i++){
            let curr = allEd[i];

            let firstIndex = allEd.findIndex(c=>c.IDService===curr.IDService);
            if (firstIndex!==i) {
                let firstObj = allEd[firstIndex];
                if (!firstObj.AdditionalServiceNeeded && curr.AdditionalServiceNeeded) firstObj.AdditionalServiceNeeded = curr.AdditionalServiceNeeded;
                if (this.ne(curr.ErledigteArbeiten)!=='') firstObj.ErledigteArbeiten=(curr.ErledigteArbeiten + ' ' + this.ne(firstObj.ErledigteArbeiten));
                if (this.ne(curr.SignatureBerater)!=='') firstObj.SignatureBerater=curr.SignatureBerater;
                if (this.ne(curr.SignatureKunde)!=='') firstObj.SignatureKunde=curr.SignatureKunde;
                if (this.ne(curr.StartTime)!=='') firstObj.StartTime=curr.StartTime;
                if (this.ne(curr.pdf)!=='') firstObj.pdf=curr.pdf;
                if (this.ne(curr.Duration)!=='') firstObj.Duration=curr.Duration;
                if (curr.Fertig===1) firstObj.Fertig=1;
                curr.deleted=1;
                toRemove.push(curr);
            }

        }


        let toUpdate=allEd.filter(c=>{return toRemove.findIndex(d=>d.id===c.id)===-1});
        console.log(toRemove.length);
        console.log(toUpdate.length);
        console.log(allEd.length -toRemove.length - toUpdate.length);
        await this.db.table('entereddata').bulkDelete(toRemove.map((row) => row.id));
        await this.db.table('entereddata').bulkPut(toUpdate);

    }*/

    doBackupImport = async (settings, reportStatus) => {
        if (reportStatus) reportStatus(0, "Downloading");
        let ref = firebase
            .app()
            .database()
            .ref(`/${settings.Company}/` + settings.Berater);

        await this.db.open();
        //let idb_db = this.db.backendDB();

        // ref.on("value", snap => {
        //     let json = JSON.stringify(snap.val());
        //     if (reportStatus) reportStatus(50, "Importing");

        //     if (reportStatus) reportStatus(100, "Done");
        //     IDBExportImport.clearDatabase(idb_db, function (err) {
        //         if (!err)
        //             IDBExportImport.importFromJsonString(idb_db, json, function (
        //                 err
        //             ) {
        //                 if (!err && reportStatus) reportStatus(100, "Done");
        //             }); //importFromJsonString
        //     }); //clear database
        // }); //ref.on

        if (reportStatus) reportStatus(30, "Downloading");
        //Import backup podataka sa firebase-a.
        //IDBExportImport importFromJsonString ne radi kako treba.
        ref.on("value", async snap => {
            let backupName = Object.keys(snap.val());
            let data = snap.val()[backupName[backupName.length - 1]];
            
            //let jsonString = JSON.stringify(snap.val());
            console.log(data);

            await this.db.table("appointments").bulkAdd(data.appointments);
            await this.db.table("entereddata").bulkAdd(data.entereddata);
            await this.db.table("fotos").bulkAdd(data.fotos);
            await this.db.table("notes").bulkAdd(data.notes);
        });
        if (reportStatus) reportStatus(100, "Finished");
    };

    doBackup = async (settings, reportStatus) => {

        

        const backupName = moment(new Date()).format('MM_DD_YYYY_HH_mm_ss');

        if (reportStatus) reportStatus(0, 'Retrieving data');
        let ref = firebase.app().database().ref(`/${settings.Company}/` + settings.Berater + '/' + backupName);

        if (reportStatus) reportStatus(1, 'Getting Appointments');
        var appointments = await this.getAllAppointments();
        // if (reportStatus) reportStatus(2, 'Getting Attachments');
        // var attachments = await this.getAllAttachments();
        if (reportStatus) reportStatus(3, 'Getting Notes');
        var notes = await this.getAllNotes();
        if (reportStatus) reportStatus(4, 'Getting Entered Data');
        var enteredData = await this.getAllEnteredData();
        if (reportStatus) reportStatus(5, 'Getting Fotos');
        var fotos = await this.getAllFotos();

       
        if (reportStatus) reportStatus(10, 'Creating DB');
        var shell = { appointments: [], attachments: [], notes: notes, entereddata: [], fotos: [] };

        await ref.set(shell);
        if (reportStatus) reportStatus(20, 'Upploading attachments');

        let per1 = (10 / (appointments.length>0?appointments.length:1)).toPrecision(2);

        for (let i = 0; i < appointments.length; i++) {
            let ref = firebase.app().database().ref(`/${settings.Company}/` + settings.Berater + '/' + backupName + "/appointments/" + i);
           await ref.set(appointments[i]);
           if (reportStatus) reportStatus((21 + (per1*i)).toFixed(2), 'Upploading appointments');
            console.log('app');
        }

        per1 = (10 / (enteredData.length>0?enteredData.length:1)).toPrecision(2);

      //  this.logToServ(settings,enteredData);

        for (let i = 0; i < enteredData.length; i++) {
            let ref = firebase.app().database().ref(`/${settings.Company}/` + settings.Berater + '/' + backupName + "/entereddata/" + i);
            
           await ref.set(enteredData[i]);
           if (reportStatus) reportStatus((31 + (per1*i)).toFixed(2), 'Upploading enteredData');
            console.log('app');
        }

        // per1 = (30 / (attachments.length>0?attachments.length:1)).toPrecision(2);
        // for (let i = 0; i < attachments.length; i++) {
        //     let ref = firebase.app().database().ref(`/${settings.Company}/` + settings.Berater + '/' + backupName + "/attachments/" + i);
        //    await ref.set(attachments[i]);
        //    if (reportStatus) reportStatus((41 + (per1*i)).toFixed(2), 'Upploading attachments');
        //     console.log('app');
        // }

        if (reportStatus) reportStatus(70, 'Upploading fotos');
            //perAtt = 10/fotos.length;
            per1 = (20 / (fotos.length>0?fotos.length:1)).toPrecision(2);

            for (let i = 0; i < fotos.length; i++) {
                let ref = firebase.app().database().ref(`/${settings.Company}/` + settings.Berater + '/' + backupName + "/fotos/" + i);
                //  if (reportStatus) reportStatus(90 + parseInt(((i-1)*perAtt).toFixed(0)), 'Upploading fotos');
               await  ref.set(fotos[i]);
               if (reportStatus) reportStatus((71 + (per1*i)).toFixed(2), 'Upploading attachments');
                console.log('foto');
            }
            if (reportStatus) reportStatus(100, 'Done');

     

    }

    getAllData = async () => {
        // await this.fixMultipleEnteredData(); //NOT NEEDED ANYMORE. If we do not get multiple rows in enteredData for single Service, we can remove this

        let dbAppointmentsP = this.getAllAppointments();
        let dbNotesP = this.getAllNotes();
        let dbAttachmentsP = this.getAllAttachments();
        let dbEnteredDataP = this.getAllEnteredData();
        let dbFotosP = this.getAllFotos();

        let dh = new DateHelper();
        let days = [];
        for (let i = 0; i <= PAST_DAYS + FUTURE_DAYS; i++) {
            var dt = dh.getDateByIndex(i);
            days.push({ index: i, date: dt });
        }

        let res = [
            await dbAppointmentsP,
            await dbNotesP,
            await dbAttachmentsP,
            await dbEnteredDataP,
            await dbFotosP
        ];

        return {
            appointments: res[0],
            notes: res[1],
            attachments: res[2],
            enteredData: res[3],
            fotos: res[4],
            days: days
        };
    };

    getAllDataFromService = async settings => {
        let url = settings.URL;
        if (!url.endsWith("/")) url = url + "/";

        let objToSend = { imei: settings.Berater };
        let listOfAttachments = await this.db.table("attachments").toArray();
        listOfAttachments = listOfAttachments.filter(e => e.Attachment !== null);
        objToSend["list"] = await listOfAttachments.map(({ ServiceID, FileName, Extension }) => ({ ServiceId: ServiceID, FileName: FileName + Extension }));

        //Max veličina fajlova koji će se automatski skinuti
        objToSend["maxFileSizeForDownload"] = settings.FileSize === "" ? 0 : settings.FileSize;

        // if(settings.Company === "GROWE"){
        //     const data = await fetch(url + "RestServiceImpl.svc/test/" + settings.Berater);
        //     return JSON.parse(await data.json());    
        // }

        // console.log(ret);
        //  ret;

        let data = await fetch(url + "RestServiceImpl.svc/output/", {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json; charset=utf-8"
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
            body: JSON.stringify(objToSend) // body data type must match "Content-Type" header
        })
        // .then(res => res.json())
        // .then(res => res)
        // .catch(e => {
        //     console.log(e);
        // });
        // return data;
        return JSON.parse(await data.json());
    };

    sendEmail = async (settings, objToSend) => {
        let url = settings.URL;
        if (!url.endsWith("/")) url = url + "/";

        // let testno = "http://localhost:2556/RestServiceImpl.svc/sendmail";

        let data = await fetch(url + "RestServiceImpl.svc/sendmail", {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json; charset=utf-8"
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
            body: JSON.stringify(objToSend) // body data type must match "Content-Type" header
        });

        return await data.json();

    };

    logToServ = async (settings, objToSend) => {
        let url = settings.URL;
        if (!url.endsWith("/")) url = url + "/";

        // let testno = "http://localhost:2556/RestServiceImpl.svc/sendmail";

        let data = await fetch(url + "RestServiceImpl.svc/UploadEneteredData", {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json; charset=utf-8"
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
            body: JSON.stringify(JSON.stringify(objToSend)) // body data type must match "Content-Type" header
        });

        return await data.json();

    };

    uploadAllDataToService = async settings => {
        let ed = await this.getAllEnteredData();
        //Promijeniti filter
        if (settings.Company === COMPANY_MENCK || settings.Company === COMPANY_LOEFFEL) {
            ed = ed.filter(c => c.Finished === 1 || c.IsMobileEdited || (c.StartDate !== null && c.UniqueID === null));
        } else {
            ed = ed.filter(c => c.Finished === 1);
        }
        let fotos = await this.getAllFotos();

        let data = ed.map(row => {
            const duration = parseInt(row.Duration ? row.Duration : "0");        
            let images = fotos.filter(c => c.UniqueID === row.UniqueID && c.data);
            let fileName = row.IDService + "_" + row.UniqueID + "_";

            let imageData = images.map(img => {
                return {
                    data: img.data,
                    file: fileName + img.id + ".jpg"
                };
            });

            return {
                IDService: row.IDService,
                Erledigt: row.Finished,
                UniqueID: row.UniqueID,
                UserName: settings.Berater,
                WorkDone: row.ErledigteArbeiten,
                StartDate: row.StartTime,
                Duration: duration,
                EndDate: moment(row.StartTime).add(duration, 'minutes').format(),
                PDF: row.pdf, // kroz row.pdf se šalje i Pdf ServiceProtokol za GS
                PdfName: settings.company === COMPANY_GROWE ? "ServiceProtokol" : `Protokoll vom ${moment().format("DD.MM.YYYY")}`,
                NoteName: settings.company === COMPANY_GROWE ? "ServiceProtokol" : `Protokoll vom ${moment().format("DD.MM.YYYY")}`,
                PdfPruf: row.PdfPruf,
                ImageDatas: imageData,
                AdditionalServiceNeeded: (row.AdditionalServiceNeeded ? true : false),
                IsMobileEdited: row.IsMobileEdited,
                FileSize: settings.FileSize || 2,
                EkTelefon1: row.ekTelefon1,
                EkTelefon2: row.ekTelefon2,
                EkOrt: row.ekOrt,
                EkPlz: row.ekPlz,
                EkStrasse: row.ekStrasse
            };
        });
        try {
            console.log(data);
        } catch (e) {
            console.log(e);
        }
        let url = settings.URL;
        if (!url.endsWith("/")) url = url + "/";

        // console.log("Data for upload :", data);
        //data = data.filter(e => e.IDService === 26230);

        return fetch(url + "RestServiceImpl.svc/updateAppointmentData", {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json; charset=utf-8"
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
            .then(response => {
                if (settings.Company === COMPANY_MENCK || settings.Company === COMPANY_LOEFFEL) {
                    //Brisanje iz entereddata gdje je UniqueID === null, nakon što se pošalju na server.
                    let enteredDataToRemove = ed.filter(e => e.UniqueID === null);
                    enteredDataToRemove.forEach(e => {
                        this.db.table('entereddata').delete(e.id);
                    })
                    return response.json()
                } else {
                    return response;
                }
            })
            .then(res => res)
            .catch(function (err) {
                return err;
            });
    };

    doSync = async (settings, reportStatus) => {
        if (reportStatus) reportStatus(0, "Sending data");
        let uploadStatus = await this.uploadAllDataToService(settings);

        //Loeffel/Menck api nema property status pa se provjerava statusText
        //Trenutni fix, potrebno izmjeniti backend.
        if((settings.Company === COMPANY_LOEFFEL || settings.Company === COMPANY_MENCK) && uploadStatus.statusText !== "OK"){
            notification(
                "error",
                "Synchronisation nicht erfolgreich! Bitte versuchen Sie zu einem späterem Zeitpunkt!"
            );
            reportStatus(100, "Failed");
            //Kada je sinhronizacija neuspješna, odradi se Backup na firebase.
            //this.doBackup(settings, reportStatus);
            return;
        }
        if (settings.Company !== COMPANY_LOEFFEL && settings.Company !== COMPANY_MENCK && uploadStatus.status!==200) {
            notification(
                "error",
                "Synchronisation nicht erfolgreich! Bitte versuchen Sie zu einem späterem Zeitpunkt!"
            );
            reportStatus(100, "Failed");
            //Kada je sinhronizacija neuspješna, odradi se Backup na firebase.
            //Limit na firebase je 1Gb mjesečno. Nakon toga se baza zaključa.
            //this.doBackup(settings, reportStatus);
            return;
        }
        if (reportStatus) reportStatus(30, "Retrieving data");

        let ed = await this.getAllEnteredData();

        if (settings.Company === COMPANY_MENCK || settings.Company === COMPANY_LOEFFEL) {
            let newAppointments = uploadStatus.UpdatedData;

            newAppointments.forEach(async newItem => {
                ed.forEach(async (existingItem, i) => {

                    if (!existingItem.UniqueID) {
                        if (newItem.IDService === existingItem.IDService) {
                            let services = await this.db.table("entereddata").where({ IDService: newItem.IDService }).toArray();
                            var appToSave = services.find(c => c.UniqueID == null)
                            if (appToSave) {
                                await this.db.table("entereddata").where({ id: appToSave.id }).modify({ UniqueID: newItem.UniqueID });
                            }
                        }
                    }
                });
            });
        }

        ed = ed.filter(c => c.Finished === 1 || c.UniqueID === null);
        await this.db.table("entereddata").bulkDelete(ed.map(row => row.id));

        if (reportStatus) reportStatus(35, "Retrieving data");

        // reportStatus(100, "Done");
        //     notification("success", "Synchronisation war erfolgreich!");
        // return;
        let serviceDataP = this.getAllDataFromService(settings);
        let dbAppointmentsP = this.getAllAppointments();
        let dbNotesP = this.getAllNotes();
        let dbAttachmentsP = this.getAllAttachments();

        let res = [
            await serviceDataP,
            await dbAppointmentsP,
            await dbNotesP,
            await dbAttachmentsP
        ];
        let serviceData = res[0];
        let appointments = serviceData.appointments;
        let atatchments = serviceData.attachments;
        let notes = serviceData.notes;
        let dbAppointments = res[1];
        let dbNotes = res[2];
        let dbAttachments = res[3];

        // if (settings.Company === COMPANY_MENCK) {
        //     let newAppointments = uploadStatus.UpdatedData;

        //     newAppointments.forEach(newItem => {
        //         dbAppointments.forEach((existingItem, i) => {
        //             if (existingItem.UniqueID === null && newItem.IDService === existingItem.IDService) { //ok
        //                 //dbAppointments[i].id = newItem.UniqueID;
        //                 dbAppointments[i].UniqueID = newItem.UniqueID;
        //             }
        //         });
        //     });
        // }

        if (reportStatus) reportStatus(70, "Updating appointments");
        await this.updateDBAppointments(dbAppointments, appointments);
        if (reportStatus) reportStatus(80, "Updating notes");
        await this.updateDBNotes(dbNotes, notes);

        if (reportStatus) reportStatus(90, "Updating attachemnts");
        await this.updateDBAttachments(dbAttachments, atatchments);

        if (settings.Company === COMPANY_OST) {
            if (reportStatus) reportStatus(95, "Updating day status");
            let db = new DbDayStatus();
            let ids = await db.uploadAllDataToService(settings);
            await this.updateDBDayStatuses(ids);
        }

        if (reportStatus) {
            reportStatus(100, "Done");
            notification("success", "Synchronisation war erfolgreich!");
        }
    };

    DownloadFileManually = async (settings, IDService, FileName, FileID) => {
        let url = settings.URL;
        if (!url.endsWith("/")) url = url + "/";

        // let objToSend = {
        //     IDService,
        //     FileID
        // }

        let data = await fetch(url + "RestServiceImpl.svc/downloadFileManually/", {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json; charset=utf-8"
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
            body: JSON.stringify({
                IDService,
                FileName
            }) // body data type must match "Content-Type" header
        })
            .then(response => {
                return response.json();

            })
            .then(res => JSON.parse(res))
            .catch(function (err) {
                return err;
            });

        if (!data.success) return data.success;

        await this.db.table("attachments").update(FileID, { Attachment: data.attachment, SignaturePosition: data.signaturePosition });
        return await this.db.table("attachments").get(FileID, function (attRow) {
            return attRow;
        });
        //console.log("fromDb :", fromDb);

    }

    updateDBDayStatuses = async ids => {
        let db = new DbDayStatus();
        await db.UpdateRows(ids);
    };

    updateDBAppointments = async (dbAppointments, appointments) => {
        //appointments that exist in remote, but not localy
        var toAdd = appointments.filter(app => {
            return !dbAppointments.some(value => {
                return (app.UniqueID != null && value.UniqueID === app.UniqueID) || (app.UniqueID == null && value.UniqueID == null && app.IDService === value.IDService); //unique
            });
        });

        //appointments that exist in localy, but not remotely
        var toRemove = dbAppointments.filter(app => {
            return !appointments.some(value => {
                return (value.UniqueID != null && value.UniqueID === app.UniqueID) || (value.UniqueID == null && app.UniqueID == null && app.IDService === value.IDService); //UniqueID
            });
        });
        toRemove.forEach(app => {
            app._deleted = true;
        });

        //appointments that exist both localy and on remote
        var toUpdate = appointments.filter(app => {
            return dbAppointments.some(value => {
                return (value.UniqueID != null && value.UniqueID === app.UniqueID) || (value.UniqueID == null && app.UniqueID == null && app.IDService === value.IDService); //UniqueID
            });
        });

        //let enteredData = await this.getAllEnteredData();
        // var toRemoveFromEnteredData = appointments.filter(app => {
        //     return enteredData.some(value => {
        //         return value.UniqueID !== app.UniqueID
        //     })
        // })
        //console.log(toRemoveFromEnteredData);
        await this.db.table("appointments").bulkAdd(toAdd);
        //if (toAdd.length > 0) console.log(`Added ${toAdd.length} appointments!`);

        await this.db.table("appointments").bulkPut(toUpdate);
        //if (toUpdate.length > 0) console.log(`Updated ${toUpdate.length} appointments!`);

        await this.db
            .table("appointments")
            .bulkDelete(toRemove.map(row => row.id));
        //if (toRemove.length > 0) console.log(`Removed ${toRemove.length} appointments!`);

        //console.log(toUpdate);
        // if(COMPANY === "MENCK"){
        //     // await this.db
        //     // .table("entereddata")
        //     // .bulkDelete(toRemove.map(row => row.id));
        //     let enteredData = await this.getAllEnteredData();
        //     enteredData.forEach(val => {
        //         console.log(val);
        //         let item = toUpdate.filter(e => e.UniqueID === val.UniqueID);
        //         if(item[0]){
        //             this.db.table("entereddata").update(val.id, {
        //                 StartDate: item[0].StartDate

        //             });
        //         }                
        //     })
        // }
    };

    updateDBNotes = async (dbNotes, notes) => {
        //appointments that exist in remote, but not localy
        var toAdd = notes.filter(app => {
            return !dbNotes.some(value => {
                return value.id === app.id;
            });
        });

        //appointments that exist in localy, but not remotely
        var toRemove = dbNotes.filter(app => {
            return !notes.some(value => {
                return value.id === app.id;
            });
        });
        toRemove.forEach(app => {
            app._deleted = true;
        });

        //appointments that exist both localy and on remote
        var toUpdate = notes.filter(app => {
            return dbNotes.some(value => {
                return value.id === app.id;
            });
        });

        await this.db.table("notes").bulkAdd(toAdd);
        console.log(`Added ${toAdd.length} notes`);
        await this.db.table("notes").bulkPut(toUpdate);
        console.log(`Updated ${toUpdate.length} notes`);
        await this.db.table("notes").bulkDelete(toRemove.map(row => row.id));
        console.log(`Removed ${toRemove.length} notes`);
    };

    updateDBAttachments = async (dbAttachments, attachments) => {
        //appointments that exist in remote, but not localy
        var toAdd = attachments.filter(app => {
            return !dbAttachments.some(value => {
                return value.id === app.id;
            });
        });

        //appointments that exist in localy, but not remotely
        var toRemove = dbAttachments.filter(app => {
            return !attachments.some(value => {
                return value.id === app.id;
            });
        });
        toRemove.forEach(app => {
            app._deleted = true;
        });

        var toUpdate = attachments.filter(app => {
            return dbAttachments.some(value => {
                return value.id === app.id;
            });
        });

        await this.db
            .table("attachments")
            .bulkDelete(toRemove.map(row => row.id));
        await this.db.table("attachments").bulkPut(toUpdate);
        //console.log(`Updated ${toUpdate.length} attachments`);
        await this.db.table("attachments").bulkAdd(toAdd);
        //console.log(`Removed ${toRemove.length} attachments`);
        //console.log(`Added ${toAdd.length} attachments`);
    };

    // AddPdfToAttachments = async (IDService, prop, value) => {
    //     //let dbAttachments = this.getAllAttachments();
    //     //let res = [await dbAttachments];
    //     let row = {
    //         IDService: IDService,
    //         FileName: IDService + '_' + prop,
    //         Attachment: value
    //     }

    //     let id = await this.db.table('attachments').put(row);
    //     row.id = id;
    //     return row;

    //     //let currentAttachments = res[0].where({ 'IDService': parseInt(IDService) }).toArray();
    //     //console.log("Dodavanje attachmenta", currentAttachments);
    // }

    async getAllAppointments() {
        return await this.db
            .table("appointments")
            //.orderBy("StartDate")
            .toArray();
    }

    async getAllEnteredData() {
        let data = await this.db.table("entereddata").toArray();

        ///EDIN - Izgleda da ovo vise ne terba, Upisivalo je UniqueID na red koje je prije unesen a nije imao uniqueid
        /* for (let i = 0; i < data.length; i++) {
             let row = data[i];
             //if (row.id && !row.Finished) row.Finished = row.Fertig;
             if (row.id && (!row.UniqueID || row.UniqueID === 0)) { //UniqueId is null or 0
                 const appointment = await this.db.appointments
                     .where({ IDService: parseInt(row.IDService) })
                     .toArray();
                 if (appointment && appointment.length > 0) {
                     if (COMPANY === "MENCK") row.UniqueID = null;
                     else row.UniqueID = appointment[0].id;
                 }
                 this.db.entereddata.put(row);
             }
         }*/
        return data;
    }

    async getAllFotos() {
        let ret = [];
        let count = await this.db.table("fotos").count();

        for(var i =0;i<count;i++) {
            let row = await this.db.table("fotos").offset(i).limit(1).first();
            ret.push(row)
        }


        return ret;
    }

    filterData = (apps, dt) => {
        return apps.filter(app =>
            this.compareMomentDate(moment(app.StartDate).startOf("day"), dt)
        );
    };

    compareMomentDate(d1, d2) {
        return d1.diff(d2, "days") === 0;
    }

    async getAppointmentPhotos(IDService) {
        return await this.db.fotos
            .where({ IDService: parseInt(IDService) })
            .toArray();
    }

    /*async getAppointmentByID(id) {
        const appointment = await this.db.appointments.get(parseInt(id));
        const IDService = appointment.IDService;
        const notes = await this.db.notes
            .where({ IDService: parseInt(IDService) })
            .toArray();
        const attachments = await this.db.attachments
            .where({ IDService: parseInt(IDService) })
            .toArray();
        const enteredData = await this.db.entereddata
            .where({ IDService: parseInt(IDService) })
            .toArray();
        const fotos = await this.db.fotos
            .where({ IDService: parseInt(IDService) })
            .toArray();


        let enteredValues = {
            ErledigteArbeiten: "",
            NeededTime: 0,
            StartTime: null,
            SignatureBerater: null,
            SignatureKunde: null,
            AdditionalServiceNeeded: false,
            Finished: 0,
            Started: 0
        };
        if (enteredData.length > 0) {
            var row = enteredData[0];
            enteredValues.ErledigteArbeiten = row.ErledigteArbeiten;
            enteredValues.Duration = row.Duration;
            enteredValues.StartTime = row.StartTime;
            enteredValues.SignatureBerater = row.SignatureBerater;
            enteredValues.SignatureKunde = row.SignatureKunde;
            enteredValues.AdditionalServiceNeeded = row.AdditionalServiceNeeded;
            enteredValues.Finished = row.Finished;
            enteredValues.Started = row.Started;
            enteredValues.pdf = row.pdf;
        }
        if (enteredValues.NeededTime === 0)
            enteredValues.NeededTime = appointment.Duration;
        if (!enteredValues.AdditionalServiceNeeded)
            enteredValues.AdditionalServiceNeeded = false;
        if (!enteredValues.Finished) enteredValues.Finished = 0;
        if (!enteredValues.Started) enteredValues.Started = 0;
        if (!enteredValues.StartTime)
            enteredValues.StartTime = appointment.StartDate;

        return { appointment, notes, attachments, fotos, enteredValues };
    }*/

    async getAllNotes() {
        return this.db.table("notes").toArray();
    }
    async getAllAttachments() {
        let atts = await this.db.table("attachments").toArray();
        /* atts.map(att=> {
             att.Attachment=null;
             return att;
         })*/
        return atts;
    }

    async UpdateEnteredRowData(app, prop, value) {
        let row = await this.GetEnteredDataRow(app);

        row[prop] = value;
        let id = await this.db.table("entereddata").put(row);
        row.id = id;
        return row;
    }



    async UpdateEnteredRowTableData(app, prop, rowId, tableName, value) {
        let row = await this.GetEnteredDataRow(app);
        if (typeof (value) == "object") {
            let arr = row[tableName];
            if (arr === undefined) arr = [];
            arr.push(value);
            row[tableName] = arr;
        } else {
            row[tableName].map(e => {
                if (e.id === rowId) {
                    return e[prop] = value;
                }
                return e;
            })
        }
        let id = await this.db.table('entereddata').put(row);
        row.id = id;
        return row;
    }

    async DeleteTableRowForDocument(app, prop, value) {
        let row = await this.GetEnteredDataRow(app);

        row[prop] = row[prop].filter(e => e.id !== value);

        let id = await this.db.table('entereddata').put(row);
        row.id = id;
        return row;
    }

    async GetEnteredDataRow(app) {
        let entereddata = await this.db.entereddata
            .where({ IDService: app.IDService })
            .toArray();

        entereddata = entereddata.filter(c => c.UniqueID === app.UniqueID);


        let row = {
            IDService: app.IDService,
            UniqueID: app.UniqueID,
            ErledigteArbeiten: '',
            Duration: 0,
            StartTime: null,
            SignatureBerater: null,
            SignatureKunde: null,
            AdditionalServiceNeeded: false,
            Finished: 0,
            Started: 0,
            //Menck
            ServiceMangelbeschreibung: "",
            PauschalMaterial: false,
            PauschalKleinMaterial: false,
            MaterialList: [],
            ZeitList: [],
            Besorgungsfahrt: [],
            StartDate: null,
            EndDate: null,
            IsMobileEdited: false,
            //GS
            ServiceTyp: '', // tip servisa radio button
            Festgestellte: '',
            Ausgefuhrte: '',
            Benotigtes: '',
            Masshaltigkeit: null, //checkbox
            SollMass: '',
            IstMass: '',
            Stabzahl: null,
            SollGelocht: '',
            SollUngelocht: '',
            IstGelocht: '',
            IstUngelocht: '',
            LangeFuhrungsscienen: null,
            SollLange: '',
            IstLange: '',
            FuhrungsschienenMontiert: null,
            FunktionstestAnlage: null,
            FotosGemacht: null,
            FehlerhafteMontage: null,
            Bedienfehler: null,
            Bedienungs: null,
            ISRollo: null,
            Fliegengitter: null,
            TerminNotwendig: null,
            Axialspiel: null,
            Panzer: null,
            Endstab: null,
            Aufhanger: null,
            Wickeldurchmesser: null,
            ProfilstabeVerbaut: null,
            FehlerhafteProgramierung: null,
            MotorSteuerung: null,
            UnzulassigeInstallation: null,
            Einlauftrichter: null,
            Gurtwickler: null,
            Einbaurichtung: null,
            Sonstiges: null,
            ZusatzlicheAngaben: '',
            ServicemonteurList: [],
            FuhrungsschienenLangeList: [],
            StabzahlPanzerList: [],
            MasshaltigkeitElementeList: [],
            PdfService: null,
            PdfPruf: null,
            ekTelefon1: app.ekTelefon1,
            ekTelefon2: app.ekTelefon2,
            ekOrt: app.ekOrt,
            ekPlz: app.ekPlz,
            ekStrasse: app.ekStrasse
        }
        if (entereddata.length > 0) {
            row = entereddata[0];
        } else {
            let appointment = (await this.db.appointments
                .where({ IDService: app.IDService })
                .toArray());
            appointment = appointment.filter(c => c.UniqueID === app.UniqueID)[0];
            if (COMPANY !== 'MENCK' && COMPANY !== 'LOEFFEL') row.StartTime = appointment.StartDate;
        }
        return row;
    }

    // async GetEnteredDataRowForMenck(IDService) {
    //     let entereddata = await this.db.entereddata
    //         .where({ IDService: parseInt(IDService) })
    //         .toArray();
    //     let row = {
    //         IDService: IDService,
    //         ErledigteArbeiten: "",
    //         NeededTime: 0,
    //         StartTime: null,
    //         SignatureBerater: null,
    //         SignatureKunde: null,
    //         Finished: 0,
    //         Started: 0,
    //         ServiceMangelbeschreibung: "",
    //         PauschalMaterial: false,
    //         PauschalKleinMaterial: false,
    //         MaterialList: [],
    //         ZeitList: [],
    //         Pdf: null,
    //         StartDate: null,
    //         EndDate: null
    //     };
    //     if (entereddata.length > 0) {
    //         row = entereddata[0];
    //     } else {
    //         const appointment = (await this.db.appointments
    //             .where({ IDService: parseInt(IDService) })
    //             .toArray())[0];
    //         if (COMPANY !== 'MENCK') row.StartTime = appointment.StartDate;
    //     }
    //     return row;
    // }


    async SavePropertyValue(app, prop, value) {
        return await this.UpdateEnteredRowData(app, prop, value);
    }



    // async SavePropertyValueForMenck(IDService, prop, value){
    //     return await this.UpdateEnteredRowDataForMenck(IDService, prop, value);
    // }

    async SavePropertyValueForTable(app, prop, rowId, tableName, value) {
        return await this.UpdateEnteredRowTableData(app, prop, rowId, tableName, value);
    }

    async DeleteTableRow(app, prop, value) {
        return await this.DeleteTableRowForDocument(app, prop, value);
    }

    async SaveData(app, values) {
        let row = await this.GetEnteredDataRow(app);
        row = Object.assign({}, row, values);
        //row = {...row, values}
        let id = await this.db.table("entereddata").put(row);
        row.id = id;
        return row;
    }

    async SaveDataFolgetermin(values) {
        let row = await this.GetEnteredDataRow({ IDService: -1 });
        row = Object.assign(row, values);
        let id = await this.db.table("entereddata").put(row);
        row.id = id;
        return row;
    }

    async SavePropertyValue2(IDService, prop, value) { }

    _arrayBufferToBase64(buffer) {
        let binary = "";
        let bytes = new Uint8Array(buffer);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    _base64ToArrayBuffer(base64) {
        let _b64 = base64;
        if (_b64.startsWith("data:image/png;base64,"))
            _b64 = _b64.substring(22);
        let raw = window.atob(_b64);
        let rawLength = raw.length;
        let array = new Uint8Array(new ArrayBuffer(rawLength));

        for (let i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }
    wordSplitter = (str, l) => {
        if(str === " ") return " ";
        let strs = [];
        let enterSplit = str.split(/\n/);
        let modified = enterSplit.map(row => {
            strs = [];
            if(row.length <= l) return row;
            else{
                while (row.length > l) {
                        let pos = row.substring(0, l).lastIndexOf(" ");
                        pos = pos <= 0 ? l : pos;
                        strs.push(row.substring(0, pos));
                        let i = row.indexOf(" ", pos) + 1;
                        if (i < pos || i > pos + l) i = pos;
                        row = row.substring(i);
                    }
                  strs.push(row);
                  return strs;                
            } 
        })
        return modified.flat();
    };

    async SaveSignaturesForServiceProtokol(app, value) {
        let row = await this.GetEnteredDataRow(app)

        row.SignatureKunde = value.SignatureKunde;
        row.AdditionalServiceNeeded = value.AdditionalServiceNeeded;

        await this.db.table('entereddata').put(row);
        return row;
    }

    async SaveSignatures(app, value) {
        let company = value.Company;
        let fileNameCompany = "Bericht";

        switch (company) {
            case COMPANY_BROEMSE:
                fileNameCompany = "Bericht";
                break;
            case COMPANY_EVERS:
                fileNameCompany = "Bericht";
                break;
            case COMPANY_GROWE:
                fileNameCompany = "Bericht";
                break;
            case COMPANY_OST:
                fileNameCompany = "Servicebericht";
                break;
            default:
                fileNameCompany = "Berichtfurunterzeichnen";
                break;
        }

        let pdfSettings = pdfSettingsData(company);

        let row = await this.GetEnteredDataRow(app);

        row.SignatureBerater = value.SignatureBerater;
        row.SignatureKunde = value.SignatureKunde;
        row.AdditionalServiceNeeded = value.AdditionalServiceNeeded;
        let bericht = await this.db.attachments.where((company !== COMPANY_BROEMSE &&  company !== COMPANY_EVERS) ? {
            FileName: fileNameCompany,
            IDService: app.IDService
        } : {
            IDService: app.IDService
        })
            .toArray();

        if ((company === COMPANY_BROEMSE || company === COMPANY_EVERS) && bericht.length > 0) {
            //Potpisuje se samo poslednji dodani dokument sa nazivom Bericht + broj dokumenta        
            const pattern = new RegExp("(Bericht)*(Bericht\\d*$)");
            const berichtDocumentsFiltered = bericht.filter(c => { return pattern.exec(c.FileName) !== null ? true : false });
            //Dokumenti sortirani po id-u, bericht[0] je poslednji dodani dokument koji odgovara uslovima
            bericht = berichtDocumentsFiltered.sort((a, b) => a.id < b.id ? 1 : -1)
        }

        let appDB = await this.db.appointments.get({
            UniqueID: app.UniqueID, IDService: app.IDService
        });

        let fotos = await this.db.fotos.where({ IDService: app.IDService }).toArray();
        fotos = fotos.filter(c => c.UniqueID === app.UniqueID);

        const hasBild = fotos.length > 0;
        const isErledigt = row.Finished === 1;

        //All data used for generating PDF  -- we can use this later to determine if pdf data is different from values in state
        row.pdfFieldValues = {
            AdditionalServiceNeeded: value.AdditionalServiceNeeded || false,
            hasBild: hasBild,
            Finished: row.Finished,
            ErledigteArbeiten: row.ErledigteArbeiten,
            StartTime: row.StartTime,
            Duration: row.Duration ? row.Duration : appDB.Duration
        };

        
        const pdfDoc = PDFDocumentFactory.load(
            
            this._base64ToArrayBuffer(bericht[0].Attachment)
        );
        const pages = pdfDoc.getPages();

        const COURIER_FONT = "Helvetica";

        const [courierFontRef] = pdfDoc.embedStandardFont("Helvetica");
        const beraterPngBytes = this._base64ToArrayBuffer(row.SignatureBerater);
        const kundePngBytes = this._base64ToArrayBuffer(row.SignatureKunde);

        const [beraterPngRef, beraterPngDims] = pdfDoc.embedPNG(
            beraterPngBytes
        );
        const [kundePngRef] = pdfDoc.embedPNG(kundePngBytes);

        const BERATER_PNG_WIDTH = beraterPngDims.width * 0.4;
        const BERATER_PNG_HEIGHT = beraterPngDims.height * 0.4;

        const existingPage = pages[0]
            .addFontDictionary(COURIER_FONT, courierFontRef)
            .addImageObject("Berater", beraterPngRef)
            .addImageObject("Kunde", kundePngRef);

        let erledigteArbeit = this.wordSplitter(
            row.ErledigteArbeiten || " ",
            70
        );

        let signaturePos = bericht[0].SignaturePosition;
        let startingPoint = 841.92 - signaturePos.Y;

        const newContentStream = pdfDoc.createContentStream(
            drawImage("Berater", {
                x: signaturePos.X + 55,
                y: startingPoint + 50,
                width: BERATER_PNG_WIDTH,
                height: BERATER_PNG_HEIGHT
            }),
            drawImage("Kunde", {
                x: signaturePos.X + 300,
                y: startingPoint + 50,
                width: BERATER_PNG_WIDTH,
                height: BERATER_PNG_HEIGHT
            }),
            drawLinesOfText([appDB.ekOrt || " "], {
                x: signaturePos.X + 10,
                y: startingPoint + 77,
                font: COURIER_FONT,
                size: 10,
                colorRgb: pdfSettings.ekOrt.colorRgb
            }),
            drawLinesOfText([moment().format("DD.MM YYYY")], {
                x: signaturePos.X + 255,
                y: startingPoint + 77,
                font: COURIER_FONT,
                size: 10,
                colorRgb: pdfSettings.CurrentDate.colorRgb
            }),
            drawLinesOfText(erledigteArbeit, { //erledigteArbeit
                x: signaturePos.X - 10,
                y: startingPoint + 250,
                font: COURIER_FONT,
                size: pdfSettings.erledigteArbeit.size,
                colorRgb: pdfSettings.erledigteArbeit.colorRgb
            })
        );

        existingPage.addContentStreams(pdfDoc.register(newContentStream));
        if (row.AdditionalServiceNeeded) {
            const newContentStreamText = pdfDoc.createContentStream(
                drawLinesOfText(["X"], {
                    x: signaturePos.X - 12,
                    y: startingPoint + 4,
                    font: COURIER_FONT,
                    size: 14,
                    colorRgb: pdfSettings.AdditionalServiceNeeded.colorRgb
                })
            );
            existingPage.addContentStreams(
                pdfDoc.register(newContentStreamText)
            );
        }

        if (hasBild) {
            const newContentStreamText = pdfDoc.createContentStream(
                drawLinesOfText(["X"], {
                    x: signaturePos.X - 12,
                    y: startingPoint - 10,
                    font: COURIER_FONT,
                    size: 14,
                    colorRgb: pdfSettings.hasBild.colorRgb
                })
            );
            existingPage.addContentStreams(
                pdfDoc.register(newContentStreamText)
            );
        }

        if (isErledigt) {
            const newContentStreamText = pdfDoc.createContentStream(
                drawLinesOfText(["X"], {
                    x: signaturePos.X - 12,
                    y: startingPoint + 18,
                    font: COURIER_FONT,
                    size: 14,
                    colorRgb: pdfSettings.isErledigt.colorRgb
                })
            );
            existingPage.addContentStreams(
                pdfDoc.register(newContentStreamText)
            );
        }

        const pdfBytes = PDFDocumentWriter.saveToBytes(pdfDoc);
        const b64 = this._arrayBufferToBase64(pdfBytes);
        row.pdf = b64;

        await this.db.table("entereddata").put(row);
        return row;
    }

    async FotoAdd(app, data) {
        let id = await this.db.table("fotos").put({
            UniqueID: app.UniqueID,
            IDService: app.IDService,
            data: data
        });
        return { id, IDService: app.IDService, UniqueID: app.UniqueID, data };
    }

    async FotoRemove(id) {
        await this.db.table("fotos").delete(id);
    }
}
