import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import LinearProgress from "@material-ui/core/LinearProgress";

import { DoSync, ReportProgress } from "../actions/SyncActions";

import { setTitle } from "../actions/AppActions";

import { Button } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import notification from "../helpers/toast_notification";

class SyncPage extends Component {
    componentDidMount() {
        this.props.setTitle("Synchronisieren");
    }

    DoSync = async () => {
        let settings = Object.assign(this.props.settings, {
            Company: this.props.company
        });
        //Provjera konekcije
        navigator.onLine === false
            ? notification(
                  "warning",
                  "Ihre Internet Geschwindigkeit ist nicht ausreichend , bitte synchronisieren Sie zu einem späteren Zeitpunkt!"
              )
            : this.props.DoSync(settings, this.props.ReportProgress);
    };

    render() {
        const style = {
            container: {
                height: "100vh",
                margin: "30px"
            },

            control: {
                position: "relative",
                top: "calc(40%)"
            }
        };

        return (
            <div style={style.container}>
                <div style={style.control}>
                    {!this.props.sync.syncInProgress && (
                        <div>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={this.DoSync}
                            >
                                Sync
                            </Button>
                        </div>
                    )}
                    {/* ToastContainer Potreban za prikaz toast notifikacije */}
                    <ToastContainer autoClose={8000} />
                    {this.props.sync.syncInProgress && (
                        <div>
                            <LinearProgress
                                variant="determinate"
                                value={this.props.sync.syncProgressValue}
                            />
                            <div>
                                Synchronisierung läuft
                                {` `+this.props.sync.syncProgressValue} %
                            </div>
                            <br />
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={this.props.StopSync}
                            >
                                Stop
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

SyncPage.propTypes = {
    settings: PropTypes.object.isRequired,
    DoSync: PropTypes.func.isRequired,
    company: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    settings: state.settings,
    sync: state.sync,
    company: state.app.company
});

export default connect(
    mapStateToProps,
    { DoSync, ReportProgress, setTitle }
)(SyncPage);
