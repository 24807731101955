import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

const initialState={};

const middleware = [thunk];
function configureStore(){
    return createStore(
        rootReducer, 
        initialState,         
        composeWithDevTools(
            applyMiddleware(...middleware)),
            
    );
}
export default configureStore;