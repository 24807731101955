import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import "moment/locale/de";
import convertFile from "../extensions/FileConverter";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { TimePicker } from "@material-ui/pickers";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import {
    CloseIcon,
    DeleteIcon,
    LocationIcon,
    CalendarIcon,
    TimeIcon,
    PositionIcon,
    AttachmentIcon,
    RefreshIcon,
    PinDropIcon,
    AssignmentCheckIcon,
    AssignmentLateIcon
} from "../../bundles/IconsBundle";
import TakePhoto from "../extensions/TakePhoto";
import MessageBox from "../dialogs/MessageBox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Loader from "../extensions/Loader";

import { setTitle } from "../../actions/AppActions";
import {
    SelectAppointmentByID,
    SetPropertyValue,
    SavePropertyValue,
    SaveData,
    FotoAdd,
    FotoRemove,
    DownloadFileManually
} from "../../actions/TaskActions";
import {
    SelectedAppSelector,
    SelectedAppAttachmentsSelector,
    SelectedAppNotesSelector,
    SelectedAppPhotosSelector
} from "../../selectors/SelectedAppointmentSelector";

import SelectedDayStatusSelector from "../../selectors/DayStatusSelector";
import DownloadFileDialog from "../dialogs/menck/DownloadFileDialog";

const styles = {
    container: {
        textAlign: "left",
        marginTop: "75px",
        marginLeft: "20px",
        marginRight: "20px"
    }
};

class TaskViewOst extends Component {
    state = {
        dialog: false,
        TakePhotoOpen: false,
        images: this.props.images || [],
        _deleteImageId: -1,
        _deleteImageMessageOpen: false,
        downloadFileDialogOpen: false,
        fileNameForDownload: "",
        fileExtForDownload : "",
        fileIdForDownload: -1,
        downloadInProgress: false,
    };

    file_input = [];

    componentDidMount() {
        moment.locale("de");
        this.props.SelectAppointmentByID(this.props.match.params.id,this.props.match.params.service);
    }

    _returnDescriptionData = data => {
        return JSON.parse(data !== undefined ? data.toString() : "[]");
    };

    materialTypeToName = item => {
        var material = "";
        if (item.MaterialType === 0) material = "PVC_Fenster";
        if (item.MaterialType === 1) material = "PVC_Hausturen";
        if (item.MaterialType === 2) material = "Rolladen";
        if (item.MaterialType === 3) material = "ALU_Hausturen";
        if (item.MaterialType === 4) material = "ALU_Fenster";
        if (item.MaterialType === 5) material = "HST";
        return material;
    };

    handleClose = () => {
        this.props.history.goBack();
    };

    fotoMachen = () => {
        this.setState({ TakePhotoOpen: true, images: this.state.images });
    };

    closeTakePhoto = () => {
        this.setState({ TakePhotoOpen: false });
    };

    handleTakeScreenshot = src => {
        this.props.FotoAdd(this.props.app, src);
        var st = this.state;
        //st.images.push(src);
        st.TakePhotoOpen = false;
        this.setState(st);
    };

    removeImage = idToRemove => {
        var st = this.state;
        st._deleteImageId = idToRemove;
        st._deleteImageMessageOpen = true;
        this.setState(st);
    };

    onRemoveImage = () => {
        var st = this.state;
        //st.images = this.state.images.filter((item,index) => index !== this.state._deleteImageId)
        this.props.FotoRemove(st._deleteImageId);
        st._deleteImageId = -1;
        st._deleteImageMessageOpen = false;
        this.setState(st);
    };

    handleCloseMessage = () => {
        var st = this.state;
        st._deleteImageMessageOpen = false;
        this.setState(st);
    };

    openDocument = att => {
        if(att.Attachment != null){
            convertFile(att.FileName, att.Extension, att.Attachment);
        } else {
            this.setState({...this.state,
                downloadFileDialogOpen: true,
                fileNameForDownload: att.FileName,
                fileExtForDownload: att.Extension,
                fileIdForDownload: att.id
             });
        }
    };

    ShowPDF = att => {
        convertFile("Servicebericht", ".pdf", att);
    };

    signDialog = () => {
        this.props.history.push(
            "/tasksign/" + this.props.match.params.id + "/" + this.props.match.params.service+ "/edit"
        );
        this.setState({ ...this.state, dialog: true });
    };

    saveData = async () => {
        const data = {
            Finished: this.props.app.Finished,
            StartTime: this.props.app.StartTime,
            Duration: this.props.app.Duration,
            ErledigteArbeiten: this.props.app.ErledigteArbeiten
        };
        await this.props.SaveData(this.props.app, data);
    };

    handleChange = async (e, checked) => {
        if (e._d) {
            await this.props.SavePropertyValue(
                this.props.app,
                "StartTime",
                e._d
            );
        } else {
            if (e.target.name === "Finished") {
                await this.props.SetPropertyValue(
                    this.props.app,
                    e.target.name,
                    checked ? 1 : 0
                );
                const duration = moment.duration(
                    moment(Date.now()).diff(moment(this.props.app.StartTime))
                );
                await this.props.SetPropertyValue(
                    this.props.app,
                    "Duration",
                    Math.round(duration.asMinutes())
                );
                // await this.props.SavePropertyValue(
                //     this.props.app.IDService,
                //     "Duration",
                //     Math.round(duration.asMinutes())
                // );
                this.saveData();
            } else
                await this.props.SetPropertyValue(
                    this.props.app,
                    e.target.name,
                    e.target.value
                );
        }
    };

    handleBlur = async e => {
        this.saveData();
    };

    selectFile = event => {
        event.preventDefault();
        console.log("FI", this.file_input);
        this.file_input = document.createElement("input");
        this.file_input.addEventListener(
            "change",
            this.uploadFile.bind(this),
            false
        );
        this.file_input.type = "file";
        this.file_input.click();
    };

    isDocumentUpdateNeeded = () => {
        const pdfData = this.props.app.pdfFieldValues;
        const appData = this.props.app;
        if (!pdfData) return false;
        if (pdfData.AdditionalServiceNeeded !== appData.AdditionalServiceNeeded)
            return true;
        if (pdfData.Finished !== appData.Finished) return true;
        if (pdfData.Duration !== appData.Duration) return true;
        if (pdfData.ErledigteArbeiten !== appData.ErledigteArbeiten)
            return true;
        if (pdfData.StartTime !== appData.StartTime) return true;
        if (pdfData.hasBild !== (this.props.fotos.length > 0)) return true;

        return false;
    };

    getBase64 = file => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    uploadFile = () => {
        this.getBase64(this.file_input.files[0]).then(src =>
            this.props.FotoAdd(this.props.app, src)
        );
    };

    _initialsFormatter = () => {
        let mitarbeiterInitials = this.props.app.MitarbeiterInitials;
        let berater = this.props.Berater;
        if (
            mitarbeiterInitials !== undefined &&
            mitarbeiterInitials.split(",").length > 1
        ) {
            let ret = mitarbeiterInitials
                .split(",")
                .filter(item => item !== berater)
                .filter((v, i, a) => a.indexOf(v) === i);
            return ret.length !== 0 ? "+ " + ret.toString() : "";
        } else return "";
    };

    _hasNotiz = ({ Bemerkung }) => {
        if (Bemerkung !== undefined && Bemerkung !== null && Bemerkung !== "")
            return true;
        else return false;
    };

    _categoryFormatter = ({ ServiceArt, Kategorie }) => {
        if (ServiceArt === 0 && Number(Kategorie) === 0) return "Glastausch";
        else if (ServiceArt === 0 && Number(Kategorie) === 1)
            return "Einbruchschaden";
        else if (ServiceArt === 0 && Number(Kategorie) === 2)
            return "sonstige Reparaturen";
        else if (ServiceArt === 0 && Number(Kategorie) === 3)
            return "Umrüstung";
        else if (ServiceArt === 0 && Number(Kategorie) === 4)
            return "Insektenschutz";
        else if (ServiceArt === 0 && Number(Kategorie) === 5)
            return "Beschattung";
        else if (ServiceArt === 0 && Number(Kategorie) === 6)
            return "Reklamation aus Montage";
        else if (ServiceArt === 1 && Number(Kategorie) === 0)
            return "Werkstatt";
        else if (ServiceArt === 2 && Number(Kategorie) === 0) return "Wartung";
        else if (ServiceArt === 2 && Number(Kategorie) === 1)
            return "freie Wartung";
        else return "";
    };

    _hasTerminArt = ({ ServiceArt, TerminArt }) => {
        if (
            ServiceArt === 0 &&
            (Number(TerminArt) === 0 ||
                Number(TerminArt) === 1 ||
                Number(TerminArt) === 2)
        )
            return true;
        else return false;
    };

    _returnTerminArt = ({ ServiceArt, TerminArt }) => {
        if (ServiceArt === 0 && Number(TerminArt) === 0) return "Reklamation";
        else if (ServiceArt === 0 && Number(TerminArt) === 1) return "Aufmaß";
        else if (ServiceArt === 0 && Number(TerminArt) === 2)
            return "lt. Auftrag";
        else if (ServiceArt === 0 && Number(TerminArt) === 3)
            return "ohne Auftrag";
        else return "";
    };

    _checkServiceArt = ({ ServiceArt }) => {
        if (ServiceArt === 0 || ServiceArt === 1) return true;
        else return false;
    };

    _checkServiceArt2 = ({ ServiceArt }) => {
        if (ServiceArt === 2) return true;
        else return false;
    };

    _returnMangelbeschreibung = ({
        ServiceMangelbeschreibung,
        Arbeitsauftrag
    }) => {
        if (
            ServiceMangelbeschreibung !== undefined &&
            ServiceMangelbeschreibung !== null &&
            ServiceMangelbeschreibung.length > 0 &&
            ServiceMangelbeschreibung !== ""
        )
            return ServiceMangelbeschreibung;
        else if (
            Arbeitsauftrag !== undefined &&
            Arbeitsauftrag !== null &&
            Arbeitsauftrag.length > 0 &&
            Arbeitsauftrag !== ""
        )
            return Arbeitsauftrag;
        else return "keine mängelbeschreibung";
    };

    _formatStufe = ({ Stufe }) => {
        if (Stufe === null) return "NULL";
        if (Stufe === 0) return "1 Haustür separat";
        if (Stufe === 1) return "1-5 Fenster";
        if (Stufe === 2) return "6-10 Fenster";
        if (Stufe === 3) return "11-15 Fenster";
        if (Stufe === 4) return "16-25 Fenster";
        if (Stufe === 5) return "26-35 Fenster";
        if (Stufe === 6) return "außer Tarif";
        return "";
    };

    //Download fajlova koji nisu inicijalno povučeni zbog ograničenja veličine fajlova.
    downloadFile = () => {
        // console.log("File ID:", this.state.fileForDownloadID);
        this.setState({ ...this.state, downloadInProgress: true });
        let fileName = this.state.fileNameForDownload + this.state.fileExtForDownload;
        this.props.DownloadFileManually(this.props.settings, this.props.app.IDService, fileName, this.state.fileIdForDownload,);
    }

    handleCloseDialogs = () => {
        this.setState({
            ...this.state,
            dialog: false,
            TakePhotoOpen: false,
            _deleteImageMessageOpen: false,
            downloadFileDialogOpen: false,
            downloadInProgress: false
        });
    };

    render() {
        const { app, notes, attachments, fotos } = this.props;
        const berichtDocs = attachments.filter(
            c => c.FileName === "Servicebericht"
        );
        const hasNotiz = this._hasNotiz(app);
        const category = this._categoryFormatter(app);
        const hasTerminArt = this._hasTerminArt(app);
        const hasServiceArt = this._checkServiceArt(app);
        const hasServiceArt2 = this._checkServiceArt2(app);

        return (
            <Dialog fullScreen open={true}>
                <AppBar>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            onClick={this.handleClose}
                            aria-label="Close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <div>
                            <div>
                                {app.KName} - {app.BName}
                            </div>
                            <div>
                                ({app.BLand}-{app.BPLZ} {app.BOrt})
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>

                <div style={styles.container}>
                    {/* Service info */}
                    <Table>
                        <TableBody>
                            <TableRow style={{ height: 10 }}>
                                <TableCell>
                                    <LocationIcon fontSize="small" />
                                </TableCell>
                                <TableCell>Endkunde: </TableCell>
                                <TableCell>
                                    <div>{app.Strasse}</div>
                                    <div>
                                        {app.BLand}-{app.BPLZ} {app.BOrt}
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <CalendarIcon fontSize="small" />
                                </TableCell>
                                <TableCell>Start: </TableCell>
                                <TableCell>
                                    {moment(app.StartDate).format(
                                        "DD.MM YYYY HH:mm"
                                    )}{" "}
                                    {this._initialsFormatter()}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <CalendarIcon fontSize="small" />
                                </TableCell>
                                <TableCell>Enddatum: </TableCell>
                                <TableCell>
                                    {moment(app.EndDate).format(
                                        "DD.MM YYYY HH:mm"
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <TimeIcon fontSize="small" />
                                </TableCell>
                                <TableCell>Dauer: </TableCell>
                                <TableCell>{app.Duration} min</TableCell>
                            </TableRow>
                            {hasNotiz && (
                                <TableRow>
                                    <TableCell>
                                        <PinDropIcon
                                            color="error"
                                            fontSize="small"
                                        />
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                        {app.Bemerkung}
                                    </TableCell>
                                </TableRow>
                            )}
                            <TableRow>
                                <TableCell>
                                    <AssignmentCheckIcon fontSize="small" />
                                </TableCell>
                                <TableCell>Auftrag: </TableCell>
                                <TableCell>
                                    {app.AuftragsNr}.{app.AuftragsErw}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <PositionIcon fontSize="small" />
                                </TableCell>
                                <TableCell>Kategorie: </TableCell>
                                <TableCell>{category}</TableCell>
                            </TableRow>
                            {hasTerminArt && (
                                <TableRow>
                                    <TableCell>
                                        <PositionIcon fontSize="small" />
                                    </TableCell>
                                    <TableCell>Terminart: </TableCell>
                                    <TableCell>
                                        {this._returnTerminArt(app)}
                                    </TableCell>
                                </TableRow>
                            )}
                            {hasServiceArt && (
                                <React.Fragment>
                                    <TableRow>
                                        <TableCell>
                                            <AssignmentLateIcon fontSize="small" />
                                        </TableCell>
                                        <TableCell colSpan={2}>
                                            Mangelbeschreibung{" "}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={3}>
                                            {this._returnMangelbeschreibung(
                                                app
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            )}
                            {hasServiceArt2 && (
                                <React.Fragment>
                                    <TableRow>
                                        <TableCell>Stufe: </TableCell>
                                        <TableCell colSpan={2}>
                                            {this._formatStufe(app)}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Anzahl fenster: </TableCell>
                                        <TableCell colSpan={2}>
                                            {app.AnzahlFenster}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Anzahl turen: </TableCell>
                                        <TableCell colSpan={2}>
                                            {app.AnzahlTuren}
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            )}
                        </TableBody>
                    </Table>
                    <br />
                    {/* Mangelbeschreibung */}
                    {/* <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{fontSize:14}} colSpan={4}>
                                    Mangelbeschreibung
                            </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Pos</TableCell>
                                <TableCell>Material</TableCell>
                                <TableCell>Element</TableCell>
                                <TableCell>Notiz</TableCell>
                            </TableRow>
                            {
                                this._returnDescriptionData(app.ServiceMangelbeschreibung).map(item => {
                                    return (
                                        <React.Fragment key={item.ID}>
                                            <TableRow style={{backgroundColor:'lightgray'}}>
                                                <TableCell style={{border:0}}>{item.Position}</TableCell>
                                                <TableCell style={{border:0}}>{this.materialTypeToName(item)}</TableCell>
                                                <TableCell style={{border:0}}>{item.Description}</TableCell>
                                                <TableCell style={{border:0}}>{item.Notiz}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell  colSpan={3}>{item.Mangelbechreibung}</TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    );
                                })
                            }
                        </TableBody>
                    </Table> */}

                    <br />
                    {/* Notiz */}
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ fontSize: 14 }} colSpan={4}>
                                    Notiz
                                </TableCell>
                            </TableRow>
                            {notes.map(item => {
                                const attachments = this.props.attachments.filter(
                                    c => c.NoteID === item.id
                                );
                                const attDom = attachments.map(item => {
                                    return (
                                        <span key={item.id}>
                                            <AttachmentIcon
                                                fontSize="small"
                                                onClick={() => {
                                                    this.openDocument(item);
                                                }}
                                            />
                                            {item.FileName}
                                            {item.Extension}
                                        </span>
                                    );
                                });
                                return (
                                    <React.Fragment key={item.id}>
                                        <TableRow>
                                            <TableCell style={{ border: 0 }}>
                                                {item.Note ? item.Note : "-"}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <b>
                                                    <span
                                                        style={{
                                                            fontSize: ".8em"
                                                        }}
                                                    >
                                                        {item.MitarbeiterName}{" "}
                                                        {moment(
                                                            item.NoteDate
                                                        ).format(
                                                            "DD.MM YYYY HH:mm"
                                                        )}{" "}
                                                        {attDom}
                                                    </span>
                                                </b>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>

                    <br />
                    {/* Dokument unterzeichnung */}
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell
                                    style={{ fontSize: 14, border: 0 }}
                                    colSpan={2}
                                >
                                    Dokument unterzeichnung:
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ border: 0 }}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={this.signDialog}
                                        disabled={
                                            berichtDocs.length > 0
                                                ? false
                                                : true
                                        }
                                    >
                                        Unterzeichnen
                                    </Button>
                                    <RefreshIcon
                                        font="small"
                                        visibility={
                                            this.isDocumentUpdateNeeded()
                                                ? "visible"
                                                : "hidden"
                                        }
                                    />
                                </TableCell>
                                <TableCell style={{ border: 0 }}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        disabled={!this.props.app.pdf}
                                        onClick={() => {
                                            this.ShowPDF(this.props.app.pdf);
                                        }}
                                    >
                                        Dokument anzeigen
                                    </Button>
                                </TableCell>
                            </TableRow>
                            {berichtDocs.length === 0 && (
                                <TableRow>
                                    <TableCell
                                        style={{ color: "red" }}
                                        colSpan={2}
                                    >
                                        <span style={{ color: "red" }}>
                                            <br />
                                            <span>
                                                Bitte Dokument zuerst erstellen
                                                lassen von dem Service Planer!
                                            </span>
                                        </span>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>

                    <br />
                    {/* Form fields */}
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ border: 0 }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    this.props.app.Finished ===
                                                    1
                                                }
                                                onChange={this.handleChange}
                                                name="Finished"
                                                value="checkedB"
                                                color="primary"
                                                disabled={
                                                    this.props.app.Started ===
                                                        0 ||
                                                        (this.props.daystatus
                                                            .StartPause !== "" &&
                                                            this.props.daystatus
                                                                .EndPause === "")
                                                        ? true
                                                        : false
                                                }
                                            />
                                        }
                                        label="Fertig"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ border: 0 }}>
                                     <TimePicker 
                                        variant="filled"
                                        ampm={false}                                        
                                        name="StartTime"
                                        label="Start zeit"
                                        format="HH:mm"
                                        value={this.props.app.StartTime ? this.props.app.StartTime : this.props.app.StartDate}
                                        onChange={this.handleChange}
                                        onBlur={this.handleBlur}
                                        />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ border: 0 }}>
                                    <TextField
                                        multiline
                                        style={{ width: "100%" }}
                                        name="Duration"
                                        label="Benoetigte zeit (min)"
                                        margin="dense"
                                        onChange={this.handleChange}
                                        onBlur={this.handleBlur}
                                        value={this.props.app.Duration}
                                        type="number"
                                        variant="filled"
                                        disabled={true}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ border: 0 }}>
                                    <TextField
                                        multiline
                                        style={{ width: "100%" }}
                                        name="ErledigteArbeiten"
                                        label="Erledigte Arbeiten"
                                        onChange={this.handleChange}
                                        onBlur={this.handleBlur}
                                        value={this.props.app.ErledigteArbeiten}
                                        margin="dense"
                                        variant="filled"
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <br />
                    {/* Fotos */}
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ border: 0 }}>
                                    <GridList cellHeight={160} cols={3}>
                                        {fotos.map((tile, index) => (
                                            <GridListTile
                                                cols={1}
                                                key={index}
                                                style={{
                                                    border: "1px solid #DDD"
                                                }}
                                            >
                                                <img src={tile.data} alt="" />
                                                <GridListTileBar
                                                    actionIcon={
                                                        <IconButton>
                                                            <DeleteIcon
                                                                color="secondary"
                                                                onClick={() => {
                                                                    this.removeImage(
                                                                        tile.id
                                                                    );
                                                                }}
                                                            />
                                                        </IconButton>
                                                    }
                                                />
                                            </GridListTile>
                                        ))}
                                    </GridList>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ border: 0 }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.fotoMachen}
                                    >
                                        Foto machen
                                    </Button>{" "}
                                    &nbsp;
                                    <Button
                                        onClick={this.selectFile}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Foto hinzufuegen
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
                <TakePhoto
                    open={this.state.TakePhotoOpen}
                    handleClose={this.closeTakePhoto}
                    handleTakeScreenshot={this.handleTakeScreenshot}
                />
                <MessageBox
                    open={this.state._deleteImageMessageOpen}
                    Title="Foto Loeschen?"
                    MessageBox="Sind Sie Sicher?"
                    AcceptText="Loeschen"
                    CancelText="Abbrechen"
                    handleClose={this.handleCloseMessage}
                    handleAccept={this.onRemoveImage}
                />
                <Dialog fullScreen open={this.state.dialog}>
                    <AppBar>
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                onClick={this.handleClose}
                                aria-label="Close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <div>
                                <div>Serviceauftrag Daten einfügen</div>
                            </div>
                        </Toolbar>
                    </AppBar>
                </Dialog>
                <DownloadFileDialog
                    open={this.state.downloadFileDialogOpen}
                    handleClose={this.handleCloseDialogs}
                    downloadFile={this.downloadFile}
                    downloadInProgress={this.state.downloadInProgress}
                />
            </Dialog>
        );
    }
}

TaskViewOst.propTypes = {
    app: PropTypes.object.isRequired,
    notes: PropTypes.array.isRequired,
    attachments: PropTypes.array.isRequired,
    fotos: PropTypes.array.isRequired,
    setTitle: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    app: SelectedAppSelector(state),
    notes: SelectedAppNotesSelector(state),
    attachments: SelectedAppAttachmentsSelector(state),
    fotos: SelectedAppPhotosSelector(state),
    daystatus: SelectedDayStatusSelector(state),
    Berater: state.settings.Berater
});

//export default connect(mapStateToProps, { DoSync, ReportProgress })(TaskView);
const withLoader = Loader(TaskViewOst);

const connected = connect(
    mapStateToProps,
    {
        setTitle,
        SelectAppointmentByID,
        SetPropertyValue,
        SavePropertyValue,
        SaveData,
        FotoAdd,
        FotoRemove,
        DownloadFileManually
    }
)(withLoader);

export default withRouter(connected);
