import {
    INITIALISE_STATE,
    TOGGLE_DRAWER,
    SET_TITLE,
    CHANGE_FILTER
} from "./types";
import dbSettings from "../db/DbSettings";
import dbTasks from "../db/DbTasks";
import dbDayStatus from "../db/DbDayStatus";
import { COMPANY } from "../settings";

export const toggleDrawer = val => {
    return {
        type: TOGGLE_DRAWER,
        payload: val
    };
};

export const setTitle = val => {
    return {
        type: SET_TITLE,
        payload: val
    };
};

export const intialiseState = async => async dispatch => {
    let settings = await new dbSettings().LoadSettings();
    let allDbData = await new dbTasks().getAllData();
    let company = COMPANY;
    if (company === 'OST') {
        let dayStatus = await new dbDayStatus().getAllData();
        dispatch({
            type: INITIALISE_STATE,
            settings: settings,
            allDbData: allDbData,
            dayStatus: dayStatus
        });
    } else {
        dispatch({
            type: INITIALISE_STATE,
            settings: settings,
            allDbData: allDbData
        });
    }
}

export const ChangeFilter = val => {
    return {
        type: CHANGE_FILTER,
        payload: val
    };
};
