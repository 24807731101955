import { createSelector } from 'reselect';
import mergeChangesWithAppointment from '../helpers/appointment-changes-merge';
import mergeChangesWithAppointmentForServiceProtokol from '../helpers/service-protokol-changes-merge';

const appointmentsSelector = state => state.tasks.appointments;
const endeteredDataSelector = state => state.tasks.enteredData;
const selectedAppSelector = state => state.tasks.selectedAppointmentId;
const selectedAppServiceIDSelector = state => state.tasks.selectedAppointmentServiceID;
const attachmentsSelector = state => state.tasks.attachments;
const notesSelector = state => state.tasks.notes;
const fotosSelector = state => state.tasks.fotos;

const getSelectedAppointment = (appointments,enteredData, UniqueID, IDService) => {

    const uID= parseInt(UniqueID)===-1?null:parseInt(UniqueID);
    

    let app = appointments.find(app => app.UniqueID === uID && app.IDService===IDService);
    if (!app) return {};
    const modifiedData = enteredData.find(c=>c.UniqueID===uID && c.IDService===IDService);
    if (!modifiedData) return app;
    
    return mergeChangesWithAppointment(app, modifiedData);
}



const SelectedAppSelector = createSelector(
    appointmentsSelector,
    endeteredDataSelector,
    selectedAppSelector,
    selectedAppServiceIDSelector,
    getSelectedAppointment
);

const getSelectedEnteredData = (appointments,enteredData, UniqueID, IDService) => {
    const uID= parseInt(UniqueID)===-1?null:parseInt(UniqueID);
    let app = appointments.find(app => app.IDService === parseInt(IDService) && app.UniqueID===uID);
    if (!app) return {};
    
    const modifiedData = enteredData.find(c=>c.IDService===app.IDService && c.UniqueID===app.UniqueID);    

    if (!modifiedData) return app;
    
    return mergeChangesWithAppointmentForServiceProtokol(app, modifiedData)
}

const EnteredDataForServicePDF = createSelector(
    appointmentsSelector,
    endeteredDataSelector,
    selectedAppSelector,  
    selectedAppServiceIDSelector,     
    getSelectedEnteredData
);

const getSelectedAppointmentAttachments = (attachments, IDService ) => {      
    return attachments.filter(c=> c.hasOwnProperty("ServiceID") ? c.ServiceID === parseInt(IDService) : c.IDService===parseInt(IDService)); //Izmjena za Loeffel (c.ServiceID), bug - nisu učitavani preuzeti attachmenti.
}
const SelectedAppAttachmentsSelector = createSelector(
    attachmentsSelector,
    selectedAppServiceIDSelector,
    getSelectedAppointmentAttachments
);

const getSelectedAppointmentNotes = (notes, IDService ) => {  
    return notes.filter(c=>c.ServiceID===parseInt(IDService));
}
const SelectedAppNotesSelector = createSelector(  
    notesSelector,    
    selectedAppServiceIDSelector,
    getSelectedAppointmentNotes
);

const getSelectedAppointmentPhotos = (photos, UniqueID, IDService ) => { 
    const uID= parseInt(UniqueID)===-1?null:parseInt(UniqueID);       
    return photos.filter(c=>c.IDService===IDService && c.UniqueID===uID);
}

const SelectedAppPhotosSelector = createSelector(    
    fotosSelector,
    selectedAppSelector,
    selectedAppServiceIDSelector,
    getSelectedAppointmentPhotos
);

export { SelectedAppSelector, SelectedAppAttachmentsSelector,SelectedAppNotesSelector,SelectedAppPhotosSelector, EnteredDataForServicePDF }
