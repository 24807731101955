import { combineReducers } from "redux";

import appReducer from "./AppReducer";
import syncReducer from "./SyncReducer";
import settingsReducer from "./SettingsReducer";
import tasksReducer from "./TasksReducer";
import backupReducer from "./BackupReducer";
import dayStatusReducer from "./DayStatusReducer";

export default combineReducers({
    app: appReducer,
    sync: syncReducer,
    settings: settingsReducer,
    tasks: tasksReducer,
    backup: backupReducer,
    dayStatus: dayStatusReducer
});
