import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';


export default class DownloadFileDialog extends Component {

  state = {
    fullWidth: true,
    maxWidth: 'xs',
  };

    render() {
        return (
            <Dialog
                fullWidth={this.state.fullWidth}
                maxWidth={this.state.maxWidth}
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Download File</DialogTitle>
                <DialogContent>
                  {(this.props.downloadInProgress === false) && (
                  <DialogContentText id="alert-dialog-description">
                    Would you like to download this file ?
                  </DialogContentText>
                  )}

                  {(this.props.downloadInProgress === true) && (
                    <div style={{textAlign:"center"}}>
                        <CircularProgress
                            color="secondary"
                        />
                        <DialogContentText id="alert-dialog-description">
                            Downloading...
                        </DialogContentText>
                    </div>
                  )}
                </DialogContent>
                {(this.props.downloadInProgress === false) && (
                  <DialogActions>
                  <Button onClick={this.props.handleClose} color="primary">
                    Nein
                  </Button>
                  <Button onClick={this.props.downloadFile} name="Start" color="primary" autoFocus>
                    Ja
                  </Button>
                </DialogActions>
                )}
            </Dialog>
        )
    }
}
