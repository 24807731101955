import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import virtualize from "react-swipeable-views-utils/lib/virtualize";
import bindKeyboard from "react-swipeable-views-utils/lib/bindKeyboard";
import { setTitle } from "../actions/AppActions";
import { SetShownDay } from "../actions/TaskActions";
import DayTasksMenck from "./menck/DayTasks";
import DayTasksLoeffel from "./loeffel/DayTasks";
import DayTasks from "./DayTasks";
import DayTasksOst from "./ost/DayTasks";
import {
    COMPANY_BROEMSE,
    COMPANY_EVERS,
    COMPANY_GROWE,
    COMPANY_OST,
    COMPANY_MENCK,
    COMPANY_LOEFFEL
} from "../helpers/constants";
import { ToastContainer } from "react-toastify";
import DayTasksBroemse from './broemse/DayTasks';

const EnhancedSwipeableViews = virtualize(bindKeyboard(SwipeableViews));

class SwipeableDays extends PureComponent {
    state = {
        shownDay: 10
    };

    componentDidMount() {
        this.setState({ shownDay: this.props.shownDay });
    }

    slideRenderer = params => {
        const { index, key } = params;
        // if(this.props.company === "MENCK") return <DayTasksMenck key={key} day={index} />;
        // return <DayTasks key={key} day={index} />;

        switch (this.props.application.company) {
            case COMPANY_BROEMSE:
                return <DayTasksBroemse key={key} day={index} />;
            case COMPANY_EVERS:
                return <DayTasks key={key} day={index} />;
            case COMPANY_OST:
                return <DayTasksOst key={key} day={index} />;
            case COMPANY_MENCK:
                return <DayTasksMenck key={key} day={index} />;
            case COMPANY_LOEFFEL:
                return <DayTasksLoeffel key={key} day={index} />;
            case COMPANY_GROWE:
                return <DayTasks key={key} day={index} />;
            default:
                return <DayTasks key={key} day={index} />;
        }
    };
    handleChangeIndex = index => {
        console.log("day", index);
        const fx = () => {
            this.props.SetShownDay(index);
        };
        setTimeout(fx, 400);

        this.setState({ shownDay: index });
    };

    render() {
        return (
            <div style={{ backgroundColor: "#ffffff" }}>
                <div style={{ marginTop: "56px" }}>
                    <EnhancedSwipeableViews
                        enableMouseEvents
                        slideCount={50}
                        overscanSlideAfter={1}
                        overscanSlideBefore={1}
                        index={this.state.shownDay}
                        onChangeIndex={e => {
                            this.handleChangeIndex(e);
                            console.log("chIndex");
                            this.props.appointments.map(a => {
                                return a;
                            });
                        }}
                        slideRenderer={this.slideRenderer}
                    />
                    <ToastContainer autoClose={8000} />
                </div>
            </div>
        );
    }
}

SwipeableDays.propTypes = {
    setTitle: PropTypes.func.isRequired,
    SetShownDay: PropTypes.func.isRequired,
    appointments: PropTypes.array.isRequired,
    settings: PropTypes.object.isRequired,
    shownDay: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
    appointments: state.tasks.appointments,
    settings: state.settings,
    shownDay: state.tasks.shownDay,
    application: state.app
});

export default connect(
    mapStateToProps,
    { setTitle, SetShownDay }
)(SwipeableDays);
