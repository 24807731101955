/**
 * Summary. (Merge changes from enteredValues to original appointment)
 *
 * @access     public
 *
 * @param {Appointment}   app
 *      Appointment from state.
 * @param {Object|Array} enteredData
 *      Single row of enteredData or Array of all enteredData rows. If Array is provided, the correct row will be found over IDService
 *
 * @return {Appointment}
 *      Original appointment modified with data from enteredData object.
 */

const mergeChangesWithAppointmentForServiceProtokol = (app, enteredData) =>{
    if (!app) return {};  //No appointment provided, return empty object
    if (!enteredData) return app;   //No modifications provided, return original appointment

    let ed = enteredData;

    if (Array.isArray(ed)) ed = enteredData.find(c=>c.IDService===app.IDService); //If provided enteredData is array, we look for needed enteredData for the same Service
    if (!ed) return app; //No modification found in array for this IDService, return original appointment.

    let appRet = Object.assign({},app);

    let duration = ed.Duration?ed.Duration:app.Duration;
    //Modify appointment with data from enteredData
    appRet.StartTime = ed.StartTime?ed.StartTime:app.StartDate;
    appRet.Started = ed.Started?1:0;
    appRet.Finished = ed.Finished?1:0;
    appRet.ErledigteArbeiten = ed.ErledigteArbeiten || '';
    appRet.SignatureKunde=ed.SignatureKunde;
    appRet.Duration=duration;
    appRet.AdditionalServiceNeeded=ed.AdditionalServiceNeeded?true:false;
    appRet.pdfFieldValues = ed.pdfFieldValues;
    appRet.pdf = ed.pdf;
    // appRet.ServiceNr = appRet.ServiceNr;
    // appRet.Komision = appRet.Komision;
    // appRet.Name = appRet.Name;

    appRet.PdfPruf = ed.PdfPruf;
    appRet.PdfService = ed.PdfService;
    // korišteno na formi za ServiceProtokol i PrufProtokol
    appRet.ServiceTyp = ed.ServiceTyp;
    appRet.Aufhanger = ed.Aufhanger;
    appRet.Ausgefuhrte = ed.Ausgefuhrte;
    appRet.Axialspiel = ed.Axialspiel;
    appRet.Bedienfehler = ed.Bedienfehler;
    appRet.Bedienungs = ed.Bedienungs;
    appRet.Benotigtes = ed.Benotigtes;
    appRet.Einbaurichtung = ed.Einbaurichtung;
    appRet.Einlauftrichter = ed.Einlauftrichter;
    appRet.Endstab = ed.Endstab;
    appRet.FehlerhafteMontage = ed.FehlerhafteMontage;
    appRet.FehlerhafteProgramierung = ed.FehlerhafteProgramierung;
    appRet.Festgestellte = ed.Festgestellte;
    appRet.Fliegengitter = ed.Fliegengitter;
    appRet.FotosGemacht = ed.FotosGemacht;

    appRet.FuhrungsschienenMontiert = ed.FuhrungsschienenMontiert;
    appRet.FunktionstestAnlage = ed.FunktionstestAnlage;
    appRet.Gurtwickler = ed.Gurtwickler;
    appRet.ISRollo = ed.ISRollo;
    appRet.IstGelocht = ed.IstGelocht;
    appRet.IstLange = ed.IstLange;
    appRet.IstMass = ed.IstMass;
    appRet.IstUngelocht = ed.IstUngelocht;

    appRet.LangeFuhrungsscienen = ed.LangeFuhrungsscienen;
    appRet.Masshaltigkeit = ed.Masshaltigkeit;
    appRet.MotorSteuerung = ed.MotorSteuerung;
    appRet.Panzer = ed.Panzer;
    appRet.ProfilstabeVerbaut = ed.ProfilstabeVerbaut;
    appRet.ServiceTyp = ed.ServiceTyp;
    appRet.SignatureKunde = ed.SignatureKunde;
    appRet.SollGelocht = ed.SollGelocht;

    appRet.SollLange = ed.SollLange;
    appRet.SollMass = ed.SollMass;
    appRet.SollUngelocht = ed.SollUngelocht;
    appRet.Sonstiges = ed.Sonstiges;
    appRet.Stabzahl = ed.Stabzahl;
    appRet.TerminNotwendig = ed.TerminNotwendig;
    appRet.UnzulassigeInstallation = ed.UnzulassigeInstallation;
    appRet.Wickeldurchmesser = ed.Wickeldurchmesser
    appRet.ZusatzlicheAngaben = ed.ZusatzlicheAngaben;
    appRet.ServicemonteurList = ed.ServicemonteurList;
    appRet.FuhrungsschienenLangeList = ed.FuhrungsschienenLangeList;
    appRet.StabzahlPanzerList = ed.StabzahlPanzerList;
    appRet.MasshaltigkeitElementeList = ed.MasshaltigkeitElementeList;

    appRet.PrintNotiz = ed.PrintNotiz?1:0;
    appRet.Eingang = ed.Eingang;

    return appRet;
}

export default mergeChangesWithAppointmentForServiceProtokol;