import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DoSync, ReportProgress } from "../../actions/SyncActions";
import { Button } from "@material-ui/core";
import notification from "../../helpers/toast_notification";

class SyncButton extends Component {

    DoSync = async () => {
        let settings = Object.assign(this.props.settings, {
            Company: this.props.company
        });
        //Provjera konekcije
        navigator.onLine === false
            ? notification(
                  "warning",
                  "Ihre Internet Geschwindigkeit ist nicht ausreichend , bitte synchronisieren Sie zu einem späteren Zeitpunkt!"
              )
            : this.props.DoSync(settings, this.props.ReportProgress);
    };

    render() {

        return (
            <div>
                <Button
                    color="secondary"
                    variant="contained"
                    size="small"
                    onClick={this.DoSync}
                    style={{ marginRight: "20px" }}
                >Sync
                </Button>
          </div>
        );
    }
}

SyncButton.propTypes = {
    settings: PropTypes.object.isRequired,
    DoSync: PropTypes.func.isRequired,
    company: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    settings: state.settings,
    sync: state.sync,
    company: state.app.company
});

export default connect(
    mapStateToProps,
    { DoSync, ReportProgress }
)(SyncButton);
