let lf = {
  shadows: Array(25).fill("none"),
  typography: {
      useNextVariants: true,
      color: "#444",
  },
  palette: {
      primary: {
          main: "#007A73",
          contrastText: "#FFFFFF",

      },
      secondary: {
          main: "#4D918C",
          contrastText: "#FFFFFF",
      }
  }
};
export default lf;
