import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Button } from "@material-ui/core";
import {
  DoBackupImport,
  ReportBackupProgress
} from "../actions/BackupActions";
import { setTitle } from "../actions/AppActions";

export class DbImport extends Component {
  componentDidMount() {
    this.props.setTitle("Import");
  }

  DoBackupImport = async () => {
    let settings = Object.assign(this.props.settings, {
      Company: this.props.company
  });
    this.props.DoBackupImport(
      settings,
      this.props.ReportBackupProgress
    );
  };

  render() {
    const style = {
      container: {
        height: "100vh",
        margin: "30px"
      },

      control: {
        position: "relative",
        top: "calc(40%)"
      }
    };

    return (
      <div style={style.container}>
        <div style={style.control}>
          {!this.props.backup.backupInProgress && (
            <div>
              <Button
                id="btnImport"
                color="primary"
                variant="contained"
                onClick={this.DoBackupImport}
              >
                Import
              </Button>
            </div>
          )}

          {this.props.backup.backupInProgress && (
            <div>
              <LinearProgress
                variant="determinate"
                value={this.props.backup.backupProgressValue}
              />
              <div>
                Import in progress <span id="spanProgressValue">{this.props.backup.backupProgressValue}%</span>
              </div>
              <br />
              <Button color="primary" variant="contained" disabled={true}>
                Import
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

DbImport.propTypes = {
  DoBackupImport: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  backup: state.backup,
  settings: state.settings,
  company: state.app.company
});

export default connect(
  mapStateToProps,
  { DoBackupImport, ReportBackupProgress, setTitle }
)(DbImport);
