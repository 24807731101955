import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
//import { SetPropertyValue, SavePropertyValue } from '../actions/taskviewActions';
import { setTitle } from "../actions/AppActions";
import { withRouter } from "react-router-dom";
import Fab from "@material-ui/core/Fab";
import AppBar from "@material-ui/core/AppBar";
import moment from "moment";
import "moment/locale/de";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { createStructuredSelector } from "reselect";
import {
  WatchIcon,
  FlagIcon,
  FinishIcon,
  NavigateIcon,
  PhoneIcon,
  Check
} from "../bundles/IconsBundle";
import { SavePropertyValue } from "../actions/TaskActions";
import StartWorkQuestionDialog from "./dialogs/StartWorkQuestionDialog";

import DateHelper from "../helpers/date-helper";
import makeGetDayAppointmentsSelector from "../selectors/TasksDailySelector";


const styles = {
  div: {
    display: "block",
    float: "left",
    textAlign: "left",
    paddingLeft: "10px",
    zIndex: 999
  },
  par: {
    height: 43,
    marginTop: 0
  },
  appbar: {
    top: 0,
    zIndex: 1000
  },
  card: {
    margin: "10px",
    boxShadow: "1px 1px 1px 1px #AAA",
    borderRadius: "0px",
    textAlign: "left",
    color: "#444"
  },
  cont: {
    overflowY: "scroll",
    height: "calc(100vh - 100px)",
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0
  }
};

export class DayTasks extends Component {
  state = {
    appointments: [],
    date: new Date(),
    questionDialogOpen: false,
    questionFinishDialogOpen: false,
    dialogApp: {},
    dialogImages: []
  };

  getDateString = () => {
    moment.locale("de");
    let day = this.props.day;
    var d = new DateHelper().getDateByIndex(day);
    return d.format("MMMM DD, YYYY");
  };

  getDateTimeString = date => {
    moment.locale("de");
    return moment(date).format("DD. MMMM YYYY HH:mm");
  };

  getServiceTitle = app => {
    return `${app.ServiceNr || ""} (${app.AbNr || ""}) - ${app.ekName || ""}`;
  };

  getBackgroundColor = app => {
    if (app.Tourart === 0) return { background: "#ff9800" };
    if (app.Finished) return { background: "#60d81a" };
    if (app.Started) return { background: "#FFEB3B" };
    return { background: "white" };
  };

  getDayName = () => {
    let day = this.props.day;
    var d = new DateHelper().getDateByIndex(day);
    moment.locale("de");
    return d.format("dddd");
  };

  SetNeededTimeAndShowAppointment = async (val) => {
    const duration = moment.duration(moment(Date.now()).diff(moment(val.StartTime)));

    await this.props.SavePropertyValue(val,'Duration', Math.round(duration.asMinutes()));
    this.props.history.push("/task/" + (val.UniqueID==null?-1:val.UniqueID) + "/" + val.IDService);
  }

  ShowAppointment = val => {
    this.props.history.push("/task/" + (val.UniqueID==null?-1:val.UniqueID) + "/" + val.IDService );
  };

  header = () => {
    return (
      <AppBar position="static" style={styles.appbar}>
        <div style={styles.div}>{this.getDayName()}</div>
        <div style={styles.div}>{this.getDateString()}</div>
      </AppBar>
    );
  };

  zeroTasksCheck = () => {
    if (this.props.appointments.length === 0) return <div>0 Auftrage</div>;
    return null;
  };

  StartWorkAsk = app => {
    this.setState({ ...this.state, questionDialogOpen: true, dialogApp: app });
  };

  handleCloseDialogs = () => {
    this.setState({
      ...this.state,
      questionDialogOpen: false,
      questionFinishDialogOpen: false
    });
  };

  startWorkAccepted = async () => {
    const dt = new Date();
    dt.setSeconds(0, 0);
    const app = this.state.dialogApp;
    await this.props.SavePropertyValue(app, "Started", 1);
    await this.props.SavePropertyValue(app, "StartTime", dt);

    this.setState({
      ...this.state,
      dialogApp: null,
      questionDialogOpen: false
    });
  };

  /*FinishWorkAsk = async app => {
    this.props.history.push("/task/" + app.id);
  };*/

  render() {
    //console.log("Notes :", this.props.appointments.notes);
    return (
      <div style={styles.back}>
        <div style={styles.par}>{this.header()}</div>
        <div style={styles.cont}>
          {this.zeroTasksCheck()}
          {this.props.appointments.map(val => (
            <Card
              key={val.id}
              style={Object.assign(this.getBackgroundColor(val), styles.card)}
              raised
            >
              <table style={{ width: "100%", }}>
                <tbody>
                  <tr>
                    <td>
                      <CardContent
                        onClick={() => {
                          this.ShowAppointment(val);
                        }}
                      >
                        <div>
                          <b>{this.getServiceTitle(val)}</b>
                        </div>
                        <Typography component="p">
                          {val.ekPlz} - {val.ekOrt}
                        </Typography>
                        <Typography component="p">{val.ekStrasse}</Typography>

                        <div style={{ lineHeight: "24px" }}>
                          <span
                            style={{
                              verticalAlign: "middle",
                              display: "inline-block",
                              height: "24px"
                            }}
                          >
                            <WatchIcon />
                          </span>
                          <span
                            style={{
                              verticalAlign: "middle",
                              display: "inline-block",
                              height: "24px",
                              fontSize: "90%",
                              marginLeft: "10px",
                            }}
                          >
                            {this.getDateTimeString(val.StartDate)}
                          </span>
                        </div>
                        {val.Bauschlussel && <Typography component="p">Bauschlüssel <Check style={{verticalAlign: "bottom"}}/></Typography>}
                      </CardContent>
                    </td>
                    <td style={{ overflow: "hidden", width: "30px" }}>
                      <div>
                        {val.Started === 1 && val.Finished !== 1 && (
                          <Fab
                            size="small"
                            id="fabFinish"
                            aria-label="Dest"
                            color="secondary"
                            onClick={() => {
                              this.SetNeededTimeAndShowAppointment(val);
                            }}
                          >
                            <FinishIcon fontSize="small" />
                          </Fab>
                        )}
                        {val.Started !== 1 && val.Finished !== 1 && (
                          <Fab
                            size="small"
                            aria-label="Dest"
                            id="fabStart"
                            color="secondary"
                            onClick={() => {
                              this.StartWorkAsk(val);
                            }}
                          >
                            <FlagIcon fontSize="small" />
                          </Fab>
                        )}
                      </div>
                      <div>
                        <Fab
                          size="small"
                          aria-label="Nav"
                          color="secondary"
                          href={
                            "google.navigation:q=" +
                            val.ekStrasse +
                            " " +
                            val.ekPlz +
                            " " +
                            val.ekOrt
                          }
                        >
                          <NavigateIcon fontSize="small" />
                        </Fab>
                      </div>
                      <div>
                        <Fab
                          size="small"
                          aria-label="Tel"
                          href={"tel:" + val.ekTelefon1}
                          color="secondary"
                        >
                          <PhoneIcon fontSize="small" />
                        </Fab>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
          ))}
        </div>

        <StartWorkQuestionDialog
          open={this.state.questionDialogOpen}
          handleClose={this.handleCloseDialogs}
          startWorkAccepted={this.startWorkAccepted}
        />
      </div>
    );
  }
}

DayTasks.propTypes = {
  appointments: PropTypes.array.isRequired
};

const mapStateToProps = createStructuredSelector({
  appointments: makeGetDayAppointmentsSelector()
});

export default withRouter(
  connect(
    mapStateToProps,
    { setTitle, SavePropertyValue }
  )(DayTasks)
);
