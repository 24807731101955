import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { SetPropertyValue, SavePropertyValue } from '../actions/taskviewActions';
import { setTitle } from "../../actions/AppActions";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import moment from "moment";
import "moment/locale/de";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { createStructuredSelector } from "reselect";
import {
  WatchIcon,
  PhoneIcon,
  PhonelinkRing,
  Stop,
  PlayArrow,
  SwapHoriz
} from "../../bundles/IconsBundle";
import { SavePropertyValue, SetPropertyValue, SaveData } from "../../actions/TaskActions";
import DateHelper from "../../helpers/date-helper";
import makeGetDayAppointmentsSelector from "../../selectors/TasksDailySelector";
import StartWorkQuestionDialog from "../dialogs/menck/StartWorkQuestionDialog";
import StartDialogDatePicker from '../dialogs/menck/StartDialogDatePicker';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Media from 'react-media';

import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  DateTimePicker
} from "@material-ui/pickers";
import SyncButton from './SyncButton';
import { checkIfAppointmentDurationIsInOneDay } from '../../helpers/utils';
import notification from "../../helpers/toast_notification";

const styles = {
  div: {
    display: "block",
    float: "left",
    textAlign: "left",
    paddingLeft: "10px",
    zIndex: 999
  },
  par: {
    height: 43,
    marginTop: 0
  },
  appbar: {
    top: 0,
    zIndex: 1000
  },
  card: {
    margin: "10px",
    boxShadow: "1px 1px 1px 1px #AAA",
    borderRadius: "0px",
    textAlign: "left",
    color: "#444"
  },
  cont: {
    overflowY: "scroll",
    height: "calc(100vh - 100px)",
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0
  },
  greenBackground: {
    background: 'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3MCIgaGVpZ2h0PSI3MCI+CjxyZWN0IHdpZHRoPSI3MCIgaGVpZ2h0PSI3MCIgZmlsbD0iI2FkZTQ3ZCI+PC9yZWN0Pgo8ZyB0cmFuc2Zvcm09InJvdGF0ZSg0NSkiPgo8cmVjdCB3aWR0aD0iOTkiIGhlaWdodD0iMjUiIGZpbGw9IiNhZmU2N2YiPjwvcmVjdD4KPHJlY3QgeT0iLTUwIiB3aWR0aD0iOTkiIGhlaWdodD0iMjUiIGZpbGw9IiNhZmU2N2YiPjwvcmVjdD4KPC9nPgo8L3N2Zz4=")'
  },
  orangeBackground: {
    background: 'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3MCIgaGVpZ2h0PSI3MCI+CjxyZWN0IHdpZHRoPSI3MCIgaGVpZ2h0PSI3MCIgZmlsbD0iI2ZhZDUzMiI+PC9yZWN0Pgo8ZyB0cmFuc2Zvcm09InJvdGF0ZSg0NSkiPgo8cmVjdCB3aWR0aD0iOTkiIGhlaWdodD0iMjUiIGZpbGw9IiNmN2QxMmUiPjwvcmVjdD4KPHJlY3QgeT0iLTUwIiB3aWR0aD0iOTkiIGhlaWdodD0iMjUiIGZpbGw9IiNmN2QxMmUiPjwvcmVjdD4KPC9nPgo8L3N2Zz4=")'
  },
  grayBackground: {
    background: 'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3MCIgaGVpZ2h0PSI3MCI+CjxyZWN0IHdpZHRoPSI3MCIgaGVpZ2h0PSI3MCIgZmlsbD0iI2Q3ZDdkNyI+PC9yZWN0Pgo8ZyB0cmFuc2Zvcm09InJvdGF0ZSg0NSkiPgo8cmVjdCB3aWR0aD0iOTkiIGhlaWdodD0iMjUiIGZpbGw9IiNkNWQ1ZDUiPjwvcmVjdD4KPHJlY3QgeT0iLTUwIiB3aWR0aD0iOTkiIGhlaWdodD0iMjUiIGZpbGw9IiNkNWQ1ZDUiPjwvcmVjdD4KPC9nPgo8L3N2Zz4=")'
  },
  syncButton: {
    color: "default",
  },
};

export class DayTasks extends Component {
  state = {
    appointments: [],
    date: new Date(),
    questionDialogOpen: false,
    questionFinishDialogOpen: false,
    dialogApp: {},
    dialogImages: [],
    selectDateTimeDialogOpen: false,
    type: 1,
    confirmationDialogOpen: false
  };

  getDateString = () => {
    moment.locale("de");
    let day = this.props.day;
    var d = new DateHelper().getDateByIndex(day);
    return d.format("MMMM DD, YYYY");
  };

  getDateTimeString = date => {
    moment.locale("de");
    return moment(date).format("dddd, DD.MM.YYYY HH:mm");
  };

  getServiceTitle = app => {
    return `${app.ServiceNr || ""} - ${app.ekName || ""}`;
  };

  getBackgroundColor = app => {
    if (app.Finished) return { background: styles.greenBackground.background };
    if (app.Started) return { background: styles.orangeBackground.background };
    return { background: styles.grayBackground.background };
  };

  getDayName = () => {
    let day = this.props.day;
    var d = new DateHelper().getDateByIndex(day);
    moment.locale("de");
    return d.format("dddd");
  };

  ShowAppointment = val => {
    this.props.history.push("/task/" + (val.UniqueID == null ? -1 : val.UniqueID) + "/" + val.IDService);
  };

  header = () => {
    return (
      <AppBar position="static" style={styles.appbar}>
        <Grid container spacing={0} direction="row" justify="space-evenly" alignItems="flex-start">
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <div style={styles.div}>{this.getDayName()}</div>
            <br />
            <div style={styles.div}>{this.getDateString()}</div>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <div style={{ textAlign: "right" }}>
              <SyncButton />
            </div>
          </Grid>
        </Grid>
        <div>

        </div>
      </AppBar>
    );
  };

  zeroTasksCheck = () => {
    if (this.props.appointments.length === 0) return <div>0 Auftrage</div>;
    return null;
  };

  ToggleWorkAsk = (app, name) => {
    this.setState({
      ...this.state,
      questionDialogOpen: true,
      dialogApp: app,
      type: name === "Start" ? 1 : 0
    });
  };

  ToggleConfirmationAsk = (app, name) => {
    this.setState({
      ...this.state,
      confirmationDialogOpen: true,
      dialogAppConfirmation: app
    });
  };

  handleCloseDialogs = () => {
    this.setState({
      ...this.state,
      questionDialogOpen: false,
      questionFinishDialogOpen: false,
      selectDateTimeDialogOpen: false,
      type: 1
    });
  };

  handleCloseDialogsConfirmation = () => {
    this.setState({
      ...this.state,
      confirmationDialogOpen: false,
      type: 1
    });
  };

  // saveData = async () => {
  //   const data = {
  //     Finished: this.props.app.Finished,
  //     StartTime: this.props.app.StartTime,
  //     Duration: this.props.app.Duration,
  //     ErledigteArbeiten: this.props.app.ErledigteArbeiten
  //   };
  //   await this.props.SaveData(this.props.app.IDService, data);
  // };

  toggleWorkAccepted = async (e) => {
    let name = e.currentTarget.name;
    const dt = new Date();
    dt.setSeconds(0, 0);
    const app = this.state.dialogApp;
    if (name === "Start") {
      await this.props.SavePropertyValue(app, "Started", 1);
      await this.props.SavePropertyValue(app, "StartTime", dt);
      await this.props.SavePropertyValue(app, "StartDate", new Date(app.StartDate));
      await this.props.SavePropertyValue(app, "UniqueID", app.UniqueID);

      await this.setState({
        ...this.state,
        dialogApp: null,
        questionDialogOpen: false
      });
    } else {
      await this.props.SavePropertyValue(app, "Finished", 1);

      const duration = moment.duration(
        moment(Date.now()).diff(moment(app.StartTime))
      );
      await this.props.SavePropertyValue(app, "Duration", Math.round(duration.asMinutes()));
      // let startDate = new Date(app.StartDate);
      // let endDate = startDate.setMinutes(startDate.getMinutes() + duration);
      // await this.props.SavePropertyValue(app.IDService, "EndDate", endDate);
      await this.props.SavePropertyValue(app, "UniqueID", app.UniqueID);

      this.setState({
        ...this.state,
        dialogApp: null,
        questionDialogOpen: false
      });
    }
  };

  openPicker = e => {
    // do not pass Event for default pickers
    this.picker.open(e);

    this.setState({
      ...this.state,
      confirmationDialogOpen: false
    });
  };

  selectDateTimeDialog = app => event => {
    this.setState({ ...this.state, selectDateTimeDialogOpen: true, dialogApp: app });
  };

  handleChipClick = (event) => {
    console.log("event:", event);
    return window.location = "tel:" + event;
  }

  saveVerschiebenDate = app => e => {
    //console.log("Snimanje novog datuma");
    let newDateToSet = null;
    if (e !== null) newDateToSet = e._d;
    let data = {
      StartTime: newDateToSet,
      StartDate: newDateToSet,
      Duration: app.GeplanteServicezeit,
      UniqueID: app.UniqueID,
      IsMobileEdited: true
    }

    if (checkIfAppointmentDurationIsInOneDay(data.StartDate, data.Duration)) {
      this.props.SaveData(app, data);
    } else {
      notification(
        "nonEditable",
        "Die Dauer des Termins beträgt mehr als einen Tag. Bitte wählen Sie eine andere Startzeit für den Termin."
      );
    }

    
  }

  // renderVerschiebenButton = (props) => (
  //   <Button
  //     variant="contained"
  //     size="medium"
  //     color="secondary"
  //     onClick={props.onClick}
  //     id={props.id}
  //     value={props.value}
  //     disabled={props.disabled}
  //     {...props.inputProps}
  //   ><SwapHoriz size="small" style={{ marginRight: "3px" }} />Verschieben</Button>
  // );



  /*FinishWorkAsk = async app => {
    this.props.history.push("/task/" + app.id);
  };*/


  render() {
    return <div style={styles.back}>
      <div style={styles.par}>{this.header()}</div>
      <div style={styles.cont}>
        {this.zeroTasksCheck()}
        {this.props.appointments.map(val => (
          <Card
            key={val.id}
            style={styles.card}
            raised
          >
            <Grid container spacing={0} direction="row" justify="space-evenly" alignItems="flex-start">
              <Grid container style={Object.assign(this.getBackgroundColor(val))}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <div>
                    <CardContent
                      onClick={() => {
                        this.ShowAppointment(val);
                      }}
                      style={{ paddingBottom: "5px", paddingTop: "10px" }}
                    >
                      <div
                        style={{ color: val.Tourart !== 1 ? "red" : "" }}
                      >
                        <b>{this.getServiceTitle(val)}</b>
                      </div>
                      <div>
                        <Typography component="p">
                          {val.ekPLZ} - {val.ekOrt}
                        </Typography>
                        <div>
                          <Typography component="p">
                            {val.Strasse}
                          </Typography>
                        </div>
                      </div>                    
                      {val.StartDate !== null && (
                        <div
                          style={{ lineHeight: "24px" }}
                        >
                          <span
                            style={{
                              verticalAlign: "middle",
                              display: "inline-block",
                              height: "24px"
                            }}
                          >
                            <Typography component="p">
                              {" "}
                              <WatchIcon />
                            </Typography>
                          </span>
                          <span
                            style={{
                              verticalAlign: "middle",
                              display: "inline-block",
                              height: "24px",
                              fontSize: "90%",
                              marginLeft: "10px"
                            }}
                          >
                            <Typography component="p">
                              {" "}
                              {this.getDateTimeString(val.StartDate)}
                            </Typography>
                          </span>
                        </div>
                      )}
                      {(val.BeraterInitials !== null && val.BeraterInitials.length > 0) && (
                        <div>
                          <Typography component="p">
                            Mit: {val.BeraterInitials.join(", ")}
                          </Typography>
                        </div>
                      )}
                    </CardContent>
                  </div>
                </Grid>
                {((val.ekTel1 !== null && val.ekTel1 !== "") || (val.ekTel2 !== null && val.ekTel2 !== "")) && (
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Media query={{ maxWidth: 599 }}>
                      {matches => matches ? (
                        <div style={{ margin: "5px", marginLeft: "10px", textAlign: "left" }}>
                          {(val.ekTel1 !== null && val.ekTel1 !== "") && (
                            <Chip
                              style={{ marginTop: "5px", padding: "0 5px" }}
                              clickable
                              icon={<PhoneIcon />}
                              label={val.ekTel1}
                              onClick={() => { this.handleChipClick(val.ekTel1) }}
                            />
                          )}
                          <br />
                          {(val.ekTel2 !== null && val.ekTel2 !== "") && (
                            <Chip
                              style={{ marginTop: "5px", padding: "0 5px" }}
                              clickable
                              icon={<PhonelinkRing />}
                              label={val.ekTel2}
                              onClick={() => { this.handleChipClick(val.ekTel2) }}
                            />
                          )}
                        </div>
                      ) : (
                          <div style={{ margin: "20px", marginLeft: "20px", textAlign: "right" }}>
                            {(val.ekTel1 !== null && val.ekTel1 !== "") && (
                              <Chip
                                style={{ marginTop: "5px", padding: "0 5px" }}
                                clickable
                                icon={<PhoneIcon />}
                                label={val.ekTel1}
                                onClick={() => { this.handleChipClick(val.ekTel1) }}
                              />
                            )}
                            <br />
                            {(val.ekTel2 !== null && val.ekTel2 !== "") && (
                              <Chip
                                style={{ marginTop: "5px", padding: "0 5px" }}
                                icon={<PhonelinkRing />}
                                clickable
                                label={val.ekTel2}
                                onClick={() => { this.handleChipClick(val.ekTel2) }}
                              />
                            )}
                          </div>
                        )}
                    </Media>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {val.ServiceInfo !== null && (
                  <div>
                    <CardContent
                      onClick={() => {
                        this.ShowAppointment(val);
                      }}
                      style={{ paddingBottom: "10px", paddingTop: "10px" }}
                    >
                      <div>
                        <Typography component="p">
                          {val.ServiceInfo}
                        </Typography>
                      </div>
                    </CardContent>
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={7} md={7} lg={7}>
                <Media query={{ maxWidth: 630 }}>
                  {matches => matches ? (
                    <div style={{ marginLeft: "10px", marginBottom: "5px", marginTop: "5px" }}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DateTimePicker
                          // TextFieldComponent={this.renderVerschiebenButton}
                          style={{ display: "none" }}
                          disablePast={true}
                          shouldDisableDate={date => new DateHelper().disableWeekends(date)}
                          ampm={false}
                          onChange={this.saveVerschiebenDate(val)}
                          ref={node => {
                            this.picker = node;
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      <Button
                        variant="contained"
                        size="medium"
                        color="secondary"
                        aria-label="Verschieben"
                        id="verschieben"
                        style={{ marginRight: "5px" }}
                        onClick={() => {
                          this.ToggleConfirmationAsk(val, "StartDialog");
                        }}
                        disabled={
                          val.Started === 1 || val.Finished === 1
                        }
                      ><SwapHoriz size="small" style={{ marginRight: "3px" }} />Verschieben</Button>
                    </div>
                  ) : (
                      <div style={{ marginLeft: "10px", marginBottom: "5px", marginTop: "5px" }}>
                        <MuiPickersUtilsProvider utils={MomentUtils} >
                          <DateTimePicker
                            style={{ display: "none" }}
                            shouldDisableDate={date => new DateHelper().disableWeekends(date)}
                            disablePast={true}
                            ampm={false}
                            onChange={this.saveVerschiebenDate(val)}
                            ref={node => {
                              this.picker = node;
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        <Button
                          variant="contained"
                          size="medium"
                          color="secondary"
                          aria-label="Verschieben"
                          id="verschieben"
                          style={{ marginRight: "5px" }}
                          onClick={() => {
                            this.ToggleConfirmationAsk(val, "StartDialog");
                          }}
                          disabled={
                            val.Started === 1 || val.Finished === 1
                          }
                        ><SwapHoriz size="small" style={{ marginRight: "3px" }} />Verschieben</Button>
                        {/* <Button
                          variant="contained"
                          size="medium"
                          color="secondary"
                          aria-label="Verschieben"
                          id="verschieben"
                          style={{ marginRight: "5px" }}
                          onClick={this.selectDateTimeDialog(val)}
                        >
                          <SwapHoriz size="small" style={{ marginRight: "3px" }} />Verschieben
                      </Button> */}
                      </div>
                    )}

                </Media>
              </Grid>
              <Grid item xs={12} sm={5} md={5} lg={5}>
                <Media query={{ maxWidth: 599 }}>
                  {matches => matches ? (
                    <div style={{ marginRight: "10px", marginBottom: "5px", marginLeft: "10px", textAlign: "left" }}>
                      <Button
                        variant="contained"
                        size="medium"
                        id="fabFinish"
                        aria-label="Dest"
                        name="StartTime"
                        color="secondary"
                        onClick={() => {
                          this.ToggleWorkAsk(val, "Start");
                        }}
                        disabled={val.Started === 1 || val.UniqueID == null || !(new DateHelper().disableStart(val.StartDate))}
                        style={{
                          backgroundColor:
                            val.Started === 1 || val.Finished === 1 || val.UniqueID == null || !(new DateHelper().disableStart(val.StartDate))
                              ? "#bdbdbd"
                              : "#689f38",
                          color: "white",
                          marginRight: "5px"
                        }}
                      >
                        <PlayArrow size="small" style={{ marginRight: "3px" }} />Start
                      </Button>
                      <Button
                        variant="contained"
                        size="medium"
                        aria-label="Dest"
                        name="Finished"
                        id="fabStart"
                        color="secondary"
                        onClick={() => {
                          this.ToggleWorkAsk(val, "End");
                        }}
                        disabled={
                          val.Started !== 1 || val.Started === undefined || val.Finished === 1
                        }
                        style={{
                          backgroundColor:
                            val.Started !== 1 || val.Started === undefined || val.Finished === 1
                              ? "#bdbdbd"
                              : "#e64a19",
                          color: "white",
                        }}
                      ><Stop size="small" style={{ marginRight: "3px" }} /> Stop
                      </Button>
                    </div>

                  ) : (
                      <div style={{ marginRight: "10px", marginBottom: "5px", marginLeft: "10px", marginTop: "5px", textAlign: "right" }}>
                        <Button
                          variant="contained"
                          size="medium"
                          id="fabFinish"
                          aria-label="Dest"
                          name="StartTime"
                          color="secondary"
                          onClick={() => {
                            this.ToggleWorkAsk(val, "Start");
                          }}
                          disabled={val.Started === 1 || val.UniqueID == null || !(new DateHelper().disableStart(val.StartDate))}
                          style={{
                            backgroundColor:
                              val.Started === 1 || val.Finished === 1 || val.UniqueID == null || !(new DateHelper().disableStart(val.StartDate))
                                ? "#bdbdbd"
                                : "#689f38",
                            color: "white",
                            marginRight: "5px"
                          }}
                        >
                          <PlayArrow size="small" style={{ marginRight: "3px" }} />Start
                        </Button>
                        <Button
                          variant="contained"
                          size="medium"
                          aria-label="Dest"
                          name="Finished"
                          id="fabStart"
                          color="secondary"
                          onClick={() => {
                            this.ToggleWorkAsk(val, "End");
                          }}
                          disabled={
                            val.Started !== 1 || val.Started === undefined || val.Finished === 1
                          }
                          style={{
                            backgroundColor:
                              val.Started !== 1 || val.Started === undefined || val.Finished === 1
                                ? "#bdbdbd"
                                : "#e64a19",
                            color: "white",
                          }}
                        ><Stop size="small" style={{ marginRight: "3px" }} /> Stop
                        </Button>
                      </div>
                    )}
                </Media>
              </Grid>
            </Grid>
          </Card>
        ))}
      </div>

      <StartDialogDatePicker open={this.state.confirmationDialogOpen} type={this.state.type} handleClose={this.handleCloseDialogsConfirmation} openPicker={this.openPicker} />

      <StartWorkQuestionDialog open={this.state.questionDialogOpen} type={this.state.type} handleClose={this.handleCloseDialogs} toggleWorkAccepted={this.toggleWorkAccepted} />

    </div>;
  }
}

DayTasks.propTypes = {
  appointments: PropTypes.array.isRequired
};

const mapStateToProps = createStructuredSelector({
  appointments: makeGetDayAppointmentsSelector()

});

export default withRouter(
  connect(
    mapStateToProps,
    { setTitle, SavePropertyValue, SetPropertyValue, SaveData }
  )(DayTasks)
);
