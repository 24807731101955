import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import "moment/locale/de";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import {
    CloseIcon,
    DeleteIcon,
    CalendarIcon,
    TimeIcon,
    KommissionIcon,
    PhoneIcon,
    AttachmentIcon,
    RefreshIcon,
    PersonIcon,
    WorkIcon,
    ProjectIcon,
    WatchIcon,
    WatchCheckedIcon
} from "../../bundles/IconsBundle";
import convertFile from "../extensions/FileConverter";
import TakePhoto from "../extensions/TakePhoto";
import MessageBox from "../dialogs/MessageBox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Scrollbars } from 'react-custom-scrollbars';

import { setTitle } from "../../actions/AppActions";
import {
    SelectAppointmentByID,
    SetPropertyValue,
    SavePropertyValue,
    SaveData,
    FotoAdd,
    FotoRemove,
    SaveDataFolgetermin,
    DownloadFileManually
} from "../../actions/TaskActions";
import {
    SelectedAppSelector,
    SelectedAppAttachmentsSelector,
    SelectedAppNotesSelector,
    SelectedAppPhotosSelector
} from "../../selectors/SelectedAppointmentSelector";
import StartWorkQuestionDialog from "../dialogs/menck/StartWorkQuestionDialog";
import StartDialogDatePicker from '../dialogs/menck/StartDialogDatePicker';
import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    DateTimePicker
} from "@material-ui/pickers";
import DateHelper from "../../helpers/date-helper";
import notification from "../../helpers/toast_notification";
import { ToastContainer } from "react-toastify";
import DownloadFileDialog from "../dialogs/menck/DownloadFileDialog";
import { checkIfAppointmentDurationIsInOneDay } from '../../helpers/utils';

const styles = {
    container: {
        textAlign: "left",
        marginTop: "90px",
        marginLeft: "20px",
        marginRight: "20px",
    },
    notiz: {
        wordBreak: 'break-all',
        marginRight: '15px',
    },
    tablecell: {
        paddingRight: '15px'
    }
};

class TaskView extends Component {
    state = {
        dialog: false,
        TakePhotoOpen: false,
        images: this.props.images || [],
        _deleteImageId: -1,
        _deleteImageMessageOpen: false,
        questionDialogOpen: false,
        questionFinishDialogOpen: false,
        dialogApp: {},
        dialogImages: [],
        selectDateTimeDialogOpen: false,
        type: 1,
        confirmationDialogOpen: false,
        downloadFileDialogOpen: false,
        fileNameForDownload: "",
        fileExtForDownload : "",
        fileIdForDownload: -1,
        downloadInProgress: false
    };

    file_input = [];

    componentDidMount() {
        moment.locale("de");
        this.props.setTitle("ServiceApp");
        this.props.SelectAppointmentByID(this.props.match.params.id,this.props.match.params.service);
        if(this.props.match.params.id === "-1") this.showInfoAboutSync();

    }

    _returnDescriptionData = data => {
        return JSON.parse(data !== undefined && data !== "" ? data.toString() : "[]");
    };

    materialTypeToName = item => {
        var material = "";
        if (item.MaterialType === 0) material = "PVC_Fenster";
        if (item.MaterialType === 1) material = "PVC_Hausturen";
        if (item.MaterialType === 2) material = "Rolladen";
        if (item.MaterialType === 3) material = "ALU_Hausturen";
        if (item.MaterialType === 4) material = "ALU_Fenster";
        if (item.MaterialType === 5) material = "HST";
        return material;
    };

    renderTrackHorizontal = () => {
        return false;
    }

    handleClose = () => {
        this.props.history.goBack();
    };

    disableCheckBox = (item) => {
        if (item === 0) return false;
        else return true;
    }

    fotoMachen = () => {
        this.setState({ TakePhotoOpen: true, images: this.state.images });
    };

    closeTakePhoto = () => {
        this.setState({ TakePhotoOpen: false });
    };

    handleTakeScreenshot = src => {
        this.props.FotoAdd(this.props.app, src);
        var st = this.state;
        //st.images.push(src);
        st.TakePhotoOpen = false;
        this.setState(st);
    };

    removeImage = idToRemove => {
        var st = this.state;
        st._deleteImageId = idToRemove;
        st._deleteImageMessageOpen = true;
        this.setState(st);
    };

    onRemoveImage = () => {
        var st = this.state;
        //st.images = this.state.images.filter((item,index) => index !== this.state._deleteImageId)
        this.props.FotoRemove(st._deleteImageId);
        st._deleteImageId = -1;
        st._deleteImageMessageOpen = false;
        this.setState(st);
    };

    handleCloseMessage = () => {
        var st = this.state;
        st._deleteImageMessageOpen = false;
        this.setState(st);
    };

    openDocument = att => {
        if(att.Attachment != null){
            convertFile(att.FileName, att.Extension, att.Attachment);
        } else {
            this.setState({...this.state,
                downloadFileDialogOpen: true,
                fileNameForDownload: att.FileName,
                fileExtForDownload: att.Extension,
                fileIdForDownload: att.id
             });
        }

    };

    ShowPDF = att => {
        convertFile("Bericht", ".pdf", att);
    };

    materialUndZeit = () => {
        this.props.history.push(
            "/material/" + this.props.match.params.id+ "/" + this.props.match.params.service + "/edit"
        );
        this.setState({ ...this.state, dialog: true });
    }

    signDialog = () => {
        this.props.history.push(
            "/tasksign/" + this.props.match.params.id + "/" + this.props.match.params.service+ "/edit"
        );
        this.setState({ ...this.state, dialog: true });
    };

    SaveData = async () => {
        const data = {
            Finished: this.props.app.Finished,
            StartTime: this.props.app.StartTime,
            Duration: this.props.app.Duration,
            ErledigteArbeiten: this.props.app.ErledigteArbeiten,
        };
        await this.props.SaveData(this.props.app, data);
    };

    openPicker = e => {
        // do not pass Event for default pickers
        this.picker.open(e);

        this.setState({
            ...this.state,
            confirmationDialogOpen: false
        });
    };

    ToggleWorkAsk = (app, name) => {
        this.setState({
            ...this.state,
            questionDialogOpen: true,
            dialogApp: app,
            type: name === "Start" ? 1 : 0
        });
    };

    ToggleConfirmationAsk = (app, name) => {
        this.setState({
            ...this.state,
            confirmationDialogOpen: true,
            dialogAppConfirmation: app
        });
    };

    handleCloseDialogs = () => {
        this.setState({
            ...this.state,
            questionDialogOpen: false,
            questionFinishDialogOpen: false,
            selectDateTimeDialogOpen: false,
            downloadFileDialogOpen: false,
            type: 1,
            downloadInProgress: false
        });
    };

    handleCloseDialogsConfirmation = () => {
        this.setState({
            ...this.state,
            confirmationDialogOpen: false,
            type: 1
        });
    };

    toggleWorkAccepted = async (e) => {
        let name = e.currentTarget.name;
        const dt = new Date();
        dt.setSeconds(0, 0);
        const app = this.state.dialogApp;
        if (name === "Start") {
            await this.props.SavePropertyValue(app, "Started", 1);
            await this.props.SavePropertyValue(app, "StartTime", dt);
            let setStartDate = new Date(this.state.dialogApp.StartDate);
            await this.props.SavePropertyValue(app, "StartDate", setStartDate);
            await this.props.SavePropertyValue(app, "UniqueID", app.UniqueID);

            await this.setState({
                ...this.state,
                dialogApp: null,
                questionDialogOpen: false
            });
        } else {
            await this.props.SavePropertyValue(app, "Finished", 1);

            const duration = moment.duration(
                moment(Date.now()).diff(moment(app.StartTime))
            );
            await this.props.SavePropertyValue(app, "Duration", Math.round(duration.asMinutes()));
            await this.props.SavePropertyValue(app, "EndDate", dt);
            await this.props.SavePropertyValue(app, "UniqueID", app.UniqueID);

            await this.setState({
                ...this.state,
                dialogApp: null,
                questionDialogOpen: false
            });
        }
    };

    handleChange = async (e, checked) => {
        console.log(this.props.app.IDService);
        if (e._d) {
            await this.props.SavePropertyValue(
                this.props.app,
                "StartTime",
                e._d
            );
        } else {
            if (e.target.name === "Finished") {
                await this.props.SetPropertyValue(
                    this.props.app,
                    e.target.name,
                    checked ? 1 : 0
                );
                const duration = moment.duration(
                    moment(Date.now()).diff(moment(this.props.app.StartTime))
                );
                await this.props.SetPropertyValue(
                    this.props.app,
                    "Duration",
                    Math.round(duration.asMinutes())
                );
                this.SaveData();
            } else
                await this.props.SetPropertyValue(
                    this.props.app,
                    e.target.name,
                    e.target.value
                );
        }
    };

    handleBlur = async e => {
        this.SaveData();
    };

    selectFile = event => {
        event.preventDefault();
        console.log("FI", this.file_input);
        this.file_input = document.createElement("input");
        this.file_input.addEventListener(
            "change",
            this.uploadFile.bind(this),
            false
        );
        this.file_input.type = "file";
        this.file_input.click();
    };

    isDocumentUpdateNeeded = () => {
        const pdfData = this.props.app.pdfFieldValues;
        const appData = this.props.app;
        if (!pdfData) return false;
        if (pdfData.AdditionalServiceNeeded !== appData.AdditionalServiceNeeded)
            return true;
        if (pdfData.Finished !== appData.Finished) return true;
        if (pdfData.Duration !== appData.Duration) return true;
        if (pdfData.ErledigteArbeiten !== appData.ErledigteArbeiten)
            return true;
        if (pdfData.StartTime !== appData.StartTime) return true;
        if (pdfData.hasBild !== (this.props.fotos.length > 0)) return true;

        return false;
    };

    getBase64 = file => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    uploadFile = () => {
        this.getBase64(this.file_input.files[0]).then(src =>
            this.props.FotoAdd(this.props.app, src)
        );
    };
    handleErledigtChange = (id, checked) => e => {
        const ParsedData = JSON.parse(this.props.app.ServiceMangelbeschreibung);

        let ChangedDataString = ParsedData.map(d => {
            if (d.id === id) {
                d.Erledigt = checked;
                return d;
            } else return d;
        });
        ChangedDataString = JSON.stringify(ChangedDataString);
        this.props.SavePropertyValue( this.props.app, "ServiceMangelbeschreibung", ChangedDataString);

    };

    ShowAppointment = val => {
        this.props.history.push("/task/" + (val.UniqueID==null?-1:val.UniqueID) + "/" + val.IDService );
    };

    startWorkAccepted = async (app) => {
        const dt = new Date();
        dt.setSeconds(0, 0);
        await this.props.SavePropertyValue(app, "Started", 1);
        await this.props.SavePropertyValue(app, "StartTime", dt);

        this.setState({
            ...this.state,
            dialogApp: null,
        });
    };

    // renderDateButton = (props) => (
    //     <Button
    //         variant="contained"
    //         size="medium"
    //         color="secondary"
    //         onClick={props.onClick}
    //         id={props.id}
    //         value={props.value}
    //         disabled={props.disabled}
    //         {...props.inputProps}
    //     ><CalendarIcon size="medium" style={{ marginRight: "0px" }} />Folgetermin</Button>
    // );

    saveFolgeterminDate = (app, e) => {
        let newDateToSet = null;
        if (e !== null) newDateToSet = e._d;
        //console.log(app, newDateToSet);

        let data = {
            StartTime: newDateToSet,
            StartDate: newDateToSet,
            Duration: app.GeplanteServicezeit,
            UniqueID: null,
            IDService: app.IDService
        }

        if(checkIfAppointmentDurationIsInOneDay(data.StartDate, data.Duration)){
            this.props.SaveDataFolgetermin(data);
        }else{
            notification(
                "nonEditable",
                "Die Dauer des Termins beträgt mehr als einen Tag. Bitte wählen Sie eine andere Startzeit für den Termin."
            );
        }

        
    }

   

    showInfoAboutSync = e => {
        notification(
            "nonEditable",
            "Bearbeiten ist deaktiviert, bitte synchronisieren."
        );
    }
//Download fajlova koji nisu inicijalno povučeni zbog ograničenja veličine fajlova.
    downloadFile = () => {
        // console.log("File ID:", this.state.fileForDownloadID);
        this.setState({ ...this.state, downloadInProgress: true });
        let fileName = this.state.fileNameForDownload + this.state.fileExtForDownload;
        this.props.DownloadFileManually(this.props.settings, this.props.app.IDService, fileName, this.state.fileIdForDownload,);
    }

    render() {
        const { app, notes, fotos, shownType, match:{params:{id : uniqueIsNull } }} = this.props;
        const ShouldDisableInputs = uniqueIsNull === "-1" ? true : false;
//console.log('Finished:',app.Finished) ;
        return (
            <Dialog fullScreen open={true}>
                <AppBar>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            onClick={this.handleClose}
                            aria-label="Close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <div style={{ width: "50%" }}>
                            <div>
                                {app.ServiceNr} - {app.ekName}
                            </div>
                            <div>
                                ({app.ekPLZ} - {app.ekOrt})
                            </div>
                        </div>
                        <div style={{ width: "50%", textAlign: "right" }}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DateTimePicker
                                    style={{ display: "none" }}
                                    TextFieldComponent={this.renderDateButton}
                                    disablePast={true}
                                    shouldDisableDate={date => new DateHelper().disableWeekends(date)}
                                    ampm={false}
                                    onChange={(e) => this.saveFolgeterminDate(app, e)}
                                    ref={node => {
                                        this.picker = node;
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            <Button
                                variant="contained"
                                size="medium"
                                color="secondary"
                                onClick={() => {
                                    this.ToggleConfirmationAsk(app, "StartDialog");
                                }}
                                disabled={ ShouldDisableInputs } //Task 6403 app.Finished === 1 // omogućeno ravljenje dodatnog termina i za završene appointmente.
                            ><CalendarIcon size="medium" style={{ marginRight: "0px" }} />Folgetermin</Button>
                            <Button
                                variant="contained"
                                size="medium"
                                id="fabFinish"
                                aria-label="Dest"
                                color="secondary"
                                name="StartTime"
                                onClick={() => {
                                    this.ToggleWorkAsk(app, "Start");
                                }}
                                disabled={
                                    app.Started === 1 || shownType === "ERLEDIGTE" || ShouldDisableInputs || !(new DateHelper().disableStart(app.StartDate))
                                }
                                style={{
                                    backgroundColor:
                                        app.Started === 1 || app.Finished === 1 || !(new DateHelper().disableStart(app.StartDate))
                                            ? "gray"
                                            : "secondary",
                                    margin: "5px",
                                    color: "white",
                                    width: "90px"
                                }}
                            >
                                <WatchIcon size="small" />
                                Start
                            </Button>
                            <Button
                                variant="contained"
                                size="medium"
                                aria-label="Dest"
                                id="fabStart"
                                name="Finished"
                                color="secondary"
                                onClick={() => {
                                    this.ToggleWorkAsk(app, "End");
                                }}
                                disabled={
                                    app.Started !== 1 || app.Started === undefined || (
                                        app.Finished === 1 && app.EndDate !== null) || ShouldDisableInputs
                                }
                                style={{
                                    backgroundColor:
                                        app.Started !== 1 || app.Finished === 1 || ShouldDisableInputs
                                            ? "gray"
                                            : "#b2232e",
                                    color: "white",
                                    width: "90px",
                                    margin: "5px"
                                }}
                            >
                                <WatchCheckedIcon size="small" /> Stop
                            </Button>
                        </div>
                    </Toolbar>
                </AppBar>

                <div style={styles.container}>
                    {/* Service info */}
                    <Table>
                        <TableBody>
                            <TableRow style={{ height: 10 }}>
                                <TableCell>
                                    <ProjectIcon fontSize="small" />
                                </TableCell>
                                <TableCell>Projekt:</TableCell>
                                <TableCell>{app.ProjektNr}</TableCell>
                                <TableCell />
                                <TableCell />
                                <TableCell />
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <PhoneIcon fontSize="small" />
                                </TableCell>
                                <TableCell>Telefon:</TableCell>
                                <TableCell>{app.ekTel1}</TableCell>
                                <TableCell>
                                    <CalendarIcon fontSize="small" />
                                </TableCell>
                                <TableCell>Start:</TableCell>
                                <TableCell>
                                    {app.StartDate !== null ? moment(app.StartDate).format(
                                        "DD.MM YYYY HH:mm"
                                    ) : ""}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <PhoneIcon fontSize="small" />
                                </TableCell>
                                <TableCell>Mobil:</TableCell>
                                <TableCell>{app.ekTel2}</TableCell>
                                <TableCell>
                                    <CalendarIcon fontSize="small" />
                                </TableCell>
                                <TableCell>Ende:</TableCell>
                                <TableCell>
                                    {app.EndDate !== null ? moment(app.EndDate).format(
                                        "DD.MM YYYY HH:mm"
                                    ) : ""}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <KommissionIcon fontSize="small" />
                                </TableCell>
                                <TableCell>Komission:</TableCell>
                                <TableCell>{app.ProjekteKomision}</TableCell>
                                <TableCell>
                                    <TimeIcon fontSize="small" />
                                </TableCell>
                                <TableCell>Geplante Dauer:</TableCell>
                                <TableCell>{app.GeplanteServicezeit + " min"}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <WorkIcon fontSize="small" />
                                </TableCell>
                                <TableCell>Auftraggeber:</TableCell>
                                <TableCell>{app.Firma}</TableCell>
                                <TableCell>
                                    <PersonIcon fontSize="small" />
                                </TableCell>
                                <TableCell>Bearbeiter:</TableCell>
                                <TableCell>{app.Berater}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <PersonIcon fontSize="small" />
                                </TableCell>
                                <TableCell>Kunde:</TableCell>
                                <TableCell>
                                    {app.ekName}, {app.Strasse}, {app.Plz}{" "}
                                    {app.Ort}
                                </TableCell>
                                <TableCell>
                                    <PhoneIcon fontSize="small" />
                                </TableCell>
                                <TableCell>Telefon:</TableCell>
                                <TableCell>{app.BeraterTel1}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell>
                                    <PhoneIcon fontSize="small" />
                                </TableCell>
                                <TableCell>Mobil:</TableCell>
                                <TableCell>{app.BeraterTel2}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <br />
                    {/* Mangelbeschreibung */}
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ fontSize: 14 }} colSpan={5}>
                                    Mangelbeschreibung
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Pos</TableCell>
                                <TableCell>Anzahl</TableCell>
                                <TableCell>Beschriebung</TableCell>
                                <TableCell>Notiz</TableCell>
                                <TableCell>erledigt</TableCell>
                            </TableRow>
                            {this._returnDescriptionData(
                                app.ServiceMangelbeschreibung
                            ).map((item, index) => {
                                return (
                                    <React.Fragment key={item.id}>
                                        <TableRow
                                            style={{
                                                backgroundColor: "white"
                                            }}
                                        >
                                            <TableCell>
                                                {item.Position}
                                            </TableCell>
                                            <TableCell>{item.Anzahl}</TableCell>
                                            <TableCell>
                                                {item.Mangelbechreibung}
                                            </TableCell>
                                            <TableCell>{item.Notiz}</TableCell>
                                            <TableCell>
                                                <Checkbox
                                                    checked={item.Erledigt}
                                                    disabled={shownType !== "OFFENE" || ShouldDisableInputs}
                                                    onChange={
                                                        this.handleErledigtChange(item.id, !item.Erledigt)
                                                    }
                                                    value={"" + index}
                                                    name="Erledigt"
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>

                    <br />
                    {/* Notiz */}
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ fontSize: 14 }} colSpan={4}>
                                    Notiz
                                </TableCell>
                            </TableRow>
                            {notes.map(item => {
                                const attachments = this.props.attachments.filter(
                                    c => c.NoteID === item.id
                                );
                                const attDom = attachments.map(item => {
                                    return (
                                        <span key={item.id}>
                                            <AttachmentIcon
                                                fontSize="small"
                                                onClick={() => {
                                                    this.openDocument(item);
                                                }}
                                            />
                                            {item.FileName}
                                            {item.Extension}
                                        </span>
                                    );
                                });
                                return (
                                    <React.Fragment key={item.id}>
                                        <TableRow>
                                            <TableCell style={styles.tablecell}>
                                                <Scrollbars
                                                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                                    autoHeight
                                                    autoHeightMax={150}
                                                    autoHide={false}
                                                >
                                                    <p style={styles.notiz}>{item.Note ? item.Note : "-"}</p>
                                                </Scrollbars>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <b>
                                                    <span
                                                        style={{
                                                            fontSize: ".8em"
                                                        }}
                                                    >
                                                        {item.MitarbeiterName}{" "}
                                                        {moment(
                                                            item.NoteDate
                                                        ).format(
                                                            "DD.MM YYYY HH:mm"
                                                        )}{" "}
                                                        {attDom}
                                                    </span>
                                                </b>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <br />
                    {/* Form fields */}
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ border: 0 }}>
                                    <TextField
                                        multiline
                                        style={{ width: "100%" }}
                                        name="ErledigteArbeiten"
                                        label="Notizen"
                                        onChange={this.handleChange}
                                        onBlur={this.handleBlur}
                                        value={this.props.app.ErledigteArbeiten}
                                        margin="dense"
                                        variant="filled"
                                        disabled={shownType !== "OFFENE" || ShouldDisableInputs}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ border: 0 }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox disabled={app.Started === 0 || app.Started === undefined || shownType !== "OFFENE" || ShouldDisableInputs}
                                                checked={
                                                    this.props.app.Finished === 1
                                                }
                                                onChange={this.handleChange}
                                                name="Finished"
                                                value="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label="Arbeit abgeschlossen"
                                    />
                                </TableCell>
                            </TableRow>
                            {/* <TableRow />
                            <TableRow /> */}

                        </TableBody>
                    </Table>
                    <br />
                    {/* Dokument unterzeichnung */}
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell
                                    style={{ fontSize: 14, border: 0 }}
                                    colSpan={2}
                                >
                                    Dokument unterzeichnung:
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ border: 0 }}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        disabled={shownType !== "OFFENE" || ShouldDisableInputs}
                                        onClick={this.materialUndZeit}
                                    >
                                        Material & Zeit
                                    </Button>
                                </TableCell>
                                <TableCell style={{ border: 0 }}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={this.signDialog}
                                        disabled={shownType !== "OFFENE" || ShouldDisableInputs}
                                    >
                                        Unterzeichnen
                                    </Button>
                                    <RefreshIcon
                                        font="small"
                                        visibility={
                                            this.isDocumentUpdateNeeded()
                                                ? "visible"
                                                : "hidden"
                                        }
                                    />
                                </TableCell>
                                <TableCell style={{ border: 0 }}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        disabled={!this.props.app.pdf || ShouldDisableInputs}
                                        onClick={() => {
                                            this.ShowPDF(this.props.app.pdf);
                                        }}
                                    >
                                        Dokument anzeigen
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <br />
                    <ToastContainer autoClose={8000} />
                    {/* Fotos */}
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ border: 0 }}>
                                    <GridList cellHeight={160} cols={3}>
                                        {fotos.map((tile, index) => (
                                            <GridListTile
                                                cols={1}
                                                key={index}
                                                style={{
                                                    border: "1px solid #DDD"
                                                }}
                                            >
                                                <img src={tile.data} alt="" />
                                                <GridListTileBar
                                                    actionIcon={
                                                        <IconButton>
                                                            <DeleteIcon
                                                                color="secondary"
                                                                onClick={() => {
                                                                    this.removeImage(
                                                                        tile.id
                                                                    );
                                                                }}
                                                            />
                                                        </IconButton>
                                                    }
                                                />
                                            </GridListTile>
                                        ))}
                                    </GridList>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ border: 0 }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.fotoMachen}
                                        disabled={shownType !== "OFFENE" || ShouldDisableInputs}
                                    >
                                        Foto machen
                                    </Button>{" "}
                                    &nbsp;
                                    <Button
                                        onClick={this.selectFile}
                                        variant="contained"
                                        color="primary"
                                        disabled={shownType !== "OFFENE" || ShouldDisableInputs}
                                    >
                                        Foto hinzufuegen
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
                <TakePhoto
                    open={this.state.TakePhotoOpen}
                    handleClose={this.closeTakePhoto}
                    handleTakeScreenshot={this.handleTakeScreenshot}
                />
                <MessageBox
                    open={this.state._deleteImageMessageOpen}
                    Title="Foto Loeschen?"
                    MessageBox="Sind Sie Sicher?"
                    AcceptText="Loeschen"
                    CancelText="Abbrechen"
                    handleClose={this.handleCloseMessage}
                    handleAccept={this.onRemoveImage}
                />
                <Dialog fullScreen open={this.state.dialog}>
                    <AppBar>
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                onClick={this.handleClose}
                                aria-label="Close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <div>
                                <div>Serviceauftrag Daten einfügen</div>
                            </div>
                        </Toolbar>
                    </AppBar>
                </Dialog>
                <StartDialogDatePicker open={this.state.confirmationDialogOpen} type={this.state.type} handleClose={this.handleCloseDialogsConfirmation} openPicker={this.openPicker} />
                <StartWorkQuestionDialog open={this.state.questionDialogOpen} type={this.state.type} handleClose={this.handleCloseDialogs} toggleWorkAccepted={this.toggleWorkAccepted} />
                <DownloadFileDialog
                    open={this.state.downloadFileDialogOpen}
                    handleClose={this.handleCloseDialogs}
                    downloadFile={this.downloadFile}
                    downloadInProgress={this.state.downloadInProgress}
                />
            </Dialog>
        );
    }
}

TaskView.propTypes = {
    app: PropTypes.object.isRequired,
    notes: PropTypes.array.isRequired,
    attachments: PropTypes.array.isRequired,
    fotos: PropTypes.array.isRequired,
    setTitle: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    app: SelectedAppSelector(state),
    notes: SelectedAppNotesSelector(state),
    attachments: SelectedAppAttachmentsSelector(state),
    fotos: SelectedAppPhotosSelector(state),
    shownType : state.tasks.shownType,
    settings: state.settings,
});

//export default connect(mapStateToProps, { DoSync, ReportProgress })(TaskView);
export default withRouter(
    connect(
        mapStateToProps,
        {
            setTitle,
            SelectAppointmentByID,
            SetPropertyValue,
            SavePropertyValue,
            SaveData,
            FotoAdd,
            FotoRemove,
            SaveDataFolgetermin,
            DownloadFileManually
        }
    )(TaskView)
);
