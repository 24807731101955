import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import SigPadTransparent from '../extensions/SigPadTransparent';
import Button from '@material-ui/core/Button';
import { SetPropertyValue, SavePropertyValue, SaveSignatures, SelectAppointmentByID, SaveSignaturesForServiceProtokol } from '../../actions/TaskActions';
import { EnteredDataForServicePDF } from '../../selectors/SelectedAppointmentSelector';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import TableServiceProtokol from './TableServiceProtokol';

import { setTitle } from '../../actions/AppActions';
import GeneratePdf from './GeneratePdf';
const styles = {
    card: {
        margin: '20px',
        border: '1px solid #999',
        width: 'inherit'
    },
    alignment: {
        margin: '0px auto 0px auto'
    },
    input: {
        width: '100%'
    },
    textField: {
        marginLeft: '5px',
        marginRight: '5px',
        width: 200,
      },
}

class TaskSignService extends Component {

    sigPadKunde = {}

    componentDidMount() {
        this.props.setTitle("Serviceauftrag Daten einfügen");
        this.props.SelectAppointmentByID(this.props.match.params.id,this.props.match.params.service);
        console.log("mounterd");
    }
    handleClearKunde = () => {
        this.sigPadKunde.clear();
    }

    UpdateKundeSignature = () => {
        if (this.sigPadKunde) this.sigPadKunde.fromDataURLTransparent(this.props.app.SignatureKunde, { width: 280, height: 60 });
        console.log("Update signature");
    }

    saveSignature = event => {      
        const kundeSignature = this.sigPadKunde.toDataURLTransparent('image/png');

        const value = {
            SignatureKunde: kundeSignature,
            AdditionalServiceNeeded: this.props.app.AdditionalServiceNeeded
        }
        this.props.SaveSignaturesForServiceProtokol(this.props.app, value);
    }

    handleChange = (e, checked) => {
        this.props.SavePropertyValue(this.props.app, e.target.name, checked);
    }
    handleChangeRadioButton = event => {
        this.props.SavePropertyValue(this.props.app, event.target.name, event.target.value);
    };
    handleTextChangeInput = name => event => {
        this.props.SetPropertyValue(this.props.app, name, event.target.value);
    };

    handleTextBlurInput = name => event => {
        this.props.SavePropertyValue(this.props.app, name, event.target.value);
    };


    render() {
        // console.log("TaskSignServiceProps: ", this.props.app);
        const { Festgestellte, Ausgefuhrte, Masshaltigkeit, Benotigtes, Stabzahl, SollMass, IstMass, SollGelocht, SollUngelocht,
            IstGelocht, IstUngelocht, LangeFuhrungsscienen, IstLange, SollLange, FuhrungsschienenMontiert, FunktionstestAnlage,
            FotosGemacht, FehlerhafteMontage, Axialspiel, FehlerhafteProgramierung, Bedienfehler, Panzer,
            MotorSteuerung, Bedienungs, Endstab, UnzulassigeInstallation, ISRollo, ZusatzlicheAngaben,
            Aufhanger, Einlauftrichter, Fliegengitter, Wickeldurchmesser, Gurtwickler, ServiceTyp,
            TerminNotwendig, ProfilstabeVerbaut, Einbaurichtung, Sonstiges
        } = this.props.app;
        if (!this.props.app.StartDate) return (<div />);
        return (
            <div style={{ marginTop: '70px' }}>
                <Paper elevation={1} style={styles.card}>
                <FormLabel component="legend">Servicetyp</FormLabel>
                    <div>
                    </div>
                    <FormControl component="fieldset">                                       
                        <RadioGroup
                            aria-label="Servicetyp"
                            name="ServiceTyp"
                            className=""
                            value={ServiceTyp}
                            onChange={this.handleChangeRadioButton}
                            style={{display:"inline"}}
                        >
                            <FormControlLabel name="kundendienst" value="kundendienst" control={<Radio />} label="Kundendienst" />
                            <FormControlLabel name="beratung" value="beratung" control={<Radio />} label="Beratung / Begutachtung" />
                            <FormControlLabel name="serviceleistung" value="serviceleistung" control={<Radio />} label="Serviceleistung / Reparatur" />
                        </RadioGroup>
                       
                    </FormControl>
                </Paper>

                <Paper elevation={2} style={styles.card}>
                <div>
                    <TextField
                        id="Festgestellte"
                        label="festgestellte Mangel"
                        value={Festgestellte || ""}
                        onChange={this.handleTextChangeInput('Festgestellte')}
                        onBlur={this.handleTextBlurInput('Festgestellte')}
                        margin="normal"
                        style={styles.textField}
                    />
                    <TextField
                        id="Ausgefuhrte"
                        label="ausgeführte Arbeiten"
                        value={Ausgefuhrte || ""}
                        onChange={this.handleTextChangeInput('Ausgefuhrte')}
                        onBlur={this.handleTextBlurInput('Ausgefuhrte')}
                        margin="normal"
                        style={styles.textField}
                    />
                    <TextField
                        id="Benotigtes"
                        label="benötigtes Material"
                        style={styles.textField}
                        value={Benotigtes || ""}
                        onChange={this.handleTextChangeInput('Benotigtes')}
                        onBlur={this.handleTextBlurInput('Benotigtes')}
                        margin="normal"
                    />
                    </div>
                </Paper>
                <Paper elevation={3} style={styles.card}>
                    <FormLabel component="legend">Prüfmaßnahmen vor Ort</FormLabel>
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={Masshaltigkeit === true ? true : false} name="Masshaltigkeit" value="checkedB" color="primary"
                            />
                        }
                        label="Maßhaltigkeit Element geprüft?"
                    /><div>
                    <TextField
                        id="SollMass"
                        label="SOLL Maß lt. ZA / Auftrag"
                        style={styles.textField}
                        value={SollMass || ""}
                        onChange={this.handleTextChangeInput('SollMass')}
                        onBlur={this.handleTextBlurInput('SollMass')}
                        margin="normal"
                        disabled={Masshaltigkeit === true ? false : true}
                    />
                    <TextField
                        id="IstMass"
                        label="IST Maß lt. ZA / Auftrag"
                        style={styles.textField}
                        value={IstMass || ""}
                        onChange={this.handleTextChangeInput('IstMass')}
                        onBlur={this.handleTextBlurInput('IstMass')}
                        margin="normal"
                        disabled={Masshaltigkeit === true ? false : true}
                    />     
                    </div>               
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} name="Stabzahl" value="checkedBB" color="primary"
                            />
                        }
                        label="Stabzahl Panzer überprüft?"
                    /><br />
                    <FormLabel>SOLL Zahl</FormLabel>
                    <div>
                    <TextField
                        id="SollGelocht"
                        label="gelocht"
                        style={styles.textField}
                        value={SollGelocht || ""}
                        onChange={this.handleTextChangeInput('SollGelocht')}
                        onBlur={this.handleTextBlurInput('SollGelocht')}
                        margin="normal"
                        disabled={Stabzahl === true ? false : true}
                    />
                    <TextField
                        id="SollUngelocht"
                        label="ungelocht"
                        style={styles.textField}
                        value={SollUngelocht || ""}
                        onChange={this.handleTextChangeInput('SollUngelocht')}
                        onBlur={this.handleTextBlurInput('SollUngelocht')}
                        margin="normal"
                        disabled={Stabzahl === true ? false : true}
                    /></div>
                    <FormLabel>IST Zahl</FormLabel>
                    <div>
                    <TextField
                        id="IstGelocht"
                        label="gelocht"
                        style={styles.textField}
                        value={IstGelocht || ""}
                        onChange={this.handleTextChangeInput('IstGelocht')}
                        onBlur={this.handleTextBlurInput('IstGelocht')}
                        margin="normal"
                        disabled={Stabzahl === true ? false : true}
                    />
                    <TextField
                        id="IstUngelocht"
                        label="ungelocht"
                        style={styles.textField}
                        value={IstUngelocht || ""}
                        onChange={this.handleTextChangeInput('IstUngelocht')}
                        onBlur={this.handleTextBlurInput('IstUngelocht')}
                        margin="normal"
                        disabled={Stabzahl === true ? false : true}
                    /></div>
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={LangeFuhrungsscienen === true ? true : false} name="LangeFuhrungsscienen" value="checkedB" color="primary"
                            />
                        }
                        label="Länge Führungsschienen geprüft?"
                    /><div>
                    <TextField
                        id="SollLange"
                        label="SOLL Länge lt. ZA / Auftrag:"
                        style={styles.textField}
                        value={SollLange || ""}
                        onChange={this.handleTextChangeInput('SollLange')}
                        onBlur={this.handleTextBlurInput('SollLange')}
                        margin="normal"
                        disabled={LangeFuhrungsscienen === true ? false : true}
                    />
                    <TextField
                        id="IstLange"
                        label="IST Länge lt. ZA / Auftrag:"
                        style={styles.textField}
                        value={IstLange || ""}
                        onChange={this.handleTextChangeInput('IstLange')}
                        onBlur={this.handleTextBlurInput('IstLange')}
                        margin="normal"
                        disabled={LangeFuhrungsscienen === true ? false : true}
                    />
                    </div>
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={FuhrungsschienenMontiert === true ? true : false} name="FuhrungsschienenMontiert" value="checkedB" color="primary"
                            />
                        }
                        label="Führungsschienen richtig montiert?"
                    /><br />
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={FunktionstestAnlage === true ? true : false} name="FunktionstestAnlage" value="checkedB" color="primary"
                            />
                        }
                        label="Funktionstest Anlage durchgeführt?"
                    /><br />
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={FotosGemacht === true ? true : false} name="FotosGemacht" value="checkedB" color="primary"
                            />
                        }
                        label="Fotos gemacht ?"
                    /><br />
                </Paper>
                <Paper elevation={4} style={styles.card}>
                    <FormLabel component="legend">Fehlerursache</FormLabel><br />
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={FehlerhafteMontage === true ? true : false} name="FehlerhafteMontage" value="checkedB" color="primary" />
                        }
                        label="fehlerhafte Montage"
                    /><br />
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={Bedienfehler === true ? true : false} name="Bedienfehler" value="checkedB" color="primary" />
                        }
                        label="Bedienfehler"
                    /><br />
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={Bedienungs === true ? true : false} name="Bedienungs" value="checkedB" color="primary" />
                        }
                        label="Bedienungs- u. Wartungsanleitung vorhanden"
                    /><br />
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={ISRollo === true ? true : false} name="ISRollo" value="checkedB" color="primary" />
                        }
                        label="IS-Rollo (Spannung / Gaze / Endleiste)"
                    /><br />
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={Fliegengitter === true ? true : false} name="Fliegengitter" value="checkedB" color="primary" />
                        }
                        label="Fliegengitter (Gaze defekt / Profil)"
                    /><br />
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={TerminNotwendig === true ? true : false} name="TerminNotwendig" value="checkedB" color="primary" />
                        }
                        label="weiterer Termin notwendig"
                    /><br />
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={Axialspiel === true ? true : false} name="Axialspiel" value="checkedB" color="primary" />
                        }
                        label="Axialspiel (zu groß / zu klein)"
                    /><br />
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={Panzer === true ? true : false} name="Panzer" value="checkedB" color="primary" />
                        }
                        label="Panzer Arretierung (defekt / klemmt)"
                    /><br />
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={Endstab === true ? true : false} name="Endstab" value="checkedB" color="primary" />
                        }
                        label="Endstab verkantet in der Führungsschiene"
                    /><br />
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={Aufhanger === true ? true : false} name="Aufhanger" value="checkedB" color="primary" />
                        }
                        label="Aufhänger abgebrochen (Federn / Starre)"
                    /><br />
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={Wickeldurchmesser === true ? true : false} name="Wickeldurchmesser" value="checkedB" color="primary" />
                        }
                        label="Wickeldurchmesser zu groß"
                    /><br />
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={ProfilstabeVerbaut === true ? true : false} name="ProfilstabeVerbaut" value="checkedB" color="primary" />
                        }
                        label="zu viele / zu wenige Profilstäbe verbaut"
                    /><br />
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={FehlerhafteProgramierung === true ? true : false} name="FehlerhafteProgramierung" value="checkedB" color="primary" />
                        }
                        label="fehlerhafte Programmierung/ -einstellung"
                    /><br />
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={MotorSteuerung === true ? true : false} name="MotorSteuerung" value="checkedB" color="primary" />
                        }
                        label="Motor / Uhr / Steuerung defekt"
                    /><br />
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={UnzulassigeInstallation === true ? true : false} name="UnzulassigeInstallation" value="checkedB" color="primary" />
                        }
                        label="unzulässige elektrische Installation"
                    /><br />
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={Einlauftrichter === true ? true : false} name="Einlauftrichter" value="checkedB" color="primary" />
                        }
                        label="Einlauftrichter (zu klein / lose)"
                    /><br />
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={Gurtwickler === true ? true : false} name="Gurtwickler" value="checkedB" color="primary" />
                        }
                        label="Gurtwickler / Kurbelgetriebe defekt"
                    /><br />
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={Einbaurichtung === true ? true : false} name="Einbaurichtung" value="checkedB" color="primary" />
                        }
                        label="Einbaurichtung (Nord / Ost / Süd / West"
                    /><br />
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={Sonstiges === true ? true : false} name="Sonstiges" value="checkedB" color="primary" />
                        }
                        label="Sonstiges"
                    /><br />
                    <TextField
                        id="ZusatzlicheAngaben"
                        label="Zusatzliche Angaben"
                        style={styles.textField}
                        value={ZusatzlicheAngaben || ""}
                        onChange={this.handleTextChangeInput('ZusatzlicheAngaben')}
                        onBlur={this.handleTextBlurInput('ZusatzlicheAngaben')}
                        margin="normal"
                    />
                </Paper>
                <Paper elevation={5} style={styles.card}>
                    <TableServiceProtokol />
                </Paper>
                <Paper elevation={6} style={styles.card}>
                    <Typography variant="h5" component="h3">
                        <FormControlLabel labelPlacement="bottom"
                            control={
                                <SigPadTransparent ref={(ref) => { this.sigPadKunde = ref; this.UpdateKundeSignature() }} onEnd={this.saveSignature} backgroundColor='rgba(200,200,200,1)' penColor='blue' canvasProps={{ width: 280, height: 60, className: 'sigCanvas' }} />
                            }
                            label="Unterschrift Bauherr / Eigentümer"
                        />

                    </Typography>
                    <Button color="primary" variant="contained" onClick={this.handleClearKunde}>Clear</Button>
                </Paper>
                <Paper elevation={7} style={styles.card}>
                    <GeneratePdf />
                </Paper>

                {/* <Button color="primary" variant="contained" onClick={this.handleSave}>Save</Button> */}
            </div>
        )
    }
}

// const mapStateToProps = state => (
//     {
//         app: SelectedAppSelector(state)
//     }
// );
function mapStateToProps(state) {
    console.log("MapStateToProps: ", state);
    return {
        app: EnteredDataForServicePDF(state)
    }
}

export default withRouter(connect(mapStateToProps, { setTitle, SetPropertyValue, SavePropertyValue, SaveSignatures, SelectAppointmentByID, SaveSignaturesForServiceProtokol })(TaskSignService));