import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import Paper from "@material-ui/core/Paper";
// import Typography from "@material-ui/core/Typography";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from '@material-ui/core/TextField';
import DBTasks from '../../../db/DbTasks';
import Typography from "@material-ui/core/Typography";
import notification from "../../../helpers/toast_notification";
import CircularProgress from '@material-ui/core/CircularProgress';

// const styles2 = {
//     card: {
//         margin: "10px",
//         border: "1px solid #999"
//     }
// };


export default class SendEmailDialog extends Component {

    state = {
        fullWidth: true,
        maxWidth: 'xs',
        Recipient: null,
        Subject: "Kundenservice",
        ErrorID: 30,
        ErrorMessage: "",
        CurrentlySendingMail: false
    };

    handleChangeInput = (e) => {
        this.setState({ ...this.state, Recipient: e })
    }

    sendEmail = async () => {
        this.setState({ ...this.state, CurrentlySendingMail: true })
        let sendMail = new DBTasks().sendEmail;
        let objToSend = {
            emailTo: this.state.Recipient || this.props.email,
            subject: this.state.Subject,
            attachment: this.props.pdf
        }
        //console.log("Obj to send :", objToSend);
        let returnedObject = await sendMail(this.props.settings, objToSend);
        this.setState({ ...this.state, ErrorID: returnedObject.ErrorID, ErrorMessage: returnedObject.ErrorMessage, CurrentlySendingMail: false });
        if(returnedObject.ErrorID === 20){
            this.setState({ ...this.state, Recipient: null, ErrorMessage: "", CurrentlySendingMail: false });
            this.props.handleClose();
            notification(
                "success",
                "Mail wurde erfolgreich gesendet."
            );
        }
    }

    getStyle = (val) => {
        switch (val) {
            case 0: //Prazan email error
                return { fontSize: 15, fontWeight: "bold", marginLeft: "10px", marginTop: "5px", color: "red" };
            case 10: //Nije prošlo slanje mail-a
                return { fontSize: 15, fontWeight: "bold", marginLeft: "10px", marginTop: "5px", color: "red" };
            case 20: //Mail poslan
                return { fontSize: 15, fontWeight: "bold", marginLeft: "10px", marginTop: "5px", color: "green" };
            default:
                return
        }

    }


    render() {
        return (
            <Dialog
                fullWidth={this.state.fullWidth}
                maxWidth={this.state.maxWidth}
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Email</DialogTitle>
                <DialogContent>

                    <TextField
                        multiline
                        fullWidth
                        label="E-Mail Empfänger"
                        value={this.state.Recipient || (this.props.email || "")}
                        // onBlur={this.handleBlur}
                        onChange={(e) => this.handleChangeInput(e.target.value)}
                        margin="normal"
                    />
                    <TextField
                        multiline
                        fullWidth
                        label="Betreff"
                        value={this.state.Subject}
                        margin="normal"
                        disabled
                    />
                    <TextField
                        multiline
                        fullWidth
                        label="Arbeitsbericht"
                        value="arbeitsbericht.pdf"
                        margin="normal"
                        disabled
                    />
                    <Typography component="p" style={this.getStyle(this.state.ErrorID)}>
                        {this.state.ErrorMessage}
                    </Typography>
                    {(this.state.CurrentlySendingMail === true) && (
                    <div style={{textAlign:"center"}}>
                        <CircularProgress
                            color="secondary"
                        />
                       <Typography component="p">
                        Mail senden...
                    </Typography>
                    </div>
                  )}
                </DialogContent>

                <DialogActions>
                    <Button onClick={this.props.handleClose} color="primary">
                        Abbrechen
                  </Button>
                    <Button onClick={this.sendEmail} name="Start" color="primary" autoFocus>
                        Versend
                  </Button>
                </DialogActions>

            </Dialog>
        )
    }
}


