import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


export default class StartWorkQuestionDialog extends Component {


    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{this.props.type ? "Vorgang Starten?" : "Vorgang beenden?"}</DialogTitle>
                <DialogContent>
                    {this.props.type === 1 && (
                        <DialogContentText id="alert-dialog-description">
                            Moechten Sie diesen Vorgang zu starten?
                        </DialogContentText>
                    )}
                    {this.props.type === 0 && (
                        <DialogContentText id="alert-dialog-description">
                            Moechten Sie diesen Vorgang zu beenden?
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose} color="primary">
                        Abbrechen
                    </Button>
                    {this.props.type && (
                        <Button onClick={this.props.toggleWorkAccepted} name="Start" color="primary" autoFocus>
                            Vorgang Starten
                        </Button>
                    )}
                    {!this.props.type && (
                        <Button onClick={this.props.toggleWorkAccepted} name="End" color="primary" autoFocus>
                            Vorgang beenden
                    </Button>
                    )}
                </DialogActions>
            </Dialog>
        )
    }
}
