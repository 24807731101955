import moment from "moment";
import { PAST_DAYS, FUTURE_DAYS } from "../settings";

export default class DateHelper {
    getToday() {
        let ret = moment(new Date()).startOf("day");
        //let ret = moment(new Date("10 July, 2023")).startOf("day");  //FOR TESTING PURPOSE

        //console.log(ret.isoWeekday());
        //if (ret.isoWeekday() === 6) ret = ret.add(2, "days");
        if (ret.isoWeekday() === 7) ret = ret.add(1, "days");
        return ret;
    }
    getStartDate() {
        let startD = this.getToday();
        for (let i = 0; i < PAST_DAYS; i++) {
            let dayIndex = startD.isoWeekday();
            if (dayIndex === 1) startD = startD.add(-2, "days");
            else startD = startD.add(-1, "days");
        }
        return startD;
    }

    getDateByIndex(index) {
        let day = this.getStartDate();

        for (let i = 0; i < index; i++) {
            let dayIndex = day.isoWeekday();
            //if (dayIndex === 5) day = day.add(3, "days");
            if (dayIndex === 6) day = day.add(2, "days");
            else day = day.add(1, "days");
        }
        return day;
    }

    appDuration(d1,d2){
        return d2.date()-d1.date();
    }

    getTodayIndex() {
        let startD = this.getToday();
        for (let i = 0; i <= PAST_DAYS + FUTURE_DAYS; i++) {
            if (startD === this.getDateByIndex(i)) return i;
        }
        return PAST_DAYS;
    }

    isTheSameDate(d1, d2) {
        return (
            d1.date() === d2.date() &&
            d1.month() === d2.month() &&
            d1.year() === d2.year()
        );
    }

    getTodayTime() {
        return moment(new Date()).format("HH:mm");
    }

    disableWeekends = date => {
        let dateForCheck = new Date(date);
        return dateForCheck.getDay() === 0 || dateForCheck.getDay() === 6;
      }

      //Disable start appointment if the appointment day is not today.
    disableStart = appointmentDate => {
        if(appointmentDate){
            let appDate = moment(appointmentDate);
            return this.isTheSameDate(appDate, moment());
        }      
    }
}
