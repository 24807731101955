import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Select from '@material-ui/core/Select';
import {
    SaveData,
    SetPropertyValue,
    SavePropertyValue,
    SelectAppointmentByID
} from "../../actions/TaskActions";
import { SelectedAppSelector } from "../../selectors/SelectedAppointmentSelector";

import { setTitle } from "../../actions/AppActions";
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ShortId from 'shortid';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';

const styles2 = {
    card: {
        margin: "20px",
        border: "1px solid #999"
    }
};

class MaterialUndZeit extends Component {
    sigPadKunde = {};
    state = {
        MaterialList: [],
        ZeitList: [],
        Besorgungsfahrt: [],
    }

    componentDidMount() {
        this.props.setTitle("Material & Zeit");
        this.props.SelectAppointmentByID(this.props.match.params.id, this.props.match.params.service);
        // console.log("mounted");
    }

    handleClearKunde = () => {
        this.sigPadKunde.clear();
    };

    UpdateKundeSignature = () => {
        if (this.sigPadKunde)
            this.sigPadKunde.fromDataURLTransparent(
                this.props.app.SignatureKunde,
                { width: 280, height: 60 }
            );
    };

    saveData = async () => {
        const data = {
            MaterialList: this.props.app.MaterialList,
            ZeitList: this.props.app.ZeitList,
            Besorgungsfahrt: this.props.app.Besorgungsfahrt,
            PauschalKleinMaterial: this.props.app.PauschalKleinMaterial,
            PauschalMaterial: this.props.app.PauschalMaterial
        };
        await this.props.SaveData(this.props.app, data);
    }
    handleBlur = async (e) => {
        this.saveData();
    }
    addItem = (tableName) => e => {
        e.preventDefault();
        let tableArr = this.props.app[tableName] || [];
        console.log(this.state);
        switch (tableName) {
            case "MaterialList":
                tableArr.push({ id: ShortId.generate(), Material: "", Stuck: "" });
                break;
            case "ZeitList":
                tableArr.push({ id: ShortId.generate(), ServiceMitarbeiter: this.props.app.FullName, Datum: moment(new Date()).format('DD.MM.YYYY'), Arbeitszeit: "" });
                break;
            case "Besorgungsfahrt":
                tableArr.push({ id: ShortId.generate(), FahrtZu: "", ServiceMitarbeiter: this.props.app.FullName, Datum: moment(new Date()).format('DD.MM.YYYY'), Kilometer: "", Dauer: "" });
                break;
            default:
                return tableArr;
        }
        // if (tableName === "MaterialList") {
        //     tableArr.push({ id: ShortId.generate(), Material: "", Stuck: "" });
        // } else {
        //     tableArr.push({ id: ShortId.generate(), ServiceMitarbeiter: "", Datum: moment(new Date()).format('DD.MM.YYYY'), Arbeitszeit: "" });
        // }
        this.setState({ tableName: tableArr });
        this.props.SetPropertyValue(this.props.app, tableName, tableArr);
    };

    deleteItem = (idToDelete, tableName) => e => {
        let changedData = this.props.app[tableName].filter(e => e.id !== idToDelete);
        this.setState({ tableName: changedData });
        this.props.SetPropertyValue(this.props.app, tableName, changedData);
    }
    handleChangeInput = (idToUpdate, tableName) => e => {
        let updatedData = this.props.app[tableName].map(d => {
            if (d.id === idToUpdate) {
                d[e.target.name] = e.target.value;
                return d;
            } else return d;
        });
        this.setState({ tableName: updatedData });
        this.props.SetPropertyValue(this.props.app, tableName, updatedData);
    }

    handleChange = (e, checked) => {
        this.props.SetPropertyValue(
            this.props.app,
            e.target.name,
            checked
        );
    };

    createMenuItems = () => {
        let arr = [];
        for (let i = 0; i <= 10; i += 0.25) {
            arr.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
        }
        return arr;
    }

    render() {
        if (!this.props.app.StartDate) return <div />;
        let MaterialList = this.props.app.MaterialList || [], ZeitList = this.props.app.ZeitList || [], Besorgungsfahrt = this.props.app.Besorgungsfahrt || [];


        return (
            <div style={{ marginTop: "70px" }}>
                <Paper elevation={1} style={styles2.card}>
                    <Typography variant="h5" component="h3">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        this.props.app.PauschalKleinMaterial || false
                                    }
                                    onChange={this.handleChange}
                                    onBlur={this.saveData}
                                    name="PauschalKleinMaterial"
                                    value="checkedB"
                                    color="primary"
                                />
                            }
                            label="pauschal Kleinmaterial"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        this.props.app.PauschalMaterial || false
                                    }
                                    onBlur={this.saveData}
                                    onChange={this.handleChange}
                                    name="PauschalMaterial"
                                    value="checkedB"
                                    color="primary"
                                />
                            }
                            label="pauschal Material"
                        />
                    </Typography>
                </Paper>
                <Paper elevation={1} style={styles2.card}>
                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <td></td>
                                <td>Material</td>
                                <td>Menge</td>
                            </tr>
                        </thead>
                        <tbody>
                            {MaterialList.map((val, idx) => {
                                return (
                                    <tr key={val.id}>
                                        <td> <Fab aria-label="Delete" onClick={this.deleteItem(val.id, "MaterialList")}>
                                            <DeleteIcon />
                                        </Fab></td>
                                        <td >
                                            <TextField
                                                name="Material"
                                                multiline
                                                fullWidth
                                                value={val.Material}
                                                onBlur={this.handleBlur}
                                                onChange={this.handleChangeInput(val.id, "MaterialList")}
                                                margin="normal"
                                            />
                                        </td>
                                        <td>
                                            <TextField
                                                name="Stuck"
                                                value={val.Stuck}
                                                onBlur={this.handleBlur}
                                                onChange={this.handleChangeInput(val.id, "MaterialList")}
                                                margin="normal"
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <Fab color="primary" aria-label="Add" onClick={this.addItem("MaterialList")}>
                        <AddIcon />
                    </Fab>
                </Paper>
                <Paper elevation={1} style={styles2.card}>
                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <td></td>
                                <td>Fahrt zu</td>
                                <td>Service Mitarbeiter</td>
                                <td>Datum</td>
                                <td>Kilometer</td>
                                <td>Dauer</td>
                            </tr>
                        </thead>
                        <tbody>
                            {Besorgungsfahrt.map((val, idx) => {
                                return (
                                    <tr key={val.id}>
                                        <td> <Fab aria-label="Delete" onClick={this.deleteItem(val.id, "Besorgungsfahrt")}>
                                            <DeleteIcon />
                                        </Fab></td>
                                        <td >
                                            <TextField
                                                name="FahrtZu"
                                                value={val.FahrtZu}
                                                onBlur={this.handleBlur}
                                                fullWidth
                                                onChange={this.handleChangeInput(val.id, "Besorgungsfahrt")}
                                                margin="normal"
                                                inputProps={{ maxLength: 100 }}
                                            />
                                        </td>
                                        <td>
                                            <TextField
                                                name="ServiceMitarbeiter"
                                                value={val.ServiceMitarbeiter}
                                                onBlur={this.handleBlur}
                                                onChange={this.handleChangeInput(val.id, "Besorgungsfahrt")}
                                                margin="normal"
                                            />
                                        </td>
                                        <td>
                                            <TextField
                                                name="Datum"
                                                value={val.Datum}
                                                onBlur={this.handleBlur}
                                                onChange={this.handleChangeInput(val.id, "Besorgungsfahrt")}
                                                margin="normal"
                                            />
                                        </td>
                                        <td>
                                            <TextField
                                                name="Kilometer"
                                                value={val.Kilometer}
                                                onBlur={this.handleBlur}
                                                onChange={this.handleChangeInput(val.id, "Besorgungsfahrt")}
                                                margin="normal"
                                                type="number"
                                            />
                                        </td>
                                        {/* <td>
                                            <TextField
                                                name="Dauer"
                                                value={val.Dauer}
                                                onBlur={this.handleBlur}
                                                onChange={this.handleChangeInput(val.id, "Besorgungsfahrt")}
                                                margin="normal"
                                            />
                                        </td> */}
                                        <td>
                                            <Select
                                                value={val.Dauer}
                                                onBlur={this.handleBlur}
                                                onChange={this.handleChangeInput(val.id, "Besorgungsfahrt")}
                                                inputProps={{
                                                    name: 'Dauer'
                                                }}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {this.createMenuItems()}
                                            </Select>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <Fab color="primary" aria-label="Add" onClick={this.addItem("Besorgungsfahrt")}>
                        <AddIcon />
                    </Fab>
                </Paper>
                <Paper elevation={1} style={styles2.card}>
                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <td></td>
                                <td>Service Mitarbeiter</td>
                                <td>Datum</td>
                                <td>Arbeitszeit (Stunden)</td>
                            </tr>
                        </thead>
                        <tbody>
                            {ZeitList.map((val, idx) => {
                                return (
                                    <tr key={val.id}>
                                        <td> <Fab aria-label="Delete" onClick={this.deleteItem(val.id, "ZeitList")}>
                                            <DeleteIcon />
                                        </Fab></td>
                                        <td >
                                            <TextField
                                                name="ServiceMitarbeiter"
                                                value={val.ServiceMitarbeiter}
                                                onBlur={this.handleBlur}
                                                onChange={this.handleChangeInput(val.id, "ZeitList")}
                                                margin="normal"
                                            />
                                        </td>
                                        <td> <TextField
                                            name="Datum"
                                            value={val.Datum}
                                            onBlur={this.handleBlur}
                                            onChange={this.handleChangeInput(val.id, "ZeitList")}
                                            margin="normal"
                                        /></td>
                                        <td>
                                            {/* <TextField
                                            id="Arbeitszeit"
                                            value={val.Arbeitszeit}
                                            onChange={this.handleChangeInput(val.id, "ZeitList")}
                                            margin="normal"
                                        /> */}
                                            <Select
                                                value={val.Arbeitszeit}
                                                onBlur={this.handleBlur}
                                                onChange={this.handleChangeInput(val.id, "ZeitList")}
                                                inputProps={{
                                                    name: 'Arbeitszeit'
                                                }}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {this.createMenuItems()}
                                            </Select>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <Fab color="primary" aria-label="Add" onClick={this.addItem("ZeitList")}>
                        <AddIcon />
                    </Fab>
                </Paper>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    app: SelectedAppSelector(state),
    company: state.app.company
});

export default withRouter(
    connect(
        mapStateToProps,
        {
            SaveData,
            setTitle,
            SetPropertyValue,
            SavePropertyValue,
            SelectAppointmentByID
        }
    )(MaterialUndZeit)
);
