import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import TextField from "@material-ui/core/TextField";

import {
    SetURL,
    SetBerater,
    SetChatURL,
    SaveSettings,
    SetFileSize
} from "../actions/SettingsActions";
import { setTitle } from "../actions/AppActions";

export class Settings extends Component {
    componentDidMount() {
        this.props.setTitle("Einstellungen");
    }

    handleChange = e => {
        switch (e.target.name) {
            case "BeraterID":
                this.props.SetBerater(e.target.value);
                return;
            case "ServiceURL":
                this.props.SetURL(e.target.value);
                return;
            case "ChatURL":
                this.props.SetChatURL(e.target.value);
                return;
            case "FileSize":
                this.props.SetFileSize(e.target.value);
                return;
            default:
                return;
        }
    };

    onSaveAllSettings = e => {
        this.props.SaveSettings(this.props.settings);
    };

    render() {
        return (
            <div style={{ margin: 20 }}>
                <div>
                    <TextField
                        id="txtBeraterID"
                        label="BeraterID"
                        fullWidth
                        value={this.props.settings.Berater || ""}
                        onBlur={this.onSaveAllSettings}
                        onChange={this.handleChange}
                        margin="normal"
                        name="BeraterID"
                    />
                </div>

                <div>
                    <TextField
                        id="txtServiceURL"
                        label="ServiceURL"
                        fullWidth
                        value={this.props.settings.URL || ""}
                        onBlur={this.onSaveAllSettings}
                        onChange={this.handleChange}
                        margin="normal"
                        name="ServiceURL"
                    />
                </div>

                <div>
                    <TextField
                        id="txtChatURL"
                        label="ChatURL"
                        fullWidth
                        value={this.props.settings.ChatURL || ""}
                        onBlur={this.onSaveAllSettings}
                        onChange={this.handleChange}
                        margin="normal"
                        name="ChatURL"
                    />
                </div>

                <div>
                    <TextField
                        id="fileSize"
                        label="FileSize"
                        fullWidth
                        value={this.props.settings.FileSize}
                        onBlur={this.onSaveAllSettings}
                        onChange={this.handleChange}
                        type="number"
                        margin="normal"
                        name="FileSize"
                    />
                </div>
            </div>
        );
    }
}

Settings.propTypes = {
    SetURL: PropTypes.func.isRequired,
    SetBerater: PropTypes.func.isRequired,
    SetChatURL: PropTypes.func.isRequired,
    SaveSettings: PropTypes.func.isRequired,
    SetFileSize: PropTypes.func.isRequired,
    settings: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    settings: state.settings
});

export default connect(
    mapStateToProps,
    { SetURL, SetBerater, SetChatURL, SaveSettings, setTitle, SetFileSize }
)(Settings);
