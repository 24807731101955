import { COMPANY_EVERS } from "../../helpers/constants";

export default function convertFile(filename, extension, base64, company) {
    var downloadLink = document.createElement('a');
    downloadLink.target = '_blank';
    downloadLink.download = filename + extension;
    var byteCharacters = atob(base64);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    let objType = 'image/jpeg';
    const ext = extension.toLowerCase();
    if (ext === ".png") objType = 'image/png';
    if (ext === ".pdf") objType = 'application/pdf';
    if (ext === ".doc") objType = 'application/msword';
    if (ext === ".docx") objType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    // convert downloaded data to a Blob
    var blob = new Blob([byteArray], { type: objType });

    // create an object URL from the Blob
    var URL = window.URL || window.webkitURL;
    var downloadUrl = URL.createObjectURL(blob);
    if (company === COMPANY_EVERS) {
        //task #7370
        window.open(downloadUrl, '_blank')
    } else {
        // set object URL as the anchor's href
        downloadLink.href = downloadUrl;

        // append the anchor to document body
        document.body.appendChild(downloadLink);

        // fire a click event on the anchor
        downloadLink.click();

        // cleanup: remove element and revoke object URL
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(downloadUrl);
    }


}