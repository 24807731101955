import { createSelector } from 'reselect';
import moment from 'moment';

import { SHOWN_TYPE_OFFENE } from '../helpers/constants';

import DateHelper from '../helpers/date-helper';
import mergeChangesWithAppointment from '../helpers/appointment-changes-merge';


 let tds = () => createSelector(
    (state) => { return state.tasks.appointments },
    (state) => { return state.tasks.enteredData },
    (state) => { return state.tasks.shownType },
    (state, props) => { return props.day },
    (state) => { return state.app.company},
    (state) => { return state.tasks.notes},
    (apps, enteredData, shownType, day, company, notes) => {

        const dh = new DateHelper();
        const date = dh.getDateByIndex(day);
        const dayApps = apps.filter(app => {
            const appDuration = dh.appDuration(moment(app.StartDate),moment(app.EndDate));
            if(appDuration===0)//ako appointment traje 1 dan
            {
                return dh.isTheSameDate(moment(app.StartDate), date);
            }
            else
            {
                let previous = dh.isTheSameDate(moment(app.StartDate), date);
                for(let i =0;i<=appDuration;i++)
                {
                    previous += dh.isTheSameDate(moment(app.StartDate).add(i,'d'), date);
                }
                return previous;
            }
        });

        //Sort by date
        dayApps.sort((a, b) => {
            return new Date(a.StartDate) - new Date(b.StartDate);
        });

       // console.log("Sorted days :", dayApps);

        // Menck *******************************************************
         let dayAppsMenck = null, mergeBeforeLoad = null, merged = null;

         if(company === "MENCK" || company === "LOEFFEL"){
             // Merge podataka da bi novi appointmenti za nedodjeljene servise imali start date prije synca
            mergeBeforeLoad = apps.map(app => {
                const modifiedData = enteredData.find(c => c.IDService === app.IDService && c.UniqueID===app.UniqueID);
                if (!modifiedData) return app;
                return mergeChangesWithAppointment(app, modifiedData);
            });
            dayAppsMenck = mergeBeforeLoad.filter(app => {
                    return app.StartDate !== null && dh.isTheSameDate(moment(app.StartDate), date);              
            });
         }
        // Menck end *******************************************************
         else{
            merged = dayApps.map(app => {
                const modifiedData = enteredData.find(c => c.IDService === app.IDService && c.UniqueID===app.UniqueID);
                if (!modifiedData) return app;
                return mergeChangesWithAppointment(app, modifiedData);
            })
         }
        

        switch(company){
            case "MENCK":
                return dayAppsMenck.filter(app => app.Finished === (shownType === SHOWN_TYPE_OFFENE ? 0 : 1));
            case "LOEFFEL":
                return dayAppsMenck.filter(app => app.Finished === (shownType === SHOWN_TYPE_OFFENE ? 0 : 1)).sort((a, b) => {return new Date(a.StartDate) - new Date(b.StartDate);});
            case "BROEMSE":
                let appMerge = merged.filter(app => app.Finished === (shownType === SHOWN_TYPE_OFFENE ? 0 : 1));
                return { app: appMerge, notes }
                default:
            return merged.filter(app => app.Finished === (shownType === SHOWN_TYPE_OFFENE ? 0 : 1));
        }

    }
);

export default tds;