import DBTasks from '../db/DbTasks';
import { FOTO_ADD, FOTO_REMOVE, SAVE_SIGNATURES, SAVE_TV_DATA, SAVE_TV_PROPERTY_VALUE, SELECT_APPOINTMENT_BY_ID, SET_SHOWN_DAY, SET_TV_PROPERTY_VALUE,
     SAVE_UNASSIGNED_DATES, SET_TV_PROPERTY_VALUE_FOR_ROW, SAVE_TV_DATA_FOLGETERMIN, DOWNLOAD_FILE_MANUALLY, SET_DOWNLOAD_IN_PROGRESS } from './types';

export const SetShownDay = (val) => dispatch => {
    dispatch({
        type: SET_SHOWN_DAY,
        payload: val
    });
}

export const SavePropertyValue = (app, prop, value) => async (dispatch) => {
    console.log("Save property value ");
    const s = await (new DBTasks()).SavePropertyValue(app,prop, value);
    dispatch({
        type: SAVE_TV_PROPERTY_VALUE,
        app:app,
        prop: prop,
        enteredData: s
    });
}



export const SavePropertyValueForServiceProtokolPdf = (app, prop, value) => async (dispatch) => {
    console.log("Save property value for service protokol pdf");
    const s = await (new DBTasks()).SavePropertyValueForServiceProtokolPdf(app, prop, value);
    //const addPdfToAttachments = await (new DBTasks()).AddPdfToAttachments(idService, prop, value);
    dispatch({
        type: SAVE_TV_PROPERTY_VALUE,
        app:app,
        prop: prop,
        enteredData: s
    });
}

// export const SavePropertyValueForMenck = (idService, prop, value) => async (dispatch) => {
//     const s = await (new DBTasks()).SavePropertyValueForMenck(idService, prop, value);
//     dispatch({
//         type: SAVE_PROPERTY_VALUE_MENCK,
//         idService: idService,
//         prop: prop,
//         enteredData: s
//     });
// }

export const SavePropertyValueForTable = (app, prop, rowId, tableName, value) => async (dispatch) => {
    console.log("Save property value for table");
    const s = await (new DBTasks()).SavePropertyValueForTable(app, prop, rowId, tableName, value);
    dispatch({
        type: SAVE_TV_PROPERTY_VALUE,
        app:app,
        prop: prop,
        enteredData: s
    });
}
// export const SetPropertyValue = (idService, prop, value) => async (dispatch) => {
//     dispatch({
//         type: SET_TV_PROPERTY_VALUE,
//         idService: idService,
//         prop: prop,
//         value: value
//     });
// }
export const SetPropertyValueForTable = (app, prop, rowId, tableName, value) => async (dispatch) => {
    console.log("Save property value for table");    
    dispatch({
        type: SET_TV_PROPERTY_VALUE_FOR_ROW,
        app: app,
        prop: prop,
        rowId: rowId,
        tableName:tableName,
        value:value        
    });
}

export const DeleteTableRow = (app, prop, value) => async (dispatch) => {
    console.log("Delete table row");
    const s = await (new DBTasks()).DeleteTableRow(app, prop, value);
    dispatch({
        type: SAVE_TV_PROPERTY_VALUE,
        app:app,
        prop: prop,
        enteredData: s
    });
}

export const SaveData = (app, data) => async (dispatch) => {
    const s = await (new DBTasks()).SaveData(app, data);
    dispatch({
        type: SAVE_TV_DATA,
        app: app,
        enteredData: s
    });
}

export const SaveDataFolgetermin = (data) => async (dispatch) => {
    const s = await (new DBTasks()).SaveDataFolgetermin(data);
    dispatch({
        type: SAVE_TV_DATA_FOLGETERMIN,
        enteredData: s
    });
}

export const SaveSignatures = (app, value) => async (dispatch) => {
    const res = await (new DBTasks()).SaveSignatures(app, value);
    dispatch({
        type: SAVE_SIGNATURES,
        app:app,
        enteredData: res
    });
}
export const SaveSignaturesForServiceProtokol = (app, value) => async (dispatch) => {
    const res = await (new DBTasks()).SaveSignaturesForServiceProtokol(app, value);
    dispatch({
        type: SAVE_SIGNATURES,
        app:app,
        enteredData: res
    });
}

export const FotoAdd = (app, data) => async (dispatch) => {
    let res = await (new DBTasks()).FotoAdd(app, data);
    dispatch({
        type: FOTO_ADD,
        payload: res
    });
}

export const FotoRemove = (id) => async (dispatch) => {
    await (new DBTasks()).FotoRemove(id);
    dispatch({
        type: FOTO_REMOVE,
        payload: id
    });
}

export const SetPropertyValue = (app, prop, value) => async (dispatch) => {
    dispatch({
        type: SET_TV_PROPERTY_VALUE,
        app:app,
        prop: prop,
        value: value
    });
}
export const SaveUnassignedDates = (idService, prop, value) => async (dispatch) => {
    dispatch({
        type: SAVE_UNASSIGNED_DATES,
        idService: idService,
        prop: prop,
        value: value
    });
}

export const SelectAppointmentByID = (UniqueID, IDService) => dispatch => {
    dispatch({
        type: SELECT_APPOINTMENT_BY_ID,
        UniqueID: UniqueID,
        IDService:IDService
    })
}

export const DownloadFileManually = (settings, IDService, FileName, FileID) => async (dispatch) => {
    const s = await (new DBTasks()).DownloadFileManually(settings, IDService, FileName, FileID);
    if(!s) return s;
    dispatch({
        type: DOWNLOAD_FILE_MANUALLY,
        FileID: FileID,
        attachmentUpdate: s
    });
    return true;
}

export const SetDownloadInProgress = (enabled) => async (dispatch) => {
    dispatch({
        type: SET_DOWNLOAD_IN_PROGRESS,
        payload: enabled
    });
}