import { DO_BACKUP, REPORT_BACKUP_PROGRESS } from '../actions/types';

const initialState = {
   backupInProgress: false,
   backupProgressValue: 0
}

export default function backup(state = initialState, action) {
    //console.log("postReducer..", action);
    switch(action.type) {        
        case DO_BACKUP:
            return state;         
        case REPORT_BACKUP_PROGRESS:
            return {...state,backupInProgress: action.payload!==100 ,backupProgressValue:action.payload  }    
        default:        
        return state;
    }

}