import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import configureStore from './store';
import { SW_INIT, SW_UPDATE } from './actions/types';
import { Provider } from 'react-redux';


const store = configureStore();

ReactDOM.render(<Provider store={store}>
  <App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
serviceWorkerRegistration.register({
  onSuccess: () => store.dispatch({ type: SW_INIT }),
  onUpdate: registration =>
    store.dispatch({ type: SW_UPDATE, payload: registration }),
  onWaiting: waiting =>
    store.dispatch({ type: SW_UPDATE, payload: waiting })
});