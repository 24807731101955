import React, { Component } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import check from '../extensions/check';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SelectedAppSelector } from '../../selectors/SelectedAppointmentSelector';
import { SaveData } from '../../actions/TaskActions';
import Button from '@material-ui/core/Button';
import moment from 'moment';

import { Loeffel } from '../../assets/images/Base64logo';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
let i = check.pdfMake.vfs;
let j = pdfFonts.pdfMake.vfs;
pdfMake.vfs = { ...i, ...j };

pdfMake.fonts = {
    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-Italic.ttf'
    },
    Fontello: {
        normal: 'fontello.ttf',
        bold: 'fontello.ttf',
        italics: 'fontello.ttf',
        bolditalics: 'fontello.ttf'
    }
}

class GeneratePdf extends Component {

    componentDidMount() {
        moment.locale("de");
    }



    downloadPDF = () => {
        let { MaterialList, ZeitList, ServiceMangelbeschreibung, PauschalKleinMaterial, PauschalMaterial, ServiceNr, StartDate, ProjektNr, Besorgungsfahrt,
            ProjekteKomision, Firma, Berater, ekName, ekOrt, ekStrasse, ekTel1, ekTel2, SignatureKunde, Finished, ekHandy, ekHandy2, PrintNotiz, ErledigteArbeiten, Eingang } = this.props.app;

        const bodyDataMaterialList = [
            [{
                columns: [
                    {
                        text: 'Material', bold: true, fontSize: 10
                    },
                    {
                        text: [{ text: `${PauschalKleinMaterial === true ? '' : ''} `, style: 'icon' }, ` pauschal Kleinmaterial`,], bold: true, fontSize: 10
                    },
                    {
                        text: [{ text: `${PauschalMaterial === true ? '' : ''} `, style: 'icon' }, ` pauschal Material`], bold: true, fontSize: 10
                    }
                ],
                style: 'checkFirst'
            },
            {
                text: `Stück`, bold: true, fontSize: 10
            }],

        ]
        MaterialList = MaterialList || [];
        MaterialList.forEach(function (e) {
            let arr = [];
            let item = { text: `${e.Material}`, alignment: 'left' };
            arr.push(item);
            let i = { text: `${e.Stuck}`, alignment: 'left' };
            arr.push(i);
            bodyDataMaterialList.push(arr);

        });

        Besorgungsfahrt = Besorgungsfahrt || [];
        const bodyDataBesorgungsfahrt = [
            [{
                text: 'Fahrt zu', bold: true, fontSize: 10
            },
            {
                text: 'Service Mitarbeiter ', bold: true, fontSize: 10
            },
            {
                text: 'Datum', bold: true, fontSize: 10
            },
            {
                text: 'Kilometer', bold: true, fontSize: 10
            },
            {
                text: 'Dauer (Stunden)', bold: true, fontSize: 10
            }]
        ];

        Besorgungsfahrt.forEach(function (e) {
            let arr = [];
            let j = { text: `${e.FahrtZu}`, alignment: 'left' };
            arr.push(j);
            let k = { text: `${e.ServiceMitarbeiter}`, alignment: 'left' };
            arr.push(k);
            let m = { text: `${e.Datum}`, alignment: 'left' };
            arr.push(m);
            let l = { text: `${e.Kilometer}`, alignment: 'left' };
            arr.push(l);
            let n = { text: `${e.Dauer}`, alignment: 'left' };
            arr.push(n);

            bodyDataBesorgungsfahrt.push(arr);

        });


        const bodyDataZeitList = [
            [{
                text: 'Servicemitarbeiter:', bold: true, fontSize: 10
            },
            {
                text: 'Datum ', bold: true, fontSize: 10
            },
            {
                text: 'Arbeitszeit (Stunden) ', bold: true, fontSize: 10
            }]
        ];
        ZeitList = ZeitList || [];
        ZeitList.forEach(function (e) {
            let arr = [];
            let j = { text: `${e.ServiceMitarbeiter}`, alignment: 'left' };
            arr.push(j);
            let k = { text: `${e.Datum}`, alignment: 'left' };
            arr.push(k);
            let m = { text: `${e.Arbeitszeit}`, alignment: 'left' };
            arr.push(m);

            bodyDataZeitList.push(arr);

        });

        const bodyDataServiceMangelbeschreibung = [
            [{ text: 'Pos', border: [false, false, false, false], bold: true },
            { text: 'Anzahl', border: [false, false, false, false], bold: true },
            { text: 'Beschreibung', border: [false, false, false, false], bold: true },
            { text: 'Notiz', border: [false, false, false, false], bold: true }]
        ];
        let ServiceMangelbeschreibungParsed = JSON.parse(ServiceMangelbeschreibung || "[]");
        ServiceMangelbeschreibungParsed.forEach(function (e) {
            let arr = [];
            let j = { text: ` ${e.Position || ""}`, alignment: 'left', margin: [5, 0, 0, 0] };
            arr.push(j);
            let k = { text: `${e.Anzahl || ""}`, alignment: 'left' };
            arr.push(k);
            let m = { text: `${e.Mangelbechreibung || ""}`, alignment: 'left' };
            arr.push(m);
            let n = { text: `${e.Notiz || ""}`, alignment: 'left' };
            arr.push(n);

            bodyDataServiceMangelbeschreibung.push(arr);

        });

        let notizTableContent = [
            [{text: 'Notiz:', bold: true, fontSize: 10 , border: [true, true, true, false]}],
            [{text: `${ErledigteArbeiten}` , fontSize: 10 , border: [true, false, true, true]}]];

        let doNotShowNotiz = [[{text:'', border: [false, false, false, false]}]];

        let docDefinition = {
            content: [
                { text: 'ARBEITSBERICHT', style: 'headerTitle'},
                {
                    image: Loeffel,
                    width: 170,
                    margin: [350, -38, 0, 5],
                },
                { text: `Auftragseingang: ${moment(Eingang).format('DD.MM.YYYY')}
                         zum Arbeitsauftrag Nr. ${ServiceNr}    vom ${moment(StartDate).format('DD.MM.YYYY HH:MM')}`, border: [false, true, false, false], style: 'header' },

                {
                    style: 'tableExampleInfo',
                    table: {
                        widths: [70, '*', '*', '*'],
                        body: [
                            [{ text: `Bv.Nr.:  `, bold: true }, `${ProjektNr || ""}`, { text: `Komission: `, bold: true, alignment: 'right' }, `${ProjekteKomision || ""}`],
                            [{ text: `Auftragebber: `, bold: true }, `${Firma || ""}`, { text: `Bearbeiter: `, bold: true, alignment: 'right' }, `${Berater || ""}`],
                            [{ text: `Kunde: `, bold: true, alignment: 'left' }, `${ekName || ""}`, ``, ``],
                            [``, `${ekStrasse || ""}`, ``, ``],
                            [``, `${ekOrt || ""}`, ``, ``],
                            [{ text: `Telefon:`, bold: true }, `${ekTel1 || ""}`, { text: `Mobil:`, bold: true, alignment: 'right' }, `${ekHandy || ""}`],
                            [{ text: ``, bold: true }, `${ekTel2 || ""}`, { text: ``, bold: true, alignment: 'right' }, `${ekHandy2 || ""}`],
                        ]
                    },
                    // layout: 'noBorders'
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 1 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 0 : 0;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'white';
                        },
                        // vLineColor: function (i, node) {
                        //     return (i === 0 || i === node.table.widths.length) ? 'black' : 'red';
                        // },
                        // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        // paddingLeft: function(i, node) { return 4; },
                        // paddingRight: function(i, node) { return 4; },
                        // paddingTop: function(i, node) { return 2; },
                        // paddingBottom: function(i, node) { return 2; },
                        // fillColor: function (rowIndex, node, columnIndex) { return null; }
                    }
                },

                { text: 'Arbeitsbeschreibung:', style: 'subheader' },
                {
                    style: 'tableExample',
                    table: {
                        widths: [50, 50, '*', '*'],
                        body: bodyDataServiceMangelbeschreibung
                    },
                    layout: 'lightHorizontalLines'
                },
                {
                    style: 'tableExample',
                    table: {
                        widths: ['*', 30],
                        body: bodyDataMaterialList
                    }
                },

                {
                    style: 'tableExample',
                    table: {
                        widths: ['*', '*', '*', '*', '*'],
                        body: bodyDataBesorgungsfahrt
                    }
                },

                {
                    style: 'tableExample',
                    table: {
                        widths: ['*', '*', '*'],
                        body: bodyDataZeitList
                    }
                },
                {
                    style: 'tableExample',
                    table: {
                        widths: ['*'],
                        body: PrintNotiz === 1 ? notizTableContent : doNotShowNotiz

                    }
                },
                {
                    style: 'tableUnterschrift',
                    table: {
                        widths: ['*', 70, 150],
                        body: [
                            [
                                {
                                    text: [{ text: `${Finished === 1 ? '' : ''} `, style: 'icon' }, ` Auftrag abgeschlossen`], bold: true, fontSize: 10
                                    , border: [false, false, false, false]
                                },
                                { text: moment(new Date()).format('DD.MM.YYYY'), border: [false, false, false, true], margin: [0, 25, 0, 0], fontSize: 10, bold: true },
                                SignatureKunde == null ? { text: '', width: 100, border: [false, false, false, true] } : { image: `${SignatureKunde}`, width: 100, border: [false, false, false, true] }
                            ],
                            [
                                { text: '', border: [false, false, false, false] },
                                { text: '', border: [false, false, false, false] },
                                { text: 'Datum / Unterschrift Kunde:', border: [false, false, false, false], bold: true }]

                        ]
                    }
                },
            ],

            footer: function(currentPage, pageCount){
                return  {
                    style: 'tableFooter',
                    table: {
                        widths: ['*',],
                        body: [
                            [
                                {
                                    text: [
                                        `Kurzfahrten unter 5 km berechnen wir mit 17 € inkl. Fahrtkostenanteil.
                                        Service-Techniker-Std. / Arbeits- und Fahrzeit: (66,- €/Std.)
                                        Fahrtkosten je km (1,05 € / km),  Fahrzeit und -kilometer werden für Hin- und Rückfahrt berechnet.`,
                                    ]
                                }
                            ]
                        ]
                    },
                    layout: 'noBorders'
                }
            },
            styles: {
                header: {
                    fontSize: 12,
                    bold: true,
                    margin: [0, 10, 0, 0],
                },
                headerTitle: {
                    fontSize: 24,
                    bold: true,
                    margin: [0, 0, 0, 5],
                },
                subheader: {
                    fontSize: 12,
                    bold: true,
                    margin: [0, 0, 0, 0]
                },
                subheaderQuestion: {
                    fontSize: 10,
                    bold: true,
                    margin: [0, 0, 0, 5]
                },
                subheader1: {
                    fontSize: 10,
                    bold: true
                },
                tableExample: {
                    margin: [0, 5, 0, 5],
                    fontSize: 9,
                },
                tableExampleInfo: {
                    margin: [0, 5, 0, 5],
                    fontSize: 10,
                },
                tableFooter: {
                    fontSize: 9,
                    margin: [40, -30, 0, 0],
                },
                check: {
                    fontSize: 10
                },
                checkFirst: {
                    fontSize: 9,
                    margin: [0, 0, 0, 0]
                },
                icon: {
                    font: 'Fontello',
                    fontSize: 12
                },
                tableHeader: {
                    bold: false,
                    fontSize: 10,
                    color: 'black'
                },
                tableMargin: {
                    margin: [0, -5, 0, 0]
                },
                tableUnterschrift: {
                    fontSize: 8,
                    margin: [0, 5, 0, 5],
                },
                footer: {
                    fontSize: 6
                }
            },
            defaultStyle: {
                // alignment: 'justify'
            }

        }
        //pdfMake.createPdf(docDefinition).open();
        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.getBase64((data) => {
            this.props.SaveData(this.props.app, { pdf: data });
            this.props.history.goBack();
        });
    }
    render() {
        return (
            <div>
                <Button color="primary" variant="contained" onClick={this.downloadPDF}>Speichern</Button>
            </div>
        )
    }
}

const mapStateToProps = (state) => (
    {
        app: SelectedAppSelector(state)
    }
)

export default withRouter(connect(mapStateToProps, { SaveData })(GeneratePdf));