import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import SigPadTransparent from "./extensions/SigPadTransparent";
import Button from "@material-ui/core/Button";
import {
    SetPropertyValue,
    SavePropertyValue,
    SaveSignatures,
    SelectAppointmentByID,
    SaveData
} from "../actions/TaskActions";
import { SelectedAppSelector , SelectedAppPhotosSelector} from "../selectors/SelectedAppointmentSelector";

import { setTitle } from "../actions/AppActions";
import GeneratePdf from './broemse/GeneratePdf';
const styles2 = {
    card: {
        margin: "20px",
        border: "1px solid #999"
    }
};

class TaskSign extends Component {
    sigPadKunde = {};
    sigPadBerater = {};

    componentDidMount() {
        this.props.setTitle("Serviceauftrag Daten einfügen");
        this.props.SelectAppointmentByID(this.props.match.params.id, this.props.match.params.service);
        //console.log("mounterd");
    }

    handleClearKunde = () => {
        this.sigPadKunde.clear();
    };

    handleClearBerater = () => {
        this.sigPadBerater.clear();
    };

    UpdateKundeSignature = () => {
        if (this.sigPadKunde)
            this.sigPadKunde.fromDataURLTransparent(
                this.props.app.SignatureKunde,
                { width: 280, height: 60 }
            );
    };

    UpdateBeraterSignature = () => {
        if (this.sigPadBerater)
            this.sigPadBerater.fromDataURLTransparent(
                this.props.app.SignatureBerater,
                { width: 280, height: 60 }
            );
    };

    handleSave = () => {

        const berSignature = this.sigPadBerater.toDataURLTransparent(
            "image/png"
        );
        const kundeSignature = this.sigPadKunde.toDataURLTransparent(
            "image/png"
        );
        
            let hasBild = this.props.fotos.length;

        const value = {
            SignatureBerater: berSignature,
            SignatureKunde: kundeSignature,
            AdditionalServiceNeeded: this.props.app.AdditionalServiceNeeded,
            Company: this.props.company,
            hasBild: hasBild
        };
        if(this.props.company === "BROEMSE"){
            this.props.SaveData(this.props.app, value);
        }else{
            this.props.SaveSignatures(this.props.app, value);
            this.props.history.goBack();
        }
        // this.props.SaveSignatures(this.props.app, value);
        // if(this.props.company !== "BROEMSE") this.props.history.goBack();
    };

    handleChange = (e, checked) => {
        this.props.SetPropertyValue(
            this.props.app,
            e.target.name,
            checked
        );
    };

    doNothing = () => {};

    render() {
        if (!this.props.app.StartDate) return <div />;
        let company = this.props.company;
        return (
            <div style={{ marginTop: "70px" }}>
                <Paper elevation={1} style={styles2.card}>
                    <Typography variant="h5" component="h3">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        this.props.app.AdditionalServiceNeeded || false
                                    }
                                    onChange={this.handleChange}
                                    name="AdditionalServiceNeeded"
                                    value="checkedB"
                                    color="primary"
                                />
                            }
                            label="Weiterer Service Notwendig"
                        />
                    </Typography>
                </Paper>
                <Paper elevation={1} style={styles2.card}>
                    <Typography variant="h5" component="h3">
                        <FormControlLabel
                            labelPlacement="bottom"
                            control={
                                <SigPadTransparent
                                    ref={ref => {
                                        this.sigPadBerater = ref;
                                        this.UpdateBeraterSignature();
                                    }}
                                    onEnd={company === "BROEMSE" ?  this.handleSave : this.doNothing}
                                    backgroundColor="rgba(200,200,200,1)"
                                    penColor="blue"
                                    canvasProps={{
                                        width: 280,
                                        height: 60,
                                        className: "sigCanvas"
                                    }}
                                />
                            }
                            label="Unterschrift Techniker"
                        />
                    </Typography>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={this.handleClearBerater}
                    >
                        Clear
                    </Button>
                </Paper>
                <Paper elevation={1} style={styles2.card}>
                    <Typography variant="h5" component="h3">
                        <FormControlLabel
                            labelPlacement="bottom"
                            control={
                                <SigPadTransparent
                                    ref={ref => {
                                        this.sigPadKunde = ref;
                                        this.UpdateKundeSignature();
                                    }}
                                    onEnd={company === "BROEMSE" ?  this.handleSave : this.doNothing}
                                    backgroundColor="rgba(200,200,200,1)"
                                    penColor="blue"
                                    canvasProps={{
                                        width: 280,
                                        height: 60,
                                        className: "sigCanvas"
                                    }}
                                />
                            }
                            label="Unterschrift Endkunde"
                        />
                    </Typography>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={this.handleClearKunde}
                    >
                        Clear
                    </Button>
                </Paper>

                {company === "BROEMSE" ? <GeneratePdf /> :
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={this.handleSave}
                    >
                        Save
        </Button>
                }

            </div>
        );
    }
}

const mapStateToProps = state => ({
    app: SelectedAppSelector(state),
    company: state.app.company,
    fotos: SelectedAppPhotosSelector(state),
});

export default withRouter(
    connect(
        mapStateToProps,
        {
            setTitle,
            SetPropertyValue,
            SavePropertyValue,
            SaveSignatures,
            SelectAppointmentByID,
            SaveData
        }
    )(TaskSign)
);
