import React, { Component } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import check from '../extensions/check';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SelectedAppSelector } from '../../selectors/SelectedAppointmentSelector';
import { SaveData, SetPropertyValue } from '../../actions/TaskActions';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import SendEmailDialog from '../dialogs/menck/SendEmailDialog';

import DBTasks from '../../db/DbTasks';

import { MenckLogo, MontageMenck1, MontageMenck2 } from '../../assets/images/Base64logo';
import { ToastContainer } from "react-toastify";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
let i = check.pdfMake.vfs;
let j = pdfFonts.pdfMake.vfs;
pdfMake.vfs = { ...i, ...j };

pdfMake.fonts = {
    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-Italic.ttf'
    },
    Fontello: {
        normal: 'fontello.ttf',
        bold: 'fontello.ttf',
        italics: 'fontello.ttf',
        bolditalics: 'fontello.ttf'
    }
}

class GeneratePdf extends Component {

    state = {
        dialog: false,
        TakePhotoOpen: false,
        images: this.props.images || [],
        _deleteImageId: -1,
        _deleteImageMessageOpen: false,
        questionDialogOpen: false,
        questionFinishDialogOpen: false,
        dialogApp: {},
        dialogImages: [],
        selectDateTimeDialogOpen: false,
        type: 1,
        confirmationDialogOpen: false,
        downloadFileDialogOpen: false,
        fileNameForDownload: "",
        fileExtForDownload : "",
        fileIdForDownload: -1,
        downloadInProgress: false
    };

    handleCloseDialogs = () => {
        this.setState({
            ...this.state,
            downloadFileDialogOpen: false,
        });
    };

    handleCloseDialogsConfirmation = () => {
        this.setState({
            ...this.state,
            confirmationDialogOpen: false,
            type: 1
        });
    };

    downloadFile = () => {
        // console.log("File ID:", this.state.fileForDownloadID);
      alert("Download");
    }

    componentDidMount() {
        moment.locale("de");
    }

    sendEmail = () => {
        let db = new DBTasks().sendEmail;
        db();
    }

    downloadPDF = () => {
        let { MaterialList, ZeitList, ServiceMangelbeschreibung, PauschalKleinMaterial, PauschalMaterial, ServiceNr, StartDate, ProjektNr, Besorgungsfahrt,
            ProjekteKomision, Firma, Berater, ekName, ekOrt, ekStrasse, ekTel1, ekTel2, ekPLZ, SignatureKunde, Finished } = this.props.app;

        const bodyDataMaterialList = [
            [{
                columns: [
                    {
                        text: 'Material', bold: true, fontSize: 10
                    },
                    {
                        text: [{ text: `${PauschalKleinMaterial === true ? '' : ''} `, style: 'icon' }, ` pauschal Kleinmaterial`,], bold: true, fontSize: 10
                    },
                    {
                        text: [{ text: `${PauschalMaterial === true ? '' : ''} `, style: 'icon' }, ` pauschal Material`], bold: true, fontSize: 10
                    }
                ],
                style: 'checkFirst'
            },
            {
                text: `Stück`, bold: true, fontSize: 10
            }],

        ]
        MaterialList = MaterialList || [];
        MaterialList.forEach(function (e) {
            let arr = [];
            let item = { text: `${e.Material}`, alignment: 'left' };
            arr.push(item);
            let i = { text: `${e.Stuck}`, alignment: 'left' };
            arr.push(i);
            bodyDataMaterialList.push(arr);

        });

        Besorgungsfahrt = Besorgungsfahrt || [];
        const bodyDataBesorgungsfahrt = [
            [{
                text: 'Fahrt zu', bold: true, fontSize: 10
            },
            {
                text: 'Service Mitarbeiter ', bold: true, fontSize: 10
            },
            {
                text: 'Datum', bold: true, fontSize: 10
            },
            {
                text: 'Kilometer', bold: true, fontSize: 10
            },
            {
                text: 'Dauer (Stunden)', bold: true, fontSize: 10
            }]
        ];

        Besorgungsfahrt.forEach(function (e) {
            let arr = [];
            let j = { text: `${e.FahrtZu}`, alignment: 'left' };
            arr.push(j);
            let k = { text: `${e.ServiceMitarbeiter}`, alignment: 'left' };
            arr.push(k);
            let m = { text: `${e.Datum}`, alignment: 'left' };
            arr.push(m);
            let l = { text: `${e.Kilometer}`, alignment: 'left' };
            arr.push(l);
            let n = { text: `${e.Dauer}`, alignment: 'left' };
            arr.push(n);

            bodyDataBesorgungsfahrt.push(arr);

        });


        const bodyDataZeitList = [
            [{
                text: 'Servicemitarbeiter:', bold: true, fontSize: 10
            },
            {
                text: 'Datum ', bold: true, fontSize: 10
            },
            {
                text: 'Arbeitszeit (Stunden) ', bold: true, fontSize: 10
            }]
        ];
        ZeitList = ZeitList || [];
        ZeitList.forEach(function (e) {
            let arr = [];
            let j = { text: `${e.ServiceMitarbeiter}`, alignment: 'left' };
            arr.push(j);
            let k = { text: `${e.Datum}`, alignment: 'left' };
            arr.push(k);
            let m = { text: `${e.Arbeitszeit}`, alignment: 'left' };
            arr.push(m);

            bodyDataZeitList.push(arr);

        });

        const bodyDataServiceMangelbeschreibung = [
            [{ text: 'Pos', border: [false, false, false, false], bold: true },
            { text: 'Anzahl', border: [false, false, false, false], bold: true },
            { text: 'Beschreibung', border: [false, false, false, false], bold: true },
            { text: 'Notiz', border: [false, false, false, false], bold: true }]
        ];
        let ServiceMangelbeschreibungParsed = JSON.parse(ServiceMangelbeschreibung || "[]");
        ServiceMangelbeschreibungParsed.forEach(function (e) {
            let arr = [];
            let j = { text: ` ${e.Position || ""}`, alignment: 'left', margin: [5, 0, 0, 0] };
            arr.push(j);
            let k = { text: `${e.Anzahl || ""}`, alignment: 'left' };
            arr.push(k);
            let m = { text: `${e.Mangelbechreibung || ""}`, alignment: 'left' };
            arr.push(m);
            let n = { text: `${e.Notiz || ""}`, alignment: 'left' };
            arr.push(n);

            bodyDataServiceMangelbeschreibung.push(arr);

        });

        let docDefinition = {
            content: [
                { text: `Arbeitsbericht Nr. ${ServiceNr}    Termin ${moment(StartDate).format('DD.MM.YYYY HH:MM')}`, border: [false, true, false, false], style: 'header' },
                {
                    image: MenckLogo,
                    width: 70,
                    margin: [420, 0, 0, 0],
                },
                {
                    style: 'tableExampleInfo',
                    table: {
                        widths: [70, '*', '*', '*'],
                        body: [
                            [{ text: `Bv.Nr.:  `, bold: true }, `${ProjektNr || ""}`, { text: `Komission: `, bold: true, alignment: 'right' }, `${ProjekteKomision || ""}`],
                            [{ text: `Auftragebber: `, bold: true }, `${Firma || ""}`, { text: `Bearbeiter: `, bold: true, alignment: 'right' }, `${Berater || ""}`],
                            [{ text: `Kunde: `, bold: true, alignment: 'left' }, `${ekName || ""}`, ``, ``],
                            [``, `${ekStrasse || ""}`, ``, ``],
                            [``, `${ekPLZ || ""} ${ekOrt || ""}`, ``, ``],
                            [{ text: `Telefon:`, bold: true }, `${ekTel1 || ""}`, { text: `Mobil:`, bold: true, alignment: 'right' }, `${ekTel2 || ""}`],
                        ]
                    },
                    // layout: 'noBorders'
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 1 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 0 : 0;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'white';
                        },
                        // vLineColor: function (i, node) {
                        //     return (i === 0 || i === node.table.widths.length) ? 'black' : 'red';
                        // },
                        // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        // paddingLeft: function(i, node) { return 4; },
                        // paddingRight: function(i, node) { return 4; },
                        // paddingTop: function(i, node) { return 2; },
                        // paddingBottom: function(i, node) { return 2; },
                        // fillColor: function (rowIndex, node, columnIndex) { return null; }
                    }
                },

                { text: 'Arbeitsbeschreibung:', style: 'subheader' },
                {
                    style: 'tableExample',
                    table: {
                        widths: [50, 50, '*', '*'],
                        body: bodyDataServiceMangelbeschreibung
                    },
                    layout: 'lightHorizontalLines'
                },
                {
                    style: 'tableExample',
                    table: {
                        widths: ['*', 30],
                        body: bodyDataMaterialList
                    }
                },

                {
                    style: 'tableExample',
                    table: {
                        widths: ['*', '*', '*', '*', '*'],
                        body: bodyDataBesorgungsfahrt
                    }
                },

                {
                    style: 'tableExample',
                    table: {
                        widths: ['*', '*', '*'],
                        body: bodyDataZeitList
                    }
                },
                {
                    style: 'tableUnterschrift',
                    table: {
                        widths: ['*', 70, 150],
                        body: [
                            [
                                {
                                    text: [{ text: `${Finished === 1 ? '' : ''} `, style: 'icon' }, ` Auftrag abgeschlossen`], bold: true, fontSize: 10
                                    , border: [false, false, false, false]
                                },
                                { text: moment(new Date()).format('DD.MM.YYYY'), border: [false, false, false, true], margin: [0, 25, 0, 0], fontSize: 10, bold: true },
                                SignatureKunde == null ? { text: '', width: 100, border: [false, false, false, true] } : { image: `${SignatureKunde}`, width: 100, border: [false, false, false, true] }
                            ],
                            [
                                { text: '', border: [false, false, false, false] },
                                { text: '', border: [false, false, false, false] },
                                { text: 'Datum / Unterschrift Kunde:', border: [false, false, false, false], bold: true }]

                        ]
                    }
                },
                {
                    style: 'tableFooter',
                    table: {
                        widths: ['*', '*', '*', 30, 30],
                        body: [
                            [
                                {
                                    text: [
                                        { text: 'Geschäftsführer\n', decoration: 'underline' },
                                        `Dipl.-Holzwirt Rolf Menck
                                 Kai Philipp Ehrig\n`,
                                        { text: 'Handelsregister\n', decoration: 'underline' },
                                        `Hamburg HRB 107572
                                 http://www.menck-fenster.de`
                                    ]
                                },
                                {
                                    text: [
                                        { text: 'Menck Hamburg\n', decoration: 'underline' },
                                        `Sinstorfer Weg 70
                                 21077 Hamburg
                                 Tel.: +49 (0) 40 / 760 106-0
                                 Tel.: +49 (0) 40 / 760 106-10
                                 hamburg@menck-fenster.de`
                                    ]
                                },
                                {
                                    text: [
                                        { text: 'Menck Schwerin\n', decoration: 'underline' },
                                        `Nordring 27
                                 19073 Wittenförden
                                 Tel.: +49 (0) 385 / 644 77-0
                                 Tel.: +49 (0) 385 / 644 77-10
                                 Tel.: +49 (0) 385 / 644 77-10`
                                    ]
                                },
                                {
                                    image: MontageMenck1,
                                    width: 30,
                                    margin: [0, 0, 0, 0],
                                },
                                {
                                    image: MontageMenck2,
                                    width: 30,
                                    margin: [0, 0, 0, 0],
                                },

                            ]
                        ]
                    },
                    layout: 'noBorders'
                }
            ],
            styles: {
                header: {
                    fontSize: 12,
                    bold: true,
                    margin: [0, 40, 0, -45],
                },
                subheader: {
                    fontSize: 12,
                    bold: true,
                    margin: [0, 0, 0, 0]
                },
                subheaderQuestion: {
                    fontSize: 10,
                    bold: true,
                    margin: [0, 0, 0, 5]
                },
                subheader1: {
                    fontSize: 10,
                    bold: true
                },
                tableExample: {
                    margin: [0, 5, 0, 5],
                    fontSize: 9,
                },
                tableExampleInfo: {
                    margin: [0, 5, 0, 5],
                    fontSize: 10,
                },
                tableFooter: {
                    fontSize: 9
                },
                check: {
                    fontSize: 10
                },
                checkFirst: {
                    fontSize: 9,
                    margin: [0, 0, 0, 0]
                },
                icon: {
                    font: 'Fontello',
                    fontSize: 12
                },
                tableHeader: {
                    bold: false,
                    fontSize: 10,
                    color: 'black'
                },
                tableMargin: {
                    margin: [0, -5, 0, 0]
                },
                tableUnterschrift: {
                    fontSize: 8,
                    margin: [0, 5, 0, 5],
                },
                footer: {
                    fontSize: 6
                }
            },
            defaultStyle: {
                // alignment: 'justify'
            }

        }
         //pdfMake.createPdf(docDefinition).open();
        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.getBase64((data) => {
            this.props.SaveData(this.props.app, { pdf: data });
            //this.props.history.goBack();
        });
        this.setState({...this.state,
            downloadFileDialogOpen: true,
         });
    }
    render() {
        return (
            <div>
                <Button color="primary" variant="contained" onClick={this.downloadPDF}>Speichern</Button>
                {/* <Button color="primary" variant="contained" onClick={this.sendEmail}>Speichern</Button> */}
               {/* <a href="mailto:almedin.bucajets@gmail.com">Send me a mail</a> */}
               <SendEmailDialog
                    open={this.state.downloadFileDialogOpen}
                    handleClose={this.handleCloseDialogs}
                    downloadFile={this.downloadFile}
                    downloadInProgress={this.state.downloadInProgress}
                    email={this.props.app.Email}
                    pdf={this.props.app.pdf}
                    settings={this.props.settings}
                />
                 <ToastContainer autoClose={8000} />
            </div>
        )
    }
}

const mapStateToProps = (state) => (
    {
        app: SelectedAppSelector(state),
        settings: state.settings
    }
)

export default withRouter(connect(mapStateToProps, { SaveData, SetPropertyValue })(GeneratePdf));