import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import TableServiceProtokol from './TableServiceProtokol';
import { EnteredDataForServicePDF } from '../../selectors/SelectedAppointmentSelector';
import { SelectAppointmentByID, SavePropertyValue } from '../../actions/TaskActions';
import { setTitle } from '../../actions/AppActions';
import TablesPrufProtokol from './TablesPrufProtokol';
import GeneratePdfPrufProtokol from './GeneratePdfPrufProtokol';

const styles = {
    card: {
        margin: '20px',
        border: '1px solid #999',
        width: 'inherit'
    },
    alignment: {
        margin: '0px auto 0px auto'
    },
    input: {
        width: '100%'
    }
}

class TaskSignPruf extends Component {

    componentDidMount() {
        this.props.setTitle("Prüfmaßnahmen Daten einfügen");
        this.props.SelectAppointmentByID(this.props.match.params.id,this.props.match.params.service);
        console.log("mounted");
    }
    handleChange = (e, checked) => {
        this.props.SavePropertyValue(this.props.app, e.target.name, checked);
    }
    handleChangeRadioButton = event => {
        this.props.SavePropertyValue(this.props.app, event.target.name, event.target.value);
    };
    render() {
        const { ServiceTyp, FuhrungsschienenMontiert, FunktionstestAnlage, FotosGemacht } = this.props.app;
        return (
            <div style={{ marginTop: '70px' }}>
                <Paper elevation={1} style={styles.card}>
                    <div>
                    </div>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Servicetyp</FormLabel>
                        <RadioGroup
                            aria-label="Servicetyp"
                            name="ServiceTyp"
                            className=""
                            value={ServiceTyp}
                            onChange={this.handleChangeRadioButton}
                        >
                            <FormControlLabel name="kundendienst" value="kundendienst" control={<Radio />} label="Kundendienst" />
                            <FormControlLabel name="beratung" value="beratung" control={<Radio />} label="Beratung / Begutachtung" />
                            <FormControlLabel name="serviceleistung" value="serviceleistung" control={<Radio />} label="Serviceleistung / Reparatur" />
                        </RadioGroup>
                    </FormControl>
                </Paper>
                <TablesPrufProtokol />
                <Paper elevation={5} style={styles.card}>
                <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={FuhrungsschienenMontiert === true ? true : false} name="FuhrungsschienenMontiert" value="checkedB" color="primary"
                            />
                        }
                        label="Führungsschienen richtig montiert?"
                    />
                     <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={FunktionstestAnlage === true ? true : false} name="FunktionstestAnlage" value="checkedB" color="primary"
                            />
                        }
                        label="Funktionstest Anlage durchgeführt?"
                    />
                     <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={FotosGemacht === true ? true : false} name="FotosGemacht" value="checkedB" color="primary"
                            />
                        }
                        label="Fotos gemacht?"
                    />
                </Paper>
                <Paper elevation={6} style={styles.card}>
                    <TableServiceProtokol />
                </Paper>
                <GeneratePdfPrufProtokol />
            </div>
        )
    }
}

function mapStateToProps(state) {
    console.log("MapStateToProps: ", state);
    return {
         app: EnteredDataForServicePDF(state)
    }
}


export default withRouter(connect(mapStateToProps,{SavePropertyValue, SelectAppointmentByID, setTitle})(TaskSignPruf));