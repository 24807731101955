import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const notification = (type, message) => {
    switch (type) {
        case "warning":
            return toast(message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                className: "toast-warning-color"
            });
        case "error":
            return toast(message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                className:"toast-error-color"
            });
            case "success":
            return toast(message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                className: "toast-success-color"
            });
            case "nonEditable":
            return toast(message, {
                position: "bottom-center",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                className: "toast-warning-color"
            });
        default:
            return toast("Info message");
    }
}

export default notification;
