import { SET_URL, SET_BERATER, SET_CHATURL, SAVE_SETTINGS, INITIALISE_STATE, SET_FILE_SIZE } from '../actions/types';
import { COMPANY } from '../settings';

const initialState = {
   id:1,
   URL:'',
   Berater: '',
   ChatURL:'',
   FileSize: 2,
   Company: COMPANY
}

export default function settings(state = initialState, action) {    
    switch(action.type) {        
        case INITIALISE_STATE:
            if (!action.settings) return state;
            return action.settings;
        case SET_URL:
            return {...state, URL: action.payload} 

        case SET_BERATER:
            return {...state, Berater: action.payload} 

        case SET_CHATURL:
            return {...state, ChatURL: action.payload} 
        
        case SET_FILE_SIZE:
            return {...state, FileSize: action.payload} 

        case SAVE_SETTINGS:
            return state;
      
        default:           
        return state;
    }

}