export const TEST_CHANGE = "TEST_CHANGE";
export const SET_SHOWN_DAY = "SET_SHOWN_DAY";

////APP
export const TOGGLE_DRAWER = "TOGGLE_DRAWER";
export const SET_TITLE = "SET_TITLE";
export const INITIALISE_STATE = "INITIALISE_STATE";
export const CHANGE_FILTER = "CHANGE_FILTER";

////Sync
export const DO_SYNC = "DO_SYNC";
export const STOP_SYNC = "STOP_SYNC";
export const REPORT_PROGRESS = "REPORT_PROGRESS";

/////Settings
export const SET_URL = "SET_URL";
export const SET_BERATER = "SET_BERATER";
export const SET_CHATURL = "SET_CHATURL";
export const SAVE_SETTINGS = "SAVE_SETTINGS";
export const SET_FILE_SIZE = "SET_FILE_SIZE";

/////Tasks
export const SAVE_UNASSIGNED_DATES = "SAVE_UNASSIGNED_DATES";
export const SAVE_PROPERTY_VALUE_MENCK = "SAVE_PROPERTY_VALUE_MENCK";
export const SAVE_TV_DATA_MENCK = "SAVE_TV_DATA_MENCK";
export const SET_TV_PROPERTY_VALUE = "SET_TV_PROPERTY_VALUE";
export const SAVE_TV_PROPERTY_VALUE = "SAVE_TV_PROPERTY_VALUE";
export const SAVE_TV_DATA = "SAVE_TV_DATA";
export const SELECT_APPOINTMENT_BY_ID = "SELECT_APPOINTMENT_BY_ID";
export const SAVE_SIGNATURES = "SAVE_SIGNATURES";
export const FOTO_ADD = "FOTO_ADD";
export const FOTO_REMOVE = "FOTO_REMOVE";
export const SET_TV_PROPERTY_VALUE_FOR_ROW='SET_TV_PROPERTY_VALUE_FOR_ROW';
export const SAVE_TV_DATA_FOLGETERMIN = 'SAVE_TV_DATA_FOLGETERMIN';
export const DOWNLOAD_FILE_MANUALLY = 'DOWNLOAD_FILE_MANUALLY';
export const SET_DOWNLOAD_IN_PROGRESS = 'SET_DOWNLOAD_IN_PROGRESS';

//BACKUP
export const DO_BACKUP = "DO_BACKUP";
export const REPORT_BACKUP_PROGRESS = "REPORT_BACKUP_PROGRESS";
export const DO_BACKUP_IMPORT = "DO_BACKUP_IMPORT";

//ServiceProtokol pdf
export const ADD_TABLE_ROW = 'ADD_TABLE_ROW'
//Day status
export const SAVE_DAY_STATUS = "SAVE_DAY_STATUS";

//Service worker
export const SW_INIT = 'SW_INIT';
export const SW_UPDATE = 'SW_UPDATE';

