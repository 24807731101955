import { SAVE_DAY_STATUS, INITIALISE_STATE } from "../actions/types";

const initialState = {
    days: [
        {
            id: 1,
            Day: new Date().setHours(0, 0, 0, 0),
            StartDay: "",
            StartPause: "",
            EndPause: "",
            ReturnedToCompany: "",
            FinishDay: "",
            Synced: 0
        }
    ]
};

let retVal = (state = initialState, action) => {
    switch (action.type) {
        case INITIALISE_STATE:
            if (!action.dayStatus) return state;
            return Object.assign(state, {
                days: action.dayStatus
            });
        case SAVE_DAY_STATUS:
            let edIndex = state.days.findIndex(c => c.id === action.id);
            if (edIndex === -1) {
                let newED = { id: action.id };
                newED[action.prop] = action.value;
                let ed = Object.assign(initialState.days[0], newED);
                let newS = {
                    ...state,
                    days: [...state.days, ed]
                };
                return newS;
            } else {
                let existingDate = Object.assign([], state.days);
                let ed = Object.assign({}, existingDate[edIndex]);
                ed[action.prop] = action.value;
                existingDate.splice(edIndex, 1, ed);
                let newS2 = {
                    ...state,
                    days: existingDate
                };
                return newS2;
            }
        default:
            return state;
    }
};

export default retVal;
