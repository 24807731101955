import React, { Component } from 'react'
import '../../assets/styles/loader.css';

const Loader = (WrappedComponent) => {
    return class Loader extends Component {
        render() {
            //console.log('AAAAAAAAAA');
            //console.log(this.props.app);
            return this.props.app!=={}?(<WrappedComponent {...this.props} />):(<div className="loader"></div>);
        }
    }
}

export default Loader;