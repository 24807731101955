let menck = {
  shadows: Array(25).fill("none"),
  typography: {
      useNextVariants: true,
      color: "#444",
  },
  palette: {
      primary: {
          main: "#092C74",
          contrastText: "#FFFFFF",

      },
      secondary: {
          main: "#3962BA",
          contrastText: "#FFFFFF",
      }
  }
};
export default menck;