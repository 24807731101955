import Dexie from "dexie";
import { COMPANY } from '../settings';

export default class DBSettings {
    constructor() {
        this.db = new Dexie("settings"); // new PouchDB(name,{revs_limit: 1, auto_compaction: true});
        this.db.version(1).stores({ settings: "id" });
    }

    async LoadSettings(initial) {
        let settings = await this.db.table("settings").toArray();

        if (settings.length > 0) return settings[0];
        return initial;
    }

    async SaveSettings(settings) {
        settings.id = 1;
        settings.Company = COMPANY;
        await this.db.table("settings").put(settings);
        return settings;
    }
}
