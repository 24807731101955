import React, { Component } from "react";
import { connect } from "react-redux";
import { setTitle, toggleDrawer, ChangeFilter } from "../actions/AppActions";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { Switch, Route, Link, BrowserRouter as Router } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import {
    List,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText
} from "../bundles/ListBundle";
import {
    MenuIcon,
    OffeneIcon,
    ErledigteIcon,
    ChatIcon,
    EinstellungenIcon,
    SyncIcon,
    SicherungskoppieIcon
} from "../bundles/IconsBundle";
import { SHOWN_TYPE_ERLEDIGTE, SHOWN_TYPE_OFFENE, SHOWN_TYPE_UNVERTEILTE } from "../helpers/constants";
import {
    COMPANY_BROEMSE,
    COMPANY_GROWE,
    COMPANY_OST,
    COMPANY_MENCK,
    COMPANY_LOEFFEL,
    COMPANY_EVERS
} from "../helpers/constants";
import { VERSION } from "../settings";
import SwipeableDays from "./SwipeableDays";
import Settings from "./Settings";
import SyncPage from "./SyncPage";
import TaskView from "./TaskView";
import TaskViewGrowe from "./growe/TaskView";
import TaskViewOst from "./ost/TaskView";
import { intialiseState } from "../actions/AppActions";
import TaskSign from "./TaskSign";
import DbExport from "./DbExport";
import DbImport from "./DbImport";
import TaskViewMenck from './menck/TaskView';
import TaskViewLoeffel from './loeffel/TaskView';
import TaskSignMenck from './menck/TaskSignMenck';
import TaskSignLoeffel from './loeffel/TaskSign';
import MenckMaterialUndZeit from './menck/MaterialUndZeit';
import LoeffelMaterialUndZeit from './loeffel/MaterialUndZeit';
import TaskSignService from "./growe/TaskSignService";
import TaskSignPruf from './growe/TaskSignPruf';
import TaskUnassigned from './menck/TaskUnassigned';
import TaskUnassignedLoeffel from './loeffel/TaskUnassigned';
import Unassigned from './menck/Unassigned';
import UnassignedLoeffel from './loeffel/Unassigned';
import UpdateButton from './update';
// import { updateServiceWorker } from '../helpers/utils'; 
import * as logo from '../assets/images/imageExport';

const classes = {
    appbar: {
        top: 0,
        height: 56,
        maxHeight: 56
    },
    child: {
        marginTop: 150,
    },
    swipeLink: {
        textDecoration: "none",
        color: 'inherit'
    }

};
class MainLayout extends Component {
    constructor(props) {
        super(props);
        this.props.intialiseState();
    }


    getAppBar = () => {
        return (
            <AppBar position="fixed" style={classes.appbar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="Menu"
                        onClick={() => {
                            this.props.toggleDrawer(true);
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" color="inherit">
                        {this.props.pageTitle}
                    </Typography>
                </Toolbar>
                {/* <UpdateButton /> */}
            </AppBar>
        );
    };

    showErledigte = () => {
        this.props.ChangeFilter(SHOWN_TYPE_ERLEDIGTE);
        this.props.setTitle("Erledigte Vorgänge");
    };
    showOffene = () => {
        this.props.ChangeFilter(SHOWN_TYPE_OFFENE);
        this.props.setTitle("Offene Vorgänge");
    };
    showUnverteilte = () => {
        this.props.ChangeFilter(SHOWN_TYPE_UNVERTEILTE);
        // this.props.setTitle("Unverteilte Vorgänge");
    };

    getTaskView = () => {
        switch (this.props.application.company) {
            case COMPANY_BROEMSE:
            case COMPANY_EVERS:
                return <TaskView />;
            case COMPANY_GROWE:
                return <TaskViewGrowe />;
            case COMPANY_OST:
                return <TaskViewOst />;
            case COMPANY_MENCK:
                return <TaskViewMenck />;
            case COMPANY_LOEFFEL:
                return <TaskViewLoeffel />
            default:
                return <TaskView />;
        }
    };

    getTaskSignView = () => {
        switch (this.props.application.company) {
            case COMPANY_BROEMSE:
            case COMPANY_EVERS:
                return <TaskSign />;
            case COMPANY_GROWE:
                return <TaskSignService />;
            // case COMPANY_OST:
            //     return <TaskViewOst />;
            case COMPANY_MENCK:
                return <TaskSignMenck />;
            case COMPANY_LOEFFEL:
                return <TaskSignLoeffel />
            default:
                return <TaskSign />;
        }
    }

    doNothing = () => {
        window.location.reload();
    };

    render() {
        const companyImageName = (this.props.application.company || "").toLowerCase();
        let showUpdateButton = this.props.application.serviceWorkerUpdated;

        return (
            <Router>
                <div>
                    <SwipeableDrawer
                        style={classes.child}
                        open={this.props.application.drawerOpen}
                        onClose={() => {
                            this.props.toggleDrawer(false);
                        }}
                        onOpen={this.doNothing}
                    >
                        <div
                            tabIndex={0}
                            role="button"
                            onClick={() => {
                                this.props.toggleDrawer(false);
                            }}
                            onKeyDown={() => {
                                this.props.toggleDrawer(false);
                            }}
                        >

                            <AppBar style={classes.appbar} position="static">
                                <Toolbar>
                                    <img
                                        src={logo ? logo[companyImageName] : ""}
                                        alt="Suite"
                                        style={{ maxHeight: 56 }}
                                    />
                                    <Typography variant="h6" color="inherit">
                                        ServiceApp{" "}
                                        <span style={{ fontSize: "0.6em" }}>
                                            {VERSION}
                                        </span>
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <List>
                                {(companyImageName === "menck" || companyImageName === "loeffel") && (
                                    <Link
                                        to="/unverteilte"
                                        onClick={this.showUnverteilte}
                                        style={classes.swipeLink}
                                    >
                                        <ListItem button>
                                            <ListItemIcon>
                                                <OffeneIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Unverteilte Vorgänge" />
                                        </ListItem>
                                    </Link>
                                )}
                                <Link
                                    to="/"
                                    onClick={this.showOffene}
                                    style={classes.swipeLink}
                                >
                                    <ListItem button>
                                        <ListItemIcon>
                                            <OffeneIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Offene Vorgänge" />
                                    </ListItem>
                                </Link>
                                <Link
                                    to="/"
                                    onClick={this.showErledigte}
                                    style={classes.swipeLink}
                                >
                                    <ListItem button>
                                        <ListItemIcon>
                                            <ErledigteIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Erledigte Vorgänge" />
                                    </ListItem>
                                </Link>
                                <Divider />
                            </List>
                            <List>
                                <ListItem button onClick={this.showB}>
                                    <ListItemIcon>
                                        <ChatIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Chat" />
                                </ListItem>
                                <Divider />
                            </List>
                            <List>
                                <Link
                                    to="/settings"
                                    style={classes.swipeLink}
                                >
                                    <ListItem button>
                                        <ListItemIcon>
                                            <EinstellungenIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Einstellungen" />
                                    </ListItem>
                                </Link>

                                <Link
                                    to="/sync"
                                    style={classes.swipeLink}
                                >
                                    <ListItem button>
                                        <ListItemIcon>
                                            <SyncIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Synchronisieren" />
                                    </ListItem>
                                </Link>
                                <Divider />
                            </List>
                            <List>
                                <Link
                                    to="/backup"
                                    style={classes.swipeLink}
                                >
                                    <ListItem button>
                                        <ListItemIcon>
                                            <SicherungskoppieIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Sicherungskopie" />
                                    </ListItem>
                                </Link>
                            </List>
                        </div>
                    </SwipeableDrawer>
                    <AppBar position="fixed" style={classes.appbar}>
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="Menu"
                                onClick={() => {
                                    this.props.toggleDrawer(true);
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography
                                variant="h6"
                                color="inherit"
                                style={{ flex: 1 }}
                                align="left"
                            >
                                {this.props.pageTitle}
                            </Typography>
                            {showUpdateButton && <UpdateButton />}
                        </Toolbar>
                    </AppBar>

                    <div style={{ marginTop: "56px" }}>
                        <Switch>
                            <Route
                                exact
                                path="/"
                                render={() => {
                                    return <SwipeableDays />;
                                }}
                            />
                            <Route
                                exact
                                path="/settings"
                                render={() => {
                                    return <Settings />;
                                }}
                            />
                            <Route
                                exact
                                path="/sync"
                                render={() => {
                                    return <SyncPage />;
                                }}
                            />
                            <Route
                                exact
                                path="/backup"
                                render={() => {
                                    return <DbExport />;
                                }}
                            />
                            <Route
                                exact
                                path="/import"
                                render={() => {
                                    return <DbImport />;
                                }}
                            />
                            <Route path="/task/:id/:service" render={this.getTaskView} />
                            <Route
                                path="/tasksign/:id/:service"
                                render={this.getTaskSignView}
                            />
                            <Route
                                path="/tasksignpruf/:id/:service"
                                render={() => {
                                    return <TaskSignPruf />;
                                }}
                            />
                            <Route
                                path="/material/:id/:service"
                                render={() => {
                                    if (this.props.application.company === COMPANY_LOEFFEL) {
                                        return <LoeffelMaterialUndZeit />;
                                    }
                                    return <MenckMaterialUndZeit />;
                                }}
                            />
                            <Route
                                exact
                                path="/unverteilte"
                                render={() => {
                                    if (this.props.application.company === COMPANY_LOEFFEL) {
                                        return <UnassignedLoeffel />;
                                    } else {
                                        return <Unassigned />;
                                    }

                                }}
                            />
                            <Route
                                exact
                                path="/taskunassigned/:id/:service"
                                render={() => {
                                    if (this.props.application.company === COMPANY_LOEFFEL) {
                                        return <TaskUnassignedLoeffel />
                                    } else {
                                        return <TaskUnassigned />;
                                    }

                                }}
                            />
                        </Switch>
                    </div>
                </div>
            </Router>
        );
    }
}

const mapStateToProps = state => ({
    application: state.app,
    pageTitle: state.app.pageTitle
});

export default connect(
    mapStateToProps,
    { setTitle, toggleDrawer, intialiseState, ChangeFilter }
)(MainLayout);
