export const SHOWN_TYPE_OFFENE = "OFFENE";
export const SHOWN_TYPE_ERLEDIGTE = "ERLEDIGTE";
export const SHOWN_TYPE_UNVERTEILTE = "UNVERTEILTE";

export const COMPANY_BROEMSE = "BROEMSE";
export const COMPANY_EVERS = "EVERS";
export const COMPANY_GROWE = "GROWE";
export const COMPANY_OST = "OST";
export const COMPANY_MENCK = "MENCK";
export const COMPANY_LOEFFEL = "LOEFFEL";
