import SignatureCanvas from 'react-signature-canvas';

export default class SigPadTransparent extends SignatureCanvas
{
    toDataURLTransparent = (type, encoderOptions) => {

        

        const canvas = this.getTrimmedCanvas();
        const ctx = canvas.getContext("2d");

        let image = ctx.getImageData(0, 0, 500, 200);
        const pix = image.data;

        const newColor = {r:200,g:200,b:200, a:0};
        const oldR=200, odlG=200, oldB=200;
            // get the image data values 
        for (var i = 0, n = pix.length; i <n; i += 4) {
            var r = pix[i],
                    g = pix[i+1],
                    b = pix[i+2];
        
                // If its white then change it
                if(r === oldR && g === odlG && b === oldB){ 
                    // Change the white to whatever.
                    pix[i] = newColor.r;
                    pix[i+1] = newColor.g;
                    pix[i+2] = newColor.b;
                    pix[i+3] = newColor.a;
                }
        }
        ctx.putImageData(image, 0, 0)
        return canvas.toDataURL(type, encoderOptions);        
      }

      fromDataURLTransparent = (dataURL, options) => {
        this._sigPad.fromDataURL(dataURL, options)
        const canvas = this._canvas;
        const ctx = canvas.getContext("2d");

        let image = ctx.getImageData(0, 0, 500, 200);
        const pix = image.data;
        
        for (let i = 0, n = pix.length; i <n; i += 4) {                        
                if( pix[i+3]===0){                     
                    pix[i+3]=255;
                }
        }
        ctx.putImageData(image, 0, 0)

      }
}