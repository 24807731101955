import React, { Component } from 'react';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ShortId from 'shortid';
import TextField from '@material-ui/core/TextField';
import { EnteredDataForServicePDF } from '../../selectors/SelectedAppointmentSelector';
import { connect } from 'react-redux';
import { SavePropertyValueForTable, DeleteTableRow, SetPropertyValueForTable } from '../../actions/TaskActions';

class TableServiceProtokol extends Component {

    deleteItem = rowId => e => {
        //delete table row
        this.props.DeleteTableRow(this.props.app, "ServicemonteurList", rowId);
    }
    addItem = () => {
        //(IDService, prop, rowId, tableName, value)
        this.props.SavePropertyValueForTable(this.props.app, null,null,"ServicemonteurList",
            {
                id: ShortId.generate(),
                Datum: "",
                Name: "",
                Von: "",
                Bis: ""
            });
    }
    handleChangeInput = rowId => e => {
        console.log(e, rowId);
        this.props.SetPropertyValueForTable(this.props.app, e.target.id, rowId, "ServicemonteurList", e.target.value)
    }
    handleBlurInput = rowId => e => {
        console.log(e, rowId);
        this.props.SavePropertyValueForTable(this.props.app, e.target.id, rowId, "ServicemonteurList", e.target.value)
    }

    render() {
        let ServicemonteurList = this.props.app.ServicemonteurList === undefined ? [] : this.props.app.ServicemonteurList;
        console.log("TableServiceProps: ", this.props);
        return (
            <div>
                <table style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <td></td>
                            <td>Datum</td>
                            <td>Name Servicemonteur(e)</td>
                            <td colSpan="2">Uhrzeit</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            ServicemonteurList.map((val, idx) => {
                            return (
                                <tr key={val.id}>
                                    <td> <Fab aria-label="Delete" onClick={this.deleteItem(val.id)}>
                                        <DeleteIcon />
                                    </Fab></td>
                                    <td >
                                        <TextField
                                            id="Datum"
                                            label="Datum"
                                            value={val.Datum}
                                            onChange={this.handleChangeInput(val.id)}
                                            onBlur={this.handleBlurInput(val.id)}
                                            margin="normal"
                                        />
                                    </td>
                                    <td> <TextField
                                        id="Name"
                                        label="Name"
                                        value={val.Name}
                                        onChange={this.handleChangeInput(val.id)}
                                        onBlur={this.handleBlurInput(val.id)}
                                        margin="normal"
                                    /></td>
                                    <td> <TextField
                                        id="Von"
                                        label="von"
                                        value={val.Von}
                                        onChange={this.handleChangeInput(val.id)}
                                        onBlur={this.handleBlurInput(val.id)}
                                        margin="normal"
                                    /></td>
                                    <td> <TextField
                                        id="Bis"
                                        label="bis"
                                        value={val.Bis}
                                        onChange={this.handleChangeInput(val.id)}
                                        onBlur={this.handleBlurInput(val.id)}
                                        margin="normal"
                                    /></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <Fab color="primary" aria-label="Add" onClick={this.addItem}>
                    <AddIcon />
                </Fab>
            </div>
        )
    }
}

const mapStateToProps = (state) => (
    {
        app: EnteredDataForServicePDF(state)
    }
)

export default connect(mapStateToProps, { SavePropertyValueForTable, DeleteTableRow, SetPropertyValueForTable })(TableServiceProtokol);