import React, { Component } from 'react';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ShortId from 'shortid';
import TextField from '@material-ui/core/TextField';
import { EnteredDataForServicePDF } from '../../selectors/SelectedAppointmentSelector';
import { connect } from 'react-redux';
import { SavePropertyValueForTable, DeleteTableRow, SetPropertyValueForTable } from '../../actions/TaskActions';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { SavePropertyValue } from '../../actions/TaskActions';

const styles = {
    card: {
        margin: '20px',
        border: '1px solid #999',
        width: 'inherit'
    },
    alignment: {
        margin: '0px auto 0px auto'
    },
    input: {
        width: '100%'
    }
}

class TablesPrufProtokol extends Component {

    deleteItem = (rowId, tableName) => e => {
        //delete table row
        this.props.DeleteTableRow(this.props.app, tableName, rowId);
    }
    addItem = tableName => e =>  {
        //(IDService, prop, rowId, tableName, value)
        let tableObject = {};
        if(tableName === "MasshaltigkeitElementeList") tableObject = {id:ShortId.generate(), Position: "",SollMass:"", IstMass:"" }
        if(tableName === "StabzahlPanzerList") tableObject = {id:ShortId.generate(), Position: "",SollGelocht:"", SollUngelocht:"", IstGelocht:"", IstUngelocht:""}
        if(tableName === "FuhrungsschienenLangeList") tableObject = {id:ShortId.generate(), Position: "",SollLange: "", IstLange:""}
        this.props.SavePropertyValueForTable(this.props.app, null,null,tableName,tableObject);
    }
    handleChange = (e, checked) => {
        this.props.SavePropertyValue(this.props.app, e.target.name, checked);
    }
    handleChangeInput = (rowId, tableName) => e => {
        console.log(e, rowId);
        this.props.SetPropertyValueForTable(this.props.app, e.target.id, rowId, tableName, e.target.value)
    }
  
    handleBlurInput = (rowId, tableName) => e => {
        console.log(e, rowId);
        this.props.SavePropertyValueForTable(this.props.app, e.target.id, rowId, tableName, e.target.value)
    }
    render() {
        let MasshaltigkeitElementeList = this.props.app.MasshaltigkeitElementeList || []
        let StabzahlPanzerList = this.props.app.StabzahlPanzerList || [];
        let FuhrungsschienenLangeList = this.props.app.FuhrungsschienenLangeList || [];
        const { Masshaltigkeit, Stabzahl, LangeFuhrungsscienen } = this.props.app;
        console.log("TableServiceProps: ", this.props);
        return (
            <div>
                <Paper elevation={1} style={styles.card}>
                <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={Masshaltigkeit === true ? true : false} name="Masshaltigkeit" value="checkedB" color="primary"
                            />
                        }
                        label="Maßhaltigkeit Elemente geprüft?"
                    /><br/>
                <table style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <td></td>
                            <td>Position</td>
                            <td>SOLL Maße lt. ZA / Auftrag</td>
                            <td>IST Maße gemäß Überprüfung</td>
                        </tr>
                    </thead>
                    <tbody>
                        {MasshaltigkeitElementeList.map((val, idx) => {
                            return (
                                <tr key={val.id}>
                                    <td> <Fab aria-label="Delete" onClick={this.deleteItem(val.id, "MasshaltigkeitElementeList") }>
                                        <DeleteIcon />
                                    </Fab></td>
                                    <td >
                                        <TextField
                                            id="Position"
                                            value={val.Position}
                                            onChange={this.handleChangeInput(val.id, "MasshaltigkeitElementeList")}
                                            onBlur={this.handleBlurInput(val.id, "MasshaltigkeitElementeList")}
                                            margin="normal"
                                        />
                                    </td>
                                    <td> <TextField
                                        id="SollMass"
                                        value={val.SollMass}
                                        onChange={this.handleChangeInput(val.id, "MasshaltigkeitElementeList")}
                                        onBlur={this.handleBlurInput(val.id, "MasshaltigkeitElementeList")}
                                        margin="normal"
                                    /></td>
                                    <td> <TextField
                                        id="IstMass"
                                        value={val.IstMass}
                                        onChange={this.handleChangeInput(val.id, "MasshaltigkeitElementeList")}
                                        onBlur={this.handleBlurInput(val.id, "MasshaltigkeitElementeList")}
                                        margin="normal"
                                    /></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <Fab color="primary" aria-label="Add" disabled={Masshaltigkeit === true ? false : true} onClick={this.addItem("MasshaltigkeitElementeList")}>
                    <AddIcon />
                </Fab>
                </Paper>
                <Paper elevation={2} style={styles.card}>
                <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={Stabzahl === true ? true : false} name="Stabzahl" value="checkedB" color="primary"
                            />
                        }
                        label="Stabzahl Panzer überprüft?"
                    /><br/>
                <table style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <td></td>
                            <td>Position</td>
                            <td colSpan="2">SOLL Stabzahl lt. ZA / Auftrag</td>
                            <td colSpan="2">IST Stabzahl gemäß Überprüfung</td>
                        </tr>
                    </thead>
                    <tbody>
                        {StabzahlPanzerList.map((val, idx) => {
                            return (
                                <tr key={val.id}>
                                    <td> <Fab aria-label="Delete" onClick={this.deleteItem(val.id, "StabzahlPanzerList")}>
                                        <DeleteIcon />
                                    </Fab></td>
                                    <td >
                                        <TextField
                                            id="Position"
                                            value={val.Position}
                                            onChange={this.handleChangeInput(val.id, "StabzahlPanzerList")}
                                            onBlur={this.handleBlurInput(val.id, "StabzahlPanzerList")}
                                            margin="normal"
                                        />
                                    </td>
                                    <td> <TextField
                                        id="SollGelocht"
                                        label="gelocht:"
                                        value={val.SollGelocht}
                                        onChange={this.handleChangeInput(val.id, "StabzahlPanzerList")}
                                        onBlur={this.handleBlurInput(val.id, "StabzahlPanzerList")}
                                        margin="normal"
                                    /></td>
                                    <td> <TextField
                                        id="SollUngelocht"
                                        label="ungelocht:"
                                        value={val.SollUngelocht}
                                        onChange={this.handleChangeInput(val.id, "StabzahlPanzerList")}
                                        onBlur={this.handleBlurInput(val.id, "StabzahlPanzerList")}
                                        margin="normal"
                                    /></td>
                                     <td> <TextField
                                        id="IstGelocht"
                                        label="gelocht:"
                                        value={val.IstGelocht}
                                        onChange={this.handleChangeInput(val.id, "StabzahlPanzerList")}
                                        onBlur={this.handleBlurInput(val.id, "StabzahlPanzerList")}
                                        margin="normal"
                                    /></td>
                                    <td> <TextField
                                        id="IstUngelocht"
                                        label="ungelocht:"
                                        value={val.IstUngelocht}
                                        onChange={this.handleChangeInput(val.id, "StabzahlPanzerList")}
                                        onBlur={this.handleBlurInput(val.id, "StabzahlPanzerList")}
                                        margin="normal"
                                    /></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <Fab color="primary" aria-label="Add" disabled={Stabzahl === true ? false : true} onClick={this.addItem("StabzahlPanzerList")}>
                    <AddIcon />
                </Fab>
                </Paper>
                <Paper elevation={3} style={styles.card}>
                <FormControlLabel
                        control={
                            <Checkbox onChange={this.handleChange} checked={LangeFuhrungsscienen === true ? true : false} name="LangeFuhrungsscienen" value="checkedB" color="primary"
                            />
                        }
                        label="Länge der Führungsschienen überprüft?"
                    /><br/>
                <table style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <td></td>
                            <td>Position</td>
                            <td>SOLL Länge lt. ZA / Auftrag</td>
                            <td>IST Länge gemäß Überprüfung</td>
                        </tr>
                    </thead>
                    <tbody>
                        {FuhrungsschienenLangeList.map((val, idx) => {
                            return (
                                <tr key={val.id}>
                                    <td> <Fab aria-label="Delete" onClick={this.deleteItem(val.id, "FuhrungsschienenLangeList")}>
                                        <DeleteIcon />
                                    </Fab></td>
                                    <td >
                                        <TextField
                                            id="Position"
                                            value={val.Position}
                                            onChange={this.handleChangeInput(val.id, "FuhrungsschienenLangeList")}
                                            onBlur={this.handleBlurInput(val.id, "FuhrungsschienenLangeList")}
                                            margin="normal"
                                        />
                                    </td>
                                    <td> <TextField
                                        id="SollLange"
                                        value={val.SollLange}
                                        onChange={this.handleChangeInput(val.id, "FuhrungsschienenLangeList")}
                                        onBlur={this.handleBlurInput(val.id, "FuhrungsschienenLangeList")}
                                        margin="normal"
                                    /></td>
                                    <td> <TextField
                                        id="IstLange"
                                        value={val.IstLange}
                                        onChange={this.handleChangeInput(val.id, "FuhrungsschienenLangeList")}
                                        onBlur={this.handleBlurInput(val.id, "FuhrungsschienenLangeList")}
                                        margin="normal"
                                    /></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <Fab color="primary" aria-label="Add" disabled={LangeFuhrungsscienen === true ? false : true} onClick={this.addItem("FuhrungsschienenLangeList")}>
                    <AddIcon />
                </Fab>
                </Paper>

            </div>
        )
    }
}

const mapStateToProps = (state) => (
    {
        app: EnteredDataForServicePDF(state)
    }
)

export default connect(mapStateToProps, { SavePropertyValueForTable, DeleteTableRow, SavePropertyValue, SetPropertyValueForTable })(TablesPrufProtokol);
