import React, { Component } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import check from '../extensions/check';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { EnteredDataForServicePDF } from '../../selectors/SelectedAppointmentSelector';
import { SavePropertyValue } from '../../actions/TaskActions';
import Button from '@material-ui/core/Button';

import {GsPrufProtokolLogo} from '../../assets/images/Base64logo';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
let i = check.pdfMake.vfs;
let j = pdfFonts.pdfMake.vfs;
pdfMake.vfs = { ...i, ...j };

pdfMake.fonts = {
    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-Italic.ttf'
    },
    Fontello: {
        normal: 'fontello.ttf',
        bold: 'fontello.ttf',
        italics: 'fontello.ttf',
        bolditalics: 'fontello.ttf'
    }
}

class GeneratePdfPrufProtokol extends Component {

    downloadPDF = () => {
        console.log("Pruf protokol pdf props : ", this.props);
        const { ServiceTyp, Masshaltigkeit, Stabzahl, LangeFuhrungsscienen, FotosGemacht, FunktionstestAnlage, FuhrungsschienenMontiert,
            ServicemonteurList, MasshaltigkeitElementeList, FuhrungsschienenLangeList, StabzahlPanzerList, Komision, Name, ekOrt, ekStrasse, ekPlz, ekTelefon1,
			Plz, Ort, Strasse } = this.props.app;
            const bodyDataServicemonteur = [ [{ text: 'Datum', style: 'tableHeader', alignment: 'center', rowSpan: 2 }, { text: 'Name Servicemonteur(e)', rowSpan: 2, style: 'tableHeader', alignment: 'center' }, { text: 'Uhrzeit', style: 'tableHeader', colSpan: 2, alignment: 'center' }, {}],
            [{ text: 'Header 1', style: 'tableHeader', alignment: 'center' }, { text: 'Header 1', style: 'tableHeader', alignment: 'center' }, { text: 'von', alignment: 'center' }, { text: 'bis', alignment: 'center' }]];
            ServicemonteurList.forEach(function(e){
                let arr = [];
                let item ={ text: `${e.Datum}`, alignment: 'center' };
                arr.push(item);
                let i ={ text: `${e.Name}`, alignment: 'left' };
                arr.push(i);
                let j ={ text: `${e.Von}`, alignment: 'left' };
                arr.push(j);
                let k = { text: `${e.Bis}`, alignment: 'left' };
                arr.push(k);
                bodyDataServicemonteur.push(arr);
                
            });
            const bodyDataMasshaltigkeitElementeList = [ 
                ['Position:',
                'SOLL Maße lt. ZA / Auftrag ',
                'IST Maße gemäß Überprüfung ',
                {
                    text: [{ text: `${Masshaltigkeit === true ? '' : ''} `, style: 'icon' }, ` ja`], border: [false, false, false, false]
                }
            ]    
            ];
            MasshaltigkeitElementeList.forEach(function(e){
                let arr = [];
                let j = { text:`${e.Position}`, alignment: 'left' };  
                arr.push(j);
                let k = { text:`${e.SollMass}`, alignment: 'left' };  
                arr.push(k);
                let m = { text:`${e.IstMass}`, alignment: 'left' };  
                arr.push(m);
                let i ={text: '', border: [false, false, false, false]};
                arr.push(i);
                 
                bodyDataMasshaltigkeitElementeList.push(arr);
                
            });

            const bodyDataStabzahlPanzerList = [
                [{ text: 'Datum', style: 'tableHeader', alignment: 'center' }, { text: 'SOLL Stabzahl lt. ZA / Auftrag ', style: 'tableHeader', colSpan: 2, alignment: 'center' }, {}, { text: 'IST Stabzahl gemäß Überprüfung', style: 'tableHeader', colSpan: 2, alignment: 'center' }, {},  
                {
                    text: [{ text: `${Stabzahl === true ? '' : ''} `, style: 'icon' }, ` ja`], border: [false, false, false, false]
                }]
                 ,
                 //ruši se dokument ukoliko ova tabela nema dodatnu kolonu ispod.
                 [{ text: '', style: 'tableHeader', alignment: 'center' }, { text: `gelocht: Stk.`, style: 'tableHeader', alignment: 'center' }, { text: `ungelocht: Stk. `, alignment: 'center' }, { text: `gelocht: Stk. `, alignment: 'center' }, { text: `ungelocht: Stk. `, alignment: 'center' }, { text: '', border: [false, false, false, false] }],
                
            ];
             
            StabzahlPanzerList.forEach(function(e){
                let arr = [];
                let i = { text:`${e.Position}`, alignment: 'left' };             
                arr.push(i);
                let j = { text: `gelocht: ${e.SollGelocht} Stk.`, alignment: 'left' };                
                arr.push(j);
                let k = { text: `ungelocht: ${e.SollUngelocht} Stk. `, alignment: 'left' };               
                arr.push(k);
                let o = { text: `gelocht: ${e.IstGelocht} Stk. `, alignment: 'left' };
                arr.push(o);
                let m = { text: `ungelocht: ${e.IstUngelocht} Stk. `, alignment: 'left' };
                arr.push(m);
                let l =  { text: '', border: [false, false, false, false] }
                arr.push(l);
                bodyDataStabzahlPanzerList.push(arr);
                
            });

            const bodyDataFuhrungsschienenLangeList = [
                [`Position:`,
                    `SOLL Länge lt. ZA / Auftrag  `,
                    `IST Länge gemäß Überprüfung  `,
                    {
                        text: [{ text: `${LangeFuhrungsscienen === true ? '' : ''} `, style: 'icon' }, ` ja`], border: [false, false, false, false]
                    }
                ],
            ];
            FuhrungsschienenLangeList.forEach(function(e){
                let arr = [];
                let i = { text:`${e.Position}`, alignment: 'left' };             
                arr.push(i);
                let j = { text: `${e.SollLange}`, alignment: 'left' };                
                arr.push(j);
                let k = { text: `${e.IstLange}`, alignment: 'left' };               
                arr.push(k);
                let o = {
                    text: '', border: [false, false, false, false]
                };
                arr.push(o);
                bodyDataFuhrungsschienenLangeList.push(arr);
                
            });

        let docDefinition = {
            content: [
                { text: 'Prüfmaßnahmen vor Ort', style: 'header' },
                {
                    image: GsPrufProtokolLogo,
                    width: 150,
                    margin:[300,0,0,0],
                },
                {
                    style: 'tableExample',
                    table: {
                        widths: ['*', '*'],
                        body: [
							[`Auftraggeber: ${Komision || ''}`, `Kunde / Bauvorhaben: ${Name || ''}`],
							[`${Strasse}`, `${ekStrasse}`],
							[`${Plz} ${Ort}`, `${ekPlz} ${ekOrt}`],
							[`Ansprechpartner - Telefon/Fax: Frau Weisskopf Susanne +49 3904667128`, `Ansprechpartner - Telefon/Fax: ${ekTelefon1}`],
						]
                    }
                },

                {
                    style: 'tableExample',
                    table: {
                        widths: ['*', '*', '*'],
                        body: [
                            [`Auftragsnummer: 
					2015-0046 `,
                                `Auftragsdatumm: 
					16.03.2016`,
                                `Termin: ca. Uhr:
					`],

                        ]
                    }
                },
                {
					columns: [
						{
							text: [{ text: `${ServiceTyp === 'kundendienst' ? '' : ''} `, style: 'icon' }, ` Kundendienst`]
						},
						{
							text: [{ text: `${ServiceTyp === 'beratung' ? '' : ''} `, style: 'icon' }, ` Beratung / Begutachtung`]
						},
						{
							text: [{ text: `${ServiceTyp === 'serviceleistung' ? '' : ''} `, style: 'icon' }, ` Serviceleistung / Reparatur`]
						}
					],
					style: 'checkFirst'
				},
                { text: 'Maßhaltigkeit Elemente geprüft?', style: 'subheader' },
                {
                    style: 'tableExample',
                    table: {
                        widths: ['*', '*', '*', 'auto'],
                        body: bodyDataMasshaltigkeitElementeList
                    }
                },
                { text: 'Stabzahl Panzer überprüft?', style: 'subheader' },
                {
                    style: 'tableExample',
                    table: {
                        widths: [80, 90, 90, 90, 90, 90, 'auto'],
                        headerRows: 2,
                        body: bodyDataStabzahlPanzerList
                    }
                },
                { text: 'Länge der Führungsschienen überprüft?', style: 'subheader' },
                {
                    style: 'tableExample',
                    table: {
                        widths: ['*', '*', '*', 'auto'],
                        body: bodyDataFuhrungsschienenLangeList
                    }
                },
                {
                    style: 'tableQuestion',
                    table: {
                        widths: ['*', 'auto'],
                        body: [
                            [{ text: 'Führungsschienen richtig montiert?', style: 'subheaderQuestion' },
                            {
                                text: [{ text: `${FuhrungsschienenMontiert === true ? '' : ''} `, style: 'icon' }, ` ja`]
                            }
                            ]
                        ],

                    },
                    layout: 'noBorders'
                },
                {
                    style: 'tableQuestion',
                    table: {
                        widths: ['*', 'auto'],
                        body: [
                            [{ text: 'Funktionstest der Anlagen durchgeführt? ', style: 'subheaderQuestion' },
                            {
                                text: [{ text: `${FunktionstestAnlage === true ? '' : ''} `, style: 'icon' }, ` ja`]
                            }
                            ]
                        ],

                    },
                    layout: 'noBorders'
                },
                {
                    style: 'tableQuestion',
                    table: {
                        widths: ['*', 'auto'],
                        body: [
                            [{ text: 'Fotos gemacht? ', style: 'subheaderQuestion' },
                            {
                                text: [{ text: `${FotosGemacht === true ? '' : ''} `, style: 'icon' }, ` ja`]
                            }
                            ]
                        ],

                    },
                    layout: 'noBorders'
                },
                {
                    style: 'tableExample',
                    table: {
                        widths: [80, '*', 70, 70],
                        headerRows: 2,
                        body: bodyDataServicemonteur
                    }
                },

                {
                    text: `ROLTEX Rolladenfabrikation GmbH • Zum Gewerbegebiet 4 • 04769 Mügeln • Tel.: 03 43 62 / 4 22 - 0 • Fax: 03 43 62 / 4 22 - 11 • e-Mail: info@roltex.de • Internet:
www.roltex.de`, style: 'footer'
                }
            ],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 40, 0, -35]
                },
                subheader: {
                    fontSize: 12,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                subheaderQuestion: {
                    fontSize: 10,
                    bold: true,
                    margin: [0, 0, 0, 5]
                },
                subheader1: {
                    fontSize: 10,
                    bold: true
                },
                tableExample: {
                    margin: [0, 5, 0, 15],
                    fontSize: 8,
                },
                tableQuestion: {
                    margin: [0, -5, 0, 0],
                    fontSize: 8,
                },
                check: {
                    fontSize: 10
                },
                checkFirst: {
                    fontSize: 8,
                    margin: [0, -10, 0, 0]
                },
                icon: {
					font: 'Fontello',
					fontSize: 12
				},
                tableHeader: {
                    bold: false,
                    fontSize: 10,
                    color: 'black'
                },
                tableMargin: {
                    margin: [0, -5, 0, 0]
                },
                tableUnterschrift: {
                    fontSize: 8,
                    margin: [0, 25, 0, 15],
                },
                footer: {
                    fontSize: 6
                }
            },
            defaultStyle: {
                // alignment: 'justify'
            }

        }
        //pdfMake.createPdf(docDefinition).open();
        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
		pdfDocGenerator.getBase64((data) => {
            this.props.SavePropertyValue(this.props.app, "PdfPruf", data);
            this.props.history.goBack();		
		});
    }
    render() {
        return (
            <div>
                <Button color="primary" variant="contained" onClick={this.downloadPDF}>Save</Button>
            </div>
        )
    }
}

const mapStateToProps = (state) => (
    {
        app: EnteredDataForServicePDF(state)
    }
)

export default withRouter(connect(mapStateToProps,{SavePropertyValue})(GeneratePdfPrufProtokol));