import { COMPANY_BROEMSE, COMPANY_GROWE, COMPANY_OST, COMPANY_MENCK, COMPANY_LOEFFEL, COMPANY_EVERS } from "./constants";
import BsTheme from "../themes/bs-theme";
import OstTheme from "../themes/ost-theme";
import GroweTheme from "../themes/growe-theme";
import MenckTheme from "../themes/menck-theme";
import LoeffelTheme from "../themes/loeffel-theme";

const themeSwitcher = company => {
    switch (company) {
        case COMPANY_BROEMSE:
        case COMPANY_EVERS:
            return BsTheme;
        case COMPANY_OST:
            return OstTheme;
        case COMPANY_GROWE:
            return GroweTheme;
        case COMPANY_MENCK:
            return MenckTheme;
        case COMPANY_LOEFFEL:
            return LoeffelTheme;
        default:
            return BsTheme;
    }
};

export default themeSwitcher;
