import { DO_SYNC, REPORT_PROGRESS, STOP_SYNC } from '../actions/types';

const initialState = {
   syncInProgress: false,
   syncProgressValue: 0
}

export default function sync(state = initialState, action) {
    
    switch(action.type) {        
        case DO_SYNC:
            return state;  
        case STOP_SYNC:
        return {...state,syncInProgress:false ,syncProgressValue:0 }    
        case REPORT_PROGRESS:
            return {...state,syncInProgress: action.payload!==100 ,syncProgressValue:action.payload  }    
        default:        
        return state;
    }

}