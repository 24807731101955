import React, { Component } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import check from '../extensions/check';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SelectedAppSelector, SelectedAppPhotosSelector } from '../../selectors/SelectedAppointmentSelector';
import { SaveData } from '../../actions/TaskActions';
import Button from '@material-ui/core/Button';
import moment from 'moment';

import { broemseLogo } from '../../assets/images/Base64logo';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
let i = check.pdfMake.vfs;
let j = pdfFonts.pdfMake.vfs;
pdfMake.vfs = { ...i, ...j };

pdfMake.fonts = {
    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-Italic.ttf'
    },
    Fontello: {
        normal: 'fontello.ttf',
        bold: 'fontello.ttf',
        italics: 'fontello.ttf',
        bolditalics: 'fontello.ttf'
    }
}

class GeneratePdf extends Component {

    componentDidMount() {
        moment.locale("de");
    }

    downloadPDF = () => {
        let { ServiceMangelbeschreibung, ServiceNr, StartDate, Firma, ekName, ekOrt, Ort, Plz, ekStrasse, SignatureKunde, SignatureBerater, Finished, FirmaTelefon,
            Komision, AbNr, ServicePosition, ekPlz, ekTelefon1, ekTelefon2, ekEMail, ekInfo, AdditionalServiceNeeded, ErledigteArbeiten } = this.props.app;
        //console.log("pdf props :", this.props.app);

        let mangelParsed = ServiceMangelbeschreibung ? JSON.parse(ServiceMangelbeschreibung) : [];

        let mangelList = mangelParsed.map(item => {
            return `Pos. ${item.Position} - ${item.Mangelbechreibung} \n`;
        })

        let hasPhotos = this.props.fotos.length > 0 ? true : false;
        //console.log("PHOTOS", this.props.fotos);
        //console.log("PHOTOS LENGTH", this.props.fotos.length);
        var dashed = {
            hLineWidth: function (i, node) {
                return (i === 0 || i === node.table.body.length) ? 2 : 1;
            },
            vLineWidth: function (i, node) {
                return (i === 0 || i === node.table.widths.length) ? 2 : 1;
            },
            hLineColor: function (i, node) {
                return 'black';
            },
            vLineColor: function (i, node) {
                return 'black';
            },
            hLineStyle: function (i, node) {
                if (i === 0 || i === node.table.body.length || i === 1) {
                    return null;
                }
                return { dash: { length: 1, space: 2 } };
            },
            vLineStyle: function (i, node) {
                if (i === 0 || i === node.table.widths.length || i === 2) {
                    return null;
                }
                return null;
            },
        };
        var borderedTable = {
            hLineWidth: function (i, node) {
                return (i === 0 || i === node.table.body.length) ? 2 : 1;
            },
            vLineWidth: function (i, node) {
                return (i === 0 || i === node.table.widths.length) ? 2 : 1;
            },
            hLineColor: function (i, node) {
                return 'black';
            },
            vLineColor: function (i, node) {
                return 'black';
            },
        };
        var dd = {
            content: [
                { text: `Kundendienst-Protokoll`, border: [false, true, false, false], style: 'header' },
                {
                    image: broemseLogo,
                    width: 100,
                    margin: [415, -30, 0, 0],
                },
                {
                    style: 'tableExample',
                    table: {
                        widths: [45, '*', '*', 45, '*', '*'],
                        headerRows: 1,
                        // keepWithHeaderRows: 1,
                        body: [
                            [
                                { text: `Kundendaten: ${StartDate ? moment(StartDate).format("DD.MM.YYYY - HH:mm") : ""}`, style: 'tableHeader', colSpan: 4, alignment: 'left', border: [true, true, false, true] },
                                '', '', '',
                                { text: `Service-Nr.:${ServiceNr}`, colSpan: 2, style: 'tableHeader', alignment: 'right', border: [false, true, true, true] }, ''
                            ],
                            [
                                { text: `Firma:`, border: [true, false, false, false] },
                                { text: `${Firma ? Firma : ''}`, colSpan: 2, border: [false, false, false, false] }, '',
                                { text: `Komm.:`, border: [true, false, false, true]}, 
                                { text: `${Komision ? Komision : ''}`, colSpan: 2, border: [false, false, true, true] }, ''
                            ],
                            [
                                { text: `Ort:`, border: [true, true, false, false] }, 
                                { text: `${Ort ? Ort : ''}`, colSpan: 2, border: [false, true, false, false] }, '',
                                { text: `AB-Nr.:`, border: [true, false, false, false] }, 
                                { text: `${AbNr ? AbNr : ''}`, colSpan: 2, border: [false, false, true, false] }, ''
                            ],
                            [
                                { text: `PLZ:`, border: [true, true, false, false] }, 
                                { text: `${Plz ? Plz : ''}`, colSpan: 2, border: [false, true, false, false] }, '',
                                { text: `Position:`, border: [true, true, false, false] }, 
                                { text: `${ServicePosition ? ServicePosition : ''}`, colSpan: 2, border: [false, true, true, false] }, ''
                            ],
                            [
                                { text: `Telefon:`, border: [true, true, false, true] }, 
                                { text: `${FirmaTelefon ? FirmaTelefon : ''}`, colSpan: 2, border: [false, true, false, true] }, '',
                                { text: ``, border: [true, true, false, true] }, 
                                { text: '', colSpan: 2, border: [false, true, true, true] }, ''
                            ],


                        ]
                    },
                    layout: dashed
                },
                {
                    style: 'tableExample',
                    table: {
                        widths: [45, '*', '*', 45, '*', '*'],
                        headerRows: 1,
                        body: [
                            [{ text: 'Bauvorhaben / Endkunde', style: 'tableHeader', colSpan: 6, alignment: 'left' }, '', '', '', '', ''],
                            [
                                { text: `Name:`, border: [true, false, false, false] },
                                { text: `${ekName ? ekName : ''}`, colSpan: 2, border: [false, false, false, false] }, '',
                                { text: `Straße:`, border: [true, false, false, true]}, 
                                { text: `${ekStrasse ? ekStrasse : ''}`, colSpan: 2, border: [false, false, true, true] }, ''
                            ],
                            [
                                { text: `PLZ:`, border: [true, true, false, false] }, 
                                { text: `${ekPlz ? ekPlz : ''}`, colSpan: 2, border: [false, true, false, false] }, '',
                                { text: `Ort:`, border: [true, false, false, false] }, 
                                { text: `${ekOrt ? ekOrt : ''}`, colSpan: 2, border: [false, false, true, false] }, ''
                            ],
                            [
                                { text: `Telefon 1:`, border: [true, true, false, false] }, 
                                { text: `${ekTelefon1 ? ekTelefon1 : ''}`, colSpan: 2, border: [false, true, false, false] }, '',
                                { text: `Telefon 2:`, border: [true, true, false, false] }, 
                                { text: `${ekTelefon2 ? ekTelefon2 : ''}`, colSpan: 2, border: [false, true, true, false] }, ''
                            ],
                            [
                                { text: `E-Mail:`, border: [true, true, false, true] }, 
                                { text: `${ekEMail ? ekEMail : ''}`, colSpan: 2, border: [false, true, false, true] }, '',
                                { text: `Info:`, border: [true, true, false, true] }, 
                                { text: `${ekInfo ? ekInfo :  ''}`, colSpan: 2, border: [false, true, true, true] }, ''
                            ],


                        ]
                    },
                    layout: dashed
                },
                {
                    style: 'tableExampleBigText',
                    table: {
                        widths: ['*'],
                        headerRows: 1,
                        // keepWithHeaderRows: 1,
                        body: [
                            [{ text: 'Mangelbeschreibung:', style: 'tableHeader', alignment: 'left' }],
                            [{ rowSpan: 5, text: mangelList }],
                            [''],
                            [''],
                            [''],
                            [''],
                            [{ text: 'Durchgeführte Arbeiten:', style: 'tableHeader', alignment: 'left' }],
                            [{ rowSpan: 5, alignment: 'justify', color: 'blue', text: `${ErledigteArbeiten ? ErledigteArbeiten : ''}` }],
                            [''],
                            [''],
                            [''],
                            [''],
                        ]
                    },
                    layout: borderedTable
                },

                {
                    text: `Die oben genannten Mängel wurden beseitigt. Sichtkontrolle und Funktionsprobe erfolgten
ohne Beanstandung. Die durchgeführten Arbeiten gelten als Abgenommen`, style: 'subheader'
                },
                {
                    style: 'tableExample',
                    table: {
                        widths: ['*', '*', '*', '*'],
                        headerRows: 0,
                        // keepWithHeaderRows: 1,
                        body: [
                            [
                                { text: [`Ort:`, { text: `${ekOrt ? ekOrt : ''} `, color: 'blue' }], colSpan: 2 }, '',
                                { text: [`Datum:`, { text: `${moment(new Date()).format("DD.MM.YYYY")} `, color: 'blue' }], colSpan: 2 }, ''
                            ],
                            // [{rowSpan: 3, text: 'Techniker:' }, {rowSpan: 3, text: 'Endkunde:' }],
                            [
                                { text: 'Techniker:', width: 100, border: [true, true, false, true] },
                                SignatureKunde !== null ? { image: `${SignatureBerater}`, width: 100, border: [false, true, true, true] } : '',
                                { text: 'Endkunde:', width: 100, border: [true, true, false, true] },
                                SignatureKunde !== null ? { image: `${SignatureKunde}`, width: 100, border: [false, true, true, true] } : '',
                            ],
                        ]
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                        },
                        hLineStyle: function (i, node) {
                            if (i === 1) {
                                return { dash: { length: 1, space: 2 } };
                            }
                            return null
                        },
                        vLineStyle: function (i, node) {
                            return null;
                        },
                    }
                },
                {
                    style: 'tableExample',
                    table: {
                        widths: ['*', 'auto'],
                        headerRows: 1,
                        // keepWithHeaderRows: 1,
                        body: [
                            [{ text: 'Interne Brömse-Bearbeitungsvermerke:', colSpan: 2, style: 'tableHeader', alignment: 'left' }, {}],
                            [{ text: [{ text: `${Finished === 1 ? '' : ''} `, style: 'icon' }, ` Erledigt`], colSpan: 2 }, ''],
                            [{ text: [{ text: `${AdditionalServiceNeeded ? '' : ''} `, style: 'icon' }, ` Weiterer Service Notwendig`], colSpan: 2 }, ''],
                            [{ text: [{ text: `${hasPhotos ? '' : ''} `, style: 'icon' }, ` Bild`], colSpan: 2 }, ''],
                            [{ text: [{ text: '', style: 'icon' }, ` Kulanz`] }, { text: `Service-Nr.:${ServiceNr ? ServiceNr : ''} `, alignment: 'left', style: 'tableHeader', border: [true, true, true, true] }],
                        ]
                    },
                    layout: borderedTable
                },

            ],

            styles: {
                header: {
                    fontSize: 25,
                    bold: true,
                    margin: [0, 0, 0, 0],
                    decoration: 'underline',
                },
                subheader: {
                    fontSize: 12,
                    bold: false,
                    margin: [0, 0, 0, 0]
                },
                subheaderQuestion: {
                    fontSize: 10,
                    bold: true,
                    margin: [0, 0, 0, 5]
                },
                subheader1: {
                    fontSize: 10,
                    bold: true
                },
                tableExample: {
                    margin: [0, 5, 0, 5],
                    fontSize: 10,

                },
                tableExampleBigText: {
                    margin: [0, 5, 0, 5],
                    fontSize: 9,
                    height: '300px'

                },
                tableExampleInfo: {
                    margin: [0, 5, 0, 5],
                    fontSize: 10,
                },
                tableFooter: {
                    fontSize: 9,
                    margin: [40, -30, 0, 0],
                },
                check: {
                    fontSize: 10
                },
                checkFirst: {
                    fontSize: 9,
                    margin: [0, 0, 0, 0]
                },
                icon: {
                    font: 'Fontello',
                    fontSize: 12
                },
                tableHeader: {
                    bold: true,
                    fontSize: 15,
                    color: 'black'
                },
                tableMargin: {
                    margin: [0, -5, 0, 0]
                },
                tableUnterschrift: {
                    fontSize: 8,
                    margin: [0, 5, 0, 5],
                },
                footer: {
                    fontSize: 6
                },
                test: {
                    alignment: 'justify',
                },
                noBorder:{
                    border: 'none'
                }

            },
            defaultStyle: {
                // alignment: 'justify'
            },

        };
        //pdfMake.createPdf(dd).open();
        const pdfDocGenerator = pdfMake.createPdf(dd);
        pdfDocGenerator.getBase64((data) => {
            this.props.SaveData(this.props.app, { pdf: data });
            this.props.history.goBack();
        });
    }
    render() {
        return (
            <div>
                <Button color="primary" variant="contained" onClick={this.downloadPDF}>Speichern</Button>
            </div>
        )
    }
}

const mapStateToProps = (state) => (
    {
        app: SelectedAppSelector(state),
        fotos: SelectedAppPhotosSelector(state)
    }
)

export default withRouter(connect(mapStateToProps, { SaveData })(GeneratePdf));