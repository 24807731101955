let gs = {
    shadows: Array(25).fill("none"),
    typography: {
        useNextVariants: true,
        color: "#444"
    },
    palette: {
        primary: {
            main: "#8BC34A",
            contrastText: "#FFFFFF"
        },
        secondary: {
            main: "#B2E477",
            contrastText: "#FFFFFF"
        }
    }
};
export default gs;