// import * as serviceWorker from "./serviceWorker";
import React, { Component } from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import MainLayout from "./components/MainLayout";
//import MainLayoutOst from "./components/ost/MainLayout";
import "./App.css";
import themeSwitcher from "./helpers/theme-switcher";
import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
// import configureStore from './store';
// import { SW_INIT, SW_UPDATE } from './actions/types';

//import { COMPANY_OST } from "./helpers/constants";


const classes = {
    buttonPrompt: {
        marginRight: '10px',
    }
};

class App extends Component {
    // constructor(props) {
    //     super(props);
    //     // serviceWorker.register;
    //     serviceWorker.register({
    //         onUpdate: registration =>
    //         store.dispatch({ type: SW_UPDATE, payload: registration }),
    //     });
    // }
    state = {
        UpdateAvailable: false,
        InstallPrompt: false
    };
    
    
    updateServiceWorker = (e, app) => {
        console.log("update service worker");
        const sw = app.serviceWorkerRegistration;
        //console.log("Sw waiting : ", app.serviceWorkerRegistration.ServiceWorkerRegistration);
        console.log("update service worker", sw);
        if(sw){
            const registrationWaiting = sw.waiting;
            if (registrationWaiting) {
              console.log("registration waiting skip waiting:", registrationWaiting);
              registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
      
              registrationWaiting.addEventListener('statechange', e => {
                if (e.target.state === 'activated') {
                  window.location.reload();
                }
              });
            }
           }
   
     };

    deferredPrompt = null;
    setupButton = null;
    componentDidMount = () => {
        //console.log("Listening for Install prompt");
        window.addEventListener('beforeinstallprompt', (e) => {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            // Stash the event so it can be triggered later.
            this.deferredPrompt = e;

            this.setState({InstallPrompt: true});

        });

        window.addEventListener('appinstalled', (evt) => {
            console.log("appinstalled fired", evt);
        });
    }

    // showAddToHomeScreen = () => {

    //     this.setState({InstallPrompt: true});
    // }

    addToHomeScreen = () => {
        //var a2hsBtn = document.querySelector(".ad2hs-prompt");  // hide our user interface that shows our A2HS button
        //a2hsBtn.style.display = 'none';  // Show the prompt
        this.setState({InstallPrompt: false});
        this.deferredPrompt.prompt();  // Wait for the user to respond to the prompt
        this.deferredPrompt.userChoice
            .then((choiceResult) => {

                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the prompt');
                } else {
                    console.log('User dismissed the prompt');
                }

                this.deferredPrompt = null;

            });
    }

    handleServiceWorkerUpdate(registration) {
        this.setState({ UpdateAvailable: true });
    }

    ReloadPage = () => {
        window.location.reload();
    };

    render() {
        //const state = store.getState();
        const themeSettings = themeSwitcher(this.props.app.company);
        const theme = createMuiTheme(themeSettings);

        return (
            <MuiThemeProvider theme={theme}>
                    <React.Fragment>
                        <CssBaseline />
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <div className="App">

                                <MainLayout />
                                {/* )} */}
                            </div>
                                      {/* <div
                                id="snackbar"
                                className={"show"}
                            >
                                A new version of this app is available. Click{" "}
                                <button onClick={(e) => this.updateServiceWorker(e, this.props.app)}>here</button>{" "}
                                to update.
                            </div> */}
                            <div
                                id="snackbar"
                                className={
                                    this.state.InstallPrompt ? "show" : "hide"
                                }                                
                            >
                               <Typography style={{fontSize: '20px', color: 'white'}}>Install App ?</Typography> 
                                <Button style={classes.buttonPrompt}
                                variant="outlined"
                                color="primary"
                                size="small"  
                                onClick={() => this.setState({InstallPrompt: false})}
                                >
                                    NEIN
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary" 
                                    size="small"                                   
                                    onClick={(e) => this.addToHomeScreen()}>
                                    JA
                                    </Button>
                            </div>
                        </MuiPickersUtilsProvider>
                    </React.Fragment>
            </MuiThemeProvider>
        );
    }
}

const mapStateToProps = state => ({
    app: state.app,
  });
  
  export default connect(
    mapStateToProps,
    { }
  )(App);
